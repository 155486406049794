import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Card from '../../../components/Card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import RenderDeleteModal from '../../../components/partials/components/delete-modal';
import ToastNotification from '../../../components/Toast';
import Loader from '../../../components/partials/components/loader';
import { API_AGENTS_LIST, API_AGENT_DELETE } from '../../../service/url';
import { deleteApiCall, getApiCall, postApiCall } from '../../../service/httpCall';
import exportData from '../../../utilities/export';
import '../../../assets/custom/css/custom.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import './slider.css'
import { Modal, Form, Button as BootstrapButton } from 'react-bootstrap';
import { EditIcon, DeleteIcon } from '../../../components/setting/elements';

import { API_AGENT_GROUPS_LIST, API_AGENT_GROUP_DELETE } from '../../../service/url';




const Agents = () => {
   const navigate = useNavigate();
   const [agentList, setAgentList] = useState([]);
   const [loading, setLoading] = useState(false);
   const [enableDeleteModal, setEnableDeleteModal] = useState(false);
   const [confirmDelete, setConfirmDelete] = useState(false);
   const [selectedAgents, setSelectedAgents] = useState([]);
   const [statusList, setStatusList] = useState({}); // Manage status toggle
   const [showToast, setShowToast] = useState(false);
   const [toastProps, setToastProps] = useState(null);
   const [buttonDisable, setButtonDisable] = useState(false);
   const [showBulkAddModal, setShowBulkAddModal] = useState(false); // For modal visibility
   const [bulkFile, setBulkFile] = useState(null); // To store the uploaded file

   useEffect(() => {
         if (toastProps) {
            setShowToast(true);
         }
   }, [toastProps]);

   useEffect(() => {
      if (confirmDelete) {
         handleDeleteSelectedAgents();
      }
   }, [confirmDelete]);

   // Fetch Agents Data
   const fetchAgents = () => {
      setLoading(true);
      getApiCall(API_AGENTS_LIST)
         .then((resp) => {
            if (resp) {
               const updatedList = resp.map((val) => ({
                  _id: val._id,
                  agent_name: val.agent_name,
                  agent_number: val.agent_number,
                  is_allocated: val.is_allocated,
                  created_by: val.created_by?.username || 'Unknown',
                  created_at: new Date(val.created_at).toLocaleString(),
                  extension: val.extension?.map((ext) => ext.extension).join(', ') || 'N/A',
                  username: val.username,
                  type: val.type,
                  access: val.access,
                  email_id: val.email_id,
                  status: val.login_status === '1' ? 'Active' : 'Inactive',
               }));

               setAgentList(updatedList);

               // Initialize statusList
               setStatusList(
                  updatedList.reduce((acc, agent) => {
                     acc[agent._id] = agent.status === 'Active';
                     return acc;
                  }, {})
               );
            } else {
               setToastProps({ type: 'danger', message: 'Failed to fetch agents' });
            }
         })
         .catch((error) => {
            console.error('Fetch Error:', error);
            setToastProps({ type: 'danger', message: 'Error fetching agents' });
         })
         .finally(() => setLoading(false));
   };

   useEffect(fetchAgents, []);

   const handleBulkAdd = () => {
      setShowBulkAddModal(true); // Open the modal
  };

  const handleCloseBulkAdd = () => {
   setShowBulkAddModal(false); // Close the modal
};

const handleBulkAddFileChange = (event) => {
   setBulkFile(event.target.files[0]); // Store the selected file
};


const handleBulkAddSave = async () => {
   if (!bulkFile) {
       setToastProps({ type: 'danger', message: 'Please select a file to upload.' });
       return;
   }

   const formData = new FormData();
   formData.append('file', bulkFile);

   try {
       await postApiCall('/agents/bulk-upload', formData); // Replace with actual endpoint
       setToastProps({ type: 'success', message: 'Bulk agents uploaded successfully!' });
       fetchAgents(); // Refresh agent list
   } catch (error) {
       setToastProps({ type: 'danger', message: 'Failed to upload bulk agents' });
   } finally {
       setShowBulkAddModal(false);
   }
};

  

   // Handle Status Toggle
   const handleStatusToggle = async (agentId) => {
      const newStatus = !statusList[agentId];
      try {
          // Use PATCH request without sending a body
          await postApiCall(`/v1/agents/status/${agentId}`, { login_status: newStatus ? '1' : '0' });
          
          setStatusList((prev) => ({
              ...prev,
              [agentId]: newStatus,
          }));
          
          setToastProps({ type: 'success', message: `Agent status updated to ${newStatus ? 'Active' : 'Inactive'}` });
      } catch (error) {
          console.error("Error toggling status:", error);
          setToastProps({ type: 'danger', message: 'Failed to update agent status' });
      }
  };
  

   const handleDeleteSelectedAgents = () => {
      if (selectedAgents.length === 0) return;
      postApiCall(API_AGENT_DELETE, { agentIds: selectedAgents })
         .then((resp) => {
            setToastProps({ type: 'success', message: 'Agents deleted successfully' });
            fetchAgents();
            setSelectedAgents([]);
         })
         .catch((err) => {
            const errorMessage = err.toString().split(".")[0];
            setToastProps({ type: 'danger', message: `Unable to delete agents: ${errorMessage}` });
            fetchAgents();
            setSelectedAgents([]);
         })
         .finally(() => setEnableDeleteModal(false));
   };

   const handleExport = async () => {
      setButtonDisable(true);
      await exportData('Agent', setToastProps, setShowToast);
      setButtonDisable(false);
   };

   const onCheckboxChange = (e, agentId) => {
      const { checked } = e.target;
      setSelectedAgents((prevSelected) =>
         checked ? [...prevSelected, agentId] : prevSelected.filter((id) => id !== agentId)
      );
   };

   const onHeaderCheckboxChange = (e) => {
      const { checked } = e.target;
      if (checked) {
         const allAgentIds = agentList.map((agent) => agent._id);
         setSelectedAgents(allAgentIds);
      } else {
         setSelectedAgents([]);
      }
   };

   const allocationStatusBodyTemplate = (rowData) => {
      return rowData.is_allocated === 0 ? 'Available' : 'Allocated';
   };

   const handleEditAgent = (agentId) => {
      navigate(`edit-agent/${agentId}`);
  };

     const handleDeleteSelectedAgent = (ids) => {
        setSelectedAgents(ids);
        setEnableDeleteModal(true)
     };

   return (
      <div style={{ marginTop: '20px' }}>
         <Row>
            <Col sm="12">
               <Card>
                  <Card.Header className="d-flex justify-content-between">
                     <h4>Agents List</h4>
                     <div>
                     <Button className="btn btn-primary me-2" onClick={handleBulkAdd}>Bulk Add</Button>
                     <Button className="btn btn-primary" onClick={() => navigate('/create-agent')}>Create Agent</Button>
                     </div>
                     
                  </Card.Header>
                  <Card.Body className="px-0">
                     {loading ? <Loader /> : (
                        <>
                           <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
                              <Button
                                 label="Delete Selected"
                                 onClick={() => setEnableDeleteModal(true)}
                                 className="p-button-danger p-button-sm"
                                 style={{ marginLeft: '10px' }}
                                 disabled={selectedAgents.length === 0}
                              />
                              <Button
                                 label="Export Data"
                                 onClick={handleExport}
                                 className="p-button-active p-button-sm"
                                 style={{ marginLeft: '10px' }}
                                 disabled={buttonDisable}
                              >
                                 {buttonDisable && (
                                    <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                                 )}
                              </Button>
                           </div>
                           <div className="scroll-container">
                           <DataTable value={agentList} paginator rows={10} emptyMessage="No agents found.">
                              <Column
                                 header={<Checkbox onChange={onHeaderCheckboxChange} checked={selectedAgents.length === agentList.length && agentList.length > 0} />}
                                 body={(rowData) => (
                                    <Checkbox
                                       onChange={(e) => onCheckboxChange(e, rowData._id)}
                                       checked={selectedAgents.includes(rowData._id)}
                                    />
                                 )}
                                 style={{ width: '3rem' }}
                              />
                              <Column field="agent_name" header="Agent Name" />
                              <Column field="agent_number" header="Agent Number" />
                              {/* <Column field="extension" header="Extensions" body={(rowData) => `${rowData.extension}`} /> */}
                              {/* <Column field="username" header="Username" /> */}
                              <Column field="type" header="Type" />
                              <Column field="access" header="Access" />
                              <Column field="email_id" header="Email ID" />
                              <Column field="created_by" header="Created By" />
                              <Column field="created_at" header="Joining Date" />
                              <Column body={allocationStatusBodyTemplate} header="Allocation Status" />
                              <Column
                                 header="Status"
                                 body={(rowData) => (
                                    <div style={{ textAlign: 'center' }}>
                                       <label className="slider_switch">
                                          <input
                                             type="checkbox"
                                             checked={statusList[rowData._id] || false}
                                             onChange={() => handleStatusToggle(rowData._id)}
                                          />
                                          <span className="slider"></span>
                                       </label>
                                    </div>
                                 )}
                                 style={{ width: '7rem', textAlign: 'center' }}
                              />
                              <Column
                                 header="Actions"
                                 body={(rowData) => (
                                    <div className="actions" style={{ display: 'flex', gap: '5px' }}>
                                          <div onClick={() => handleEditAgent(rowData._id)}>
                                             <EditIcon title="Edit" />
                                          </div>
                                          <div>
                                             <DeleteIcon
                                                handleCallback={() => handleDeleteSelectedAgent([rowData._id])}
                                                title="Delete"
                                             />
                                          </div>
                                    </div>
                                 )}
                              />

                           </DataTable>
                           </div>
                        </>
                     )}
                  </Card.Body>
               </Card>
            </Col>
         </Row>

         <RenderDeleteModal
            show={enableDeleteModal}
            setShow={setEnableDeleteModal}
            setConfirmDelete={setConfirmDelete}
            isMultiDelete={true}
            clearSelectedData={() => setSelectedAgents([])}
            onConfirm={handleDeleteSelectedAgents}
         />


          <Modal show={showBulkAddModal} onHide={() => setShowBulkAddModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Bulk Members</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formFile">
                            <Form.Label>Upload .csv File</Form.Label>
                            <Form.Control type="file" accept=".csv" onChange={handleBulkAddFileChange} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <BootstrapButton variant="secondary" onClick={() => setShowBulkAddModal(false)}>Close</BootstrapButton>
                    <BootstrapButton variant="primary" onClick={handleBulkAddSave}>Save</BootstrapButton>
                </Modal.Footer>
            </Modal>
            <ToastNotification show_toast={showToast} set_show_toast={setShowToast} {...toastProps} />
      </div>

      
   );
};

export default Agents;
