import React, { useState, useEffect } from 'react'
import {Row,Col,Form,Button} from 'react-bootstrap'
import Card from '../../../components/Card'
import ToastNotification from '../../../components/Toast';
import { API_USERS_SIGNUP } from '../../../service/url';
import { postApiCall } from '../../../service/httpCall';
import { useNavigate, useParams } from 'react-router-dom';
import RenderUserAccessRolesList from '../../../components/partials/components/user-access-roles-list';
import { getUserCreateAccessRoleLabel } from '../../../utilities/setting';
import { ROUTES } from '../../../utilities/constant';
import { useAuth, errorCheck } from '../auth/auth-utils';

const CallCentreUserCreate = () => {

   const navigate = useNavigate();
   const params = useParams();
   const { checkAuth } = useAuth();
   const [callCentreId, setCallCentreId] = useState(undefined);
   const [name, setName] = useState('');
   const [username, setUsername] = useState('');
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const [confirmPassword, setConfirmPassword] = useState('');
   const [showToast, setShowToast] = useState(false);
   const [toastProps, setToastProps] = useState(null);
   const [buttonDisable, setButtonDisable] = useState(false);
   const [activeUserAccessRole, setActiveUserAccessRole] = useState(undefined);
   const [userAccessRoles, setUserAccessRoles] = useState([]);
   const [emailError, setEmailError] = useState("");
   

   useEffect( () => {

    setCallCentreId(params.id);

    getUserCreateAccessRoleLabel()
    .then(resp => {

       if(resp){
          setUserAccessRoles(resp);
          setActiveUserAccessRole(resp[0].value);
       }

    })
    .catch(onError);

   }, []);

   useEffect(() => {
      checkAuth(setToastProps, setShowToast)
    }, []);


   useEffect( () => {

      if(toastProps){
         setShowToast(true);
      }

   }, [toastProps]);


   function validateForm(){

      let valid = true;

      if(!username.trim()){
         valid = false;
         setToastProps({message: 'Username required', type: 'danger'});
      }
      if(!name.trim()){
         valid = false;
         setToastProps({message: 'Name required', type: 'danger'});
      }
      if(!email.trim()){
         valid = false;
         setToastProps({message: 'Name required', type: 'danger'});
      }
      else if(!password.trim()){
         valid = false;
         setToastProps({message: 'Password required', type: 'danger'});
      }
      else if(password.trim().length < 6){
         valid = false;
         setToastProps({message: 'Password must be of minimum 6 characters', type: 'danger'});
      }
      else if(!confirmPassword.trim()){
         valid = false;
         setToastProps({message: 'Confirm passowrd required', type: 'danger'});
      }
      else if(password.trim() != confirmPassword.trim()){
         valid = false;
         setToastProps({message: 'Password and confirm password not matching', type: 'danger'});
      }

      return valid;

   }

   async function submitForm(){

      setButtonDisable(true);

      if(!validateForm()){
         setButtonDisable(false);
         return;
      }

      const params = { username, password, role: activeUserAccessRole, name, email, created_by: callCentreId };

      postApiCall(API_USERS_SIGNUP, params)
      .then(onUserCreateSuccess)
      .catch(onUserCreateError);

   }

   function onUserCreateSuccess(resp){
      setToastProps({message: 'User created successfully', type: 'success'});
      setTimeout( () => {
         const callCentreUsersListPath = ROUTES.CALL_CENTRES.USERS.LIST.replace(':id', callCentreId);
         navigate(`/${callCentreUsersListPath}`);
      }, 1500);
   }

   function onUserCreateError(err){
      if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
         errorCheck(err, navigate, setToastProps, setShowToast)
      } else {
        setButtonDisable(false);
        setToastProps({message: JSON.stringify(err), type: 'danger'});
      }
   }

   function onError(err) {
      if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
       errorCheck(err, navigate, setToastProps, setShowToast)
    } else {
      console.log(err)
    }
   }

     
 const validateEmail = (value) => {
   const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
   if (!value) {
     setEmailError("Email is required");
   } else if (!emailPattern.test(value)) {
     setEmailError("Invalid email format");
   } else {
     setEmailError("");
   }
 };

   return(
      <>
        <div>
            <Row>
               <Col xl="12" lg="12">
                  <Card>
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">New User Information</h4>
                           <RenderUserAccessRolesList activeUserAccessRole={activeUserAccessRole} />
                        </div>
                     </Card.Header>
                     <Card.Body>
                        <div className="new-user-info">
                           <form>
                              <div className="row">
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="username">User Name:</Form.Label>
                                    <Form.Control type="text" id="username" maxLength={100} value={username} onChange={e => setUsername(e.target.value)} required />
                                 </Form.Group>
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="name">Full Name:</Form.Label>
                                    <Form.Control type="text"  id="name" maxLength={100} value={name} onChange={e => setName(e.target.value)} required />
                                 </Form.Group>
                              </div>
                              <div className="row">
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="email">Email:</Form.Label>
                                    <Form.Control type="email"  id="email" maxLength={100} value={email}
                                    onChange={(e) => {
                                       setEmail(e.target.value);
                                       validateEmail(e.target.value);
                                     }}
                                      required
                                    />
                                    {emailError && <span className="text-danger">{emailError}</span>}
                                 </Form.Group>
                              </div>
                              <div className="row">
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="pass">Password:</Form.Label>
                                    <Form.Control type="password"  id="pass" maxLength={100} value={password} onChange={e => setPassword(e.target.value)} required />
                                 </Form.Group>
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="rpass">Confirm Password:</Form.Label>
                                    <Form.Control type="password"  id="rpass" maxLength={100} value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} required />
                                 </Form.Group>
                              </div>
                              <hr/>
                              <Button onClick={async() => {submitForm()}} type="button" variant="btn btn-primary" disabled={buttonDisable}>
                                 Add New User &nbsp;
                                 {
                                    buttonDisable ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <></>
                                 }
                              </Button>
                           </form>
                        </div>
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
         </div>

         <ToastNotification show_toast={showToast} set_show_toast={setShowToast} {...toastProps} />

      </>
  )

}

export default CallCentreUserCreate;