import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Card from "../../../components/Card";
import { API_CREDITS_GET } from "../../../service/url";
import { getApiCall } from "../../../service/httpCall";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import ToastNotification from "../../../components/Toast";
import { errorCheck } from "../auth/auth-utils";
import exportData from "../../../utilities/export";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import "../../../assets/custom/css/custom.css";


//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
import Loader from "../../../components/partials/components/loader";

const CreditHistory = () => {
  const navigate = useNavigate();
  const [creditsList, setCreditsList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastProps, setToastProps] = useState(null);
  const [filters, setFilters] = useState({
    name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  });
  const [loading, setLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleFilter = () => {
    if (!startDate || !endDate) {
      setFilteredData(creditsList);
      return;
    }

    const startOfDay = new Date(startDate);
    startOfDay.setHours(0, 0, 0, 0);
  
    const endOfDay = new Date(endDate);
    endOfDay.setHours(23, 59, 59, 999);

    const filtered = creditsList.filter((credit) => {
      const creditDate = new Date(credit.req_date);
      return creditDate >= startOfDay && creditDate <= endOfDay;
    });

    setFilteredData(filtered);
  };

  const handleExport = async () => {
    setButtonDisable(true);
    await exportData("Credits", setToastProps, setShowToast);
    setButtonDisable(false);
  };

  useEffect(() => {
    setLoading(true);
    getApiCall(API_CREDITS_GET, {})
      .then(onCreditsListSuccess)
      .catch(onError)
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (toastProps) {
      setShowToast(true);
    }
  }, [toastProps]);

  function onCreditsListSuccess(resp) {
    setCreditsList(resp);
    setFilteredData(resp);
  }

  function onError(err) {
    if (typeof err !== "object" && err.includes("TokenExpiredError")) {
      errorCheck(err, navigate, setToastProps, setShowToast);
    } else {
      console.log(err);
    }
  }

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setFilteredData(creditsList);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div>
        <Row>
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">User's Credit History</h4>
                </div>
                <Button
                  label="Export Data"
                  onClick={handleExport}
                  className="p-button-active p-button-sm"
                  style={{ marginBottom: "10px", marginLeft: "10px" }}
                  disabled={buttonDisable}
                >
                  &nbsp;
                  {buttonDisable && (
                    <span
                      className="spinner-border spinner-border-sm ml-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </Button>
              </Card.Header>
              <Card.Body className="px-0">
                <div className="d-flex align-items-center mb-3" style={{ marginLeft: "10px" }}>
                  <Calendar
                    value={startDate}
                    onChange={(e) => setStartDate(e.value)}
                    placeholder="Start Date"
                    showIcon
                  />
                  <span className="mx-2">to</span>
                  <Calendar
                    value={endDate}
                    onChange={(e) => setEndDate(e.value)}
                    placeholder="End Date"
                    showIcon
                  />
                  <Button
                    label="Filter"
                    onClick={handleFilter}
                    className="p-button-sm p-button-primary mx-2"
                  />
                  <Button
                    label="Reset"
                    onClick={handleReset}
                    className="p-button-sm p-button-secondary"
                  />
                </div>
                <div className="scroll-container">
                  <div className="table-responsive border-bottom my-3">
                    <DataTable
                      value={filteredData}
                      tableStyle={{ minWidth: "50rem" }}
                      filters={filters}
                      paginator
                      rows={8}
                    >
                      <Column
                        header="#"
                        body={(rowData, { rowIndex }) => rowIndex + 1}
                        style={{ width: "50px" }}
                      />
                      <Column field="user_id" header="User Id"></Column>
                      <Column
                        field="from_user.username"
                        header="From"
                      ></Column>
                      <Column field="to_user.username" header="To"></Column>
                      <Column field="action_user.username" header="Action On"></Column>
                      <Column
                        field="req_date"
                        header="Date"
                        body={(rowData) =>
                          new Date(rowData.req_date).toLocaleString("en-IN", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          })
                        }
                      ></Column>
                      <Column field="action" header="Actions"></Column>
                      <Column
                        field="credits_rupees.$numberDecimal"
                        header="Amount"
                      ></Column>
                      <Column
                        field="balance.$numberDecimal"
                        header="Balance"
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      <ToastNotification
        show_toast={showToast}
        set_show_toast={setShowToast}
        {...toastProps}
      />
    </>
  );
};

export default CreditHistory;
