import React, { useState, useEffect } from "react";
import { getApiCall, postApiCall } from "../../../service/httpCall";
import { Row, Col, Card } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import RenderDeleteModal from "../../../components/partials/components/delete-modal";
import ToastNotification from "../../../components/Toast";
import {
    API_NUMBER_FILE_LIST,
    API_NUMBER_FILE_LIST_DELETE,
} from "../../../service/url";
import { DeleteIcon } from "../../../components/setting/elements";
import Loader  from '../../../components/partials/components/loader';
import { useAuth, errorCheck } from '../auth/auth-utils';
import { useNavigate } from "react-router-dom";
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import "../../../assets/custom/css/custom.css";
import exportData from '../../../utilities/export';


export default function NumberFileList() {
  const [enableDeleteModal, setEnableDeleteModal] = useState(false);
  const [deletedNumberFileId, setDeleteNumberFileId] = useState(undefined);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [toastProps, setToastProps] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [numberFileList, setNumberFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { checkAuth } = useAuth();
  const navigate = useNavigate();
  const [selectedData, setSelectedData] = useState([]);
  const [isMultiDelete, setIsMultiDelete] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);

  const handleExport = async () => {
    setButtonDisable(true); 
    await exportData('NumberFileList', setToastProps, setShowToast);
    setButtonDisable(false); 
  };

  const handleCheckboxToggle = (fileId) => {
    const isSelected = selectedData.includes(fileId);
    setSelectedData(isSelected
        ? selectedData.filter(id => id !== fileId)
        : [...selectedData, fileId]);
  };

  const handleMultiDelete = () => {
        setIsMultiDelete(true);
        setEnableDeleteModal(true);
  };


  useEffect(() => {
    if (toastProps) {
      setShowToast(true);
    }
  }, [toastProps]);

  useEffect(() => {
    setLoading(true);
    getApiCall(API_NUMBER_FILE_LIST)
      .then(onNumberFileListSuccess)
      .catch(onError)
      .finally(() => setLoading(false));
  }, []);


  function onNumberFileListSuccess(resp) {
    const updatedList = resp.map((val, idx) => {
      val["key"] = idx + 1;
      val["created_at"] = new Date(val["created_at"]).toGMTString();
      return val;
    });
    setNumberFileList(updatedList);
  }

  const RenderActionComponent = ({id}) => {
    return (
      <div className="flex align-items-center list-user-action">
          <DeleteIcon handleCallback={() => handleNumberFileListDelete(id)} />
      </div>
    );
  };

  function handleNumberFileListDelete(id) {
    checkAuth(setToastProps, setShowToast)
    setIsMultiDelete(false);
    setEnableDeleteModal(true);
    setDeleteNumberFileId(id);
  }

  const handleSelectAll = () => {
    if (selectedData.length === numberFileList.length) {
        setSelectedData([]);
    } else {
        const allFilesIds = numberFileList.map(numberFileList => numberFileList._id);
        setSelectedData(allFilesIds);
    }
  };

  useEffect( () => {
    if (confirmDelete) {
        if (isMultiDelete) {
            postApiCall(API_NUMBER_FILE_LIST_DELETE, { fileListIds: selectedData })
                .then(onDeleteSuccess)
                .catch(onDeleteFail);
        } else {
            postApiCall(API_NUMBER_FILE_LIST_DELETE, { fileListIds: [deletedNumberFileId] })
                .then(onDeleteSuccess)
                .catch(onDeleteFail);
        }
    }
    }, [confirmDelete]);

  function onDeleteSuccess(resp) {
    setToastProps({
      type: "success",
      message: "File deleted successfully",
    });
    let updatedList;
    if (isMultiDelete) {
        updatedList = numberFileList.filter(val => !selectedData.includes(val._id)).map((val, key) => {
            val['key'] = key + 1;
            return val;
        });
        setSelectedData([]);
    } else {
        updatedList = numberFileList
        .filter((val) => val._id != deletedNumberFileId)
        .map((val, key) => {
          val["key"] = key + 1;
          return val;
        });
    }
    setNumberFileList(updatedList);
    setEnableDeleteModal(false);
    setDeleteNumberFileId(undefined);
    setConfirmDelete(false); 
  }

  function onDeleteFail(err) {
    if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
      errorCheck(err, navigate, setToastProps, setShowToast)
    } else {
      setToastProps({
        type: "danger",
        message: `Unable to delete File: ${err}`,
      });
    }
    setConfirmDelete(false); 
   
  }

  function onError(err) {
    if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
      errorCheck(err, navigate, setToastProps, setShowToast)
   } else {
     console.log(err)
   }
 }

 const rowClassName = (data) => {
  return {
      'highlighted-row': selectedData.includes(data._id)
    };
  }


  const actionBodyTemplate = (rowData) => {
    return <RenderActionComponent id={rowData._id} />;
  };


  if (loading) {
    return <Loader />
  }

  return (
    <>
      <div>
        <Row>
          <Col sm="12">
            <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <div className="header-title">
                <h4 className="card-title">Number File List</h4>
              </div>
            </Card.Header>
              <Card.Body className="px-0">
                <div className="scroll-container">
                  <div className="table-responsive border-bottom my-3">
                    <Button 
                      label="Delete Selected" 
                      onClick={handleMultiDelete} 
                      className="p-button-danger p-button-sm" 
                      style={{ marginBottom: '10px', marginLeft: '10px' }} 
                      disabled={selectedData.length === 0} 
                    />
                    <Button 
                      label="Export Data" 
                      onClick={handleExport} 
                      className="p-button-active p-button-sm" 
                      style={{ marginBottom: '10px', marginLeft: '10px' }} 
                      disabled={buttonDisable} 
                    >
                      &nbsp;
                      {buttonDisable && (
                          <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                      )}
                    </Button>
                    <DataTable
                      value={numberFileList}
                      tableStyle={{ minWidth: "50rem" }}
                      paginator
                      rows={8}
                      rowClassName={rowClassName}
                    >
                      <Column 
                        header={<Checkbox 
                        checked={selectedData.length === numberFileList.length && numberFileList.length > 0} 
                        onChange={handleSelectAll} 
                        />} 
                        headerStyle={{ width: '3rem' }} 
                        body={(rowData) => (
                        <Checkbox 
                          checked={selectedData.includes(rowData._id)} 
                          onChange={() => handleCheckboxToggle(rowData._id)} 
                        />
                        )} 
                      />
                      <Column
                        field="file_name"
                        header="File Name"
                        filter
                        filterPlaceholder="Search by file_name"
                      ></Column>
                      <Column field="file_url" header="File URL"></Column>
                      <Column
                        field="user_id.username"
                        header="Uploaded By"
                      ></Column>
                      <Column field="created_at" header="Created Date"></Column>
                      <Column header="Action" body={actionBodyTemplate} />
                    </DataTable>
                  </div>
                </div>
              </Card.Body>

            </Card>
          </Col>
        </Row>
      </div>

      <RenderDeleteModal 
         show={enableDeleteModal} 
         setShow={setEnableDeleteModal} 
         setConfirmDelete={setConfirmDelete} 
         isMultiDelete={isMultiDelete} 
         clearSelectedData={() => setSelectedData([])}
      />

      <ToastNotification
        show_toast={showToast}
        set_show_toast={setShowToast}
        {...toastProps}
      />
    </>
  );
};

