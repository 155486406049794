import React, { useState, useEffect, useRef } from 'react'
import {Row,Col,Image} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import Card from '../../../components/Card'
import { API_COMPANY_LIST } from '../../../service/url';
import { getApiCall } from '../../../service/httpCall';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import Loader  from '../../../components/partials/components/loader';
import "../../../assets/custom/css/custom.css";

//theme
import 'primereact/resources/themes/lara-light-indigo/theme.css';
//core
import 'primereact/resources/primereact.min.css';

const CompanyList = () =>{

   const [companylist, setCompanylist] = useState([]);
   const [filters, setFilters] = useState({
      name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    //   status: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
   });
   //const statuses = useRef(['Active', 'Inactive']);
   
   const [loading, setLoading] = useState(false);

   useEffect( () => {
      setLoading(true);
      getApiCall(API_COMPANY_LIST, {})
      .then(onCompanyListSuccess)
      .catch(onError)
      .finally(() => setLoading(false));

   }, []);

   function onCompanyListSuccess(resp){
      const updatedList = resp.map( (val, idx) => {
         val['key'] = idx+1;
         val['actions'] = RenderActionComponent(val._id);
         val['created_at'] = new Date(val['created_at']).toGMTString();
         return val;
      } );
      setCompanylist(updatedList);
   }

   function RenderActionComponent(id){

      return (
         <div className="flex align-items-center list-user-action">
            <Link className="btn btn-sm btn-icon btn-warning" data-toggle="tooltip" data-placement="top" title="Edit" data-original-title="Edit" to={`/dashboard/app/company-profile/${id}`}>
               <span className="btn-inner">
                  <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                     <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                     <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
               </span>
            </Link>{' '}
         </div>
      );

   }

//    const dropdownItemTemplate = (option) => {
//       return <span>{option}</span>;
//    }

//    const statusFilterTemplate = (options) => {
//       return <Dropdown value={options.value} options={statuses.current} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={dropdownItemTemplate} placeholder="Select One" className="p-column-filter" showClear />;
//    }

   function onError(err){
      console.log(err);
   }

   if (loading) {
      return <Loader />
   }

  return(
     <>
       <div>
         <Row>
            <Col sm="12">
               <Card>
                  <Card.Header className="d-flex justify-content-between">
                     <div className="header-title">
                        <h4 className="card-title">Company List</h4>
                     </div>
                  </Card.Header>
                  <Card.Body className="px-0">
                  <div className="scroll-container">
                     <div className="table-responsive border-bottom my-3">
                        <DataTable value={companylist} tableStyle={{ minWidth: '50rem' }} filters={filters} paginator rows={8}>
                           <Column field="key" header="#"></Column>
                           <Column field="name" filterField="name" header="name" filter filterPlaceholder="Search by name" ></Column>
                           <Column field="created_at" header="Created Date"></Column>
                           <Column field="actions" header="Actions"></Column>
                        </DataTable>
                     </div>
                  </div>
                  </Card.Body>
               </Card>
            </Col>
         </Row>
      </div>
     </>
  )

}

export default CompanyList;