import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button,Table } from "react-bootstrap";
import Card from "../../../components/Card";
import ToastNotification from "../../../components/Toast";
import { API_USERS_SIGNUP } from "../../../service/url";
import { postApiCall,getApiCall } from "../../../service/httpCall";
import { useNavigate } from "react-router-dom";
import {getUserCreateAccessRoleLabel,ifUserAssociateCompany, getUser} from "../../../utilities/setting";
import { validatePhone } from "../../../utilities/helpers";
import RenderUserCompanyFields from "../../../components/partials/dashboard/users/company";
import { ROUTES } from "../../../utilities/constant";
import { useAuth, errorCheck } from '../auth/auth-utils';
import { API_ACL_SETTING_LIST } from "../../../service/url";
import { USER_ROLES } from '../../../utilities/constant';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {validateEmail} from '../../../utilities/helpers'
import "../../../assets/custom/css/custom.css";


const UserAdd = () => {
  const navigate = useNavigate();
  const { checkAuth } = useAuth();
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyPincode, setCompanyPincode] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastProps, setToastProps] = useState(null);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [activeUserAccessRole, setActiveUserAccessRole] = useState(undefined);
  const [aclSettingList, setAClSettingList] = useState([]);
  const [selectedACL, setSelectedACL] = useState("");
  const [ifRenderCompanyFields, setIfRenderCompanyFields] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isReseller, setIsReseller] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState("");
  const [loading, setLoading] = useState(true);
  const [isCompanyAdmin, setCompanyAdmin] = useState(false);
  const [isCallCenterAdmin, setCallCenterAdmin] = useState(false);
  const [channel, setChannel] = useState(0);
  const [companyUser , setCompanyUser] = useState(0);
  const [callCenterAdminUser , setCallCenterAdminUser] = useState(0);
  const [callCenterAdminAgent , setCallCenterAdminAgent] = useState(0);
  const [callCenterAdminTeamlead , setCallCenterAdminTeamlead] = useState(0);
  const [agent , setAgent] = useState(0);
  const [selectedFlowType, setSelectedFlowType] = useState("");
  const [companyUserRole, setCompanyUserRole] = useState(false);
  const [availableLicence, setAvailableLicence] = useState({});
  const [errors, setErrors] = useState({
    role_cadmin: "",
    role_ccadmin: "",
    role_ccagent: "",
    role_ccteamlead: "",
    agent: "",
    channel: ""
  });

  const [emailError, setEmailError] = useState("");

  const validateInput = (value, field) => {
    if (value === "") {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: "This field cannot be empty" }));
      return "";
    }
  
    const numValue = Number(value);

    if (isReseller) {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: "" })); // Clear error when valid
      return numValue
    }
  
    if (isNaN(numValue)) {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: "Invalid number" }));
      return "";
    }

    if (companyUserRole) {
      let available_licence = availableLicence?.[field];
  
      // Adjust comparison based on selected user type
      if (selectedUserType === USER_ROLES.COMPANY_ADMIN) {
        if (field === USER_ROLES.COMPANY_ADMIN) {
          available_licence = availableLicence?.[field] > 0 ? availableLicence?.[field] - 1 : availableLicence?.[field];
        }
      } else if (selectedUserType === USER_ROLES.CALL_CENTRE_ADMIN) {
        if (field === USER_ROLES.CALL_CENTRE_ADMIN) {
          available_licence = availableLicence?.[field] > 0 ? availableLicence?.[field] - 1 : availableLicence?.[field];
        }
      }
  
      if (numValue > available_licence) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `Value cannot be more than ${available_licence}`,
        }));
        return numValue;
      }
    }

    if (!companyUserRole && !isReseller && !isSuperAdmin) {
      let available_licence = availableLicence?.[field];
  
      // Adjust comparison based on selected user type
      if (selectedUserType === USER_ROLES.CALL_CENTRE_ADMIN) {
        if (field === USER_ROLES.CALL_CENTRE_ADMIN) {
          available_licence = availableLicence?.[field] > 0 ? availableLicence?.[field] - 1 : availableLicence?.[field];
        }
      } else if (selectedUserType === USER_ROLES.CALL_CENTRE_TEAM_LEAD) {
        if (field === USER_ROLES.CALL_CENTRE_TEAM_LEAD) {
          available_licence = availableLicence?.[field] > 0 ? availableLicence?.[field] - 1 : availableLicence?.[field];
        }
      } else if (selectedUserType === USER_ROLES.CALL_CENTRE_AGENT) {
        if (field === USER_ROLES.CALL_CENTRE_AGENT) {
          available_licence = availableLicence?.[field] > 0 ? availableLicence?.[field] - 1 : availableLicence?.[field];
        }
      }
  
      if (numValue > available_licence) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `Value cannot be more than ${available_licence}`,
        }));
        return numValue;
      }
    }
  
    // if (numValue > availableLicence[field]) {
    //   setErrors((prevErrors) => ({ ...prevErrors, [field]: `Value cannot be more than ${availableLicence[field]}` }));
    //   return numValue;
    // }
  
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" })); // Clear error when valid
    return numValue;
  };

  useEffect(() => {
    if (companyUserRole) {
      if (selectedUserType === USER_ROLES.COMPANY_ADMIN) {
        validateInput(companyUser, "role_cadmin");
        if (callCenterAdminUser) {
          validateInput(callCenterAdminUser, "role_ccadmin");
        }
      } else if (selectedUserType === USER_ROLES.CALL_CENTRE_ADMIN) {
        validateInput(callCenterAdminUser, "role_ccadmin");
        if (companyUser) {
          validateInput(companyUser, "role_cadmin");
        }
      }
    } else if (!companyUserRole && !isReseller && !isSuperAdmin) {
      if (selectedUserType === USER_ROLES.CALL_CENTRE_ADMIN) {
          validateInput(callCenterAdminUser, "role_ccadmin");
        if (callCenterAdminAgent || callCenterAdminTeamlead) {
          validateInput(callCenterAdminAgent, "role_ccagent");
          validateInput(callCenterAdminTeamlead, "role_ccteamlead");
        }
      } else if (selectedUserType === USER_ROLES.CALL_CENTRE_AGENT) {
          validateInput(callCenterAdminAgent, "role_ccagent");
        if (callCenterAdminUser || callCenterAdminTeamlead) {
          validateInput(callCenterAdminTeamlead, "role_ccteamlead");
          validateInput(callCenterAdminUser, "role_ccadmin");
        }
      } else if (selectedUserType === USER_ROLES.CALL_CENTRE_TEAM_LEAD) {
          validateInput(callCenterAdminTeamlead, "role_ccteamlead");
          if (callCenterAdminUser || callCenterAdminAgent) {
            validateInput(callCenterAdminAgent, "role_ccagent");
            validateInput(callCenterAdminUser, "role_ccadmin");
          }
      }
    }
  }, [selectedUserType]); 


  useEffect(() => {
    async function superadminCheck() {
      const user = JSON.parse(await AsyncStorage.getItem('user'));
      if (user.role !== USER_ROLES.RESELLER && user.role !== USER_ROLES.SUPERADMIN) {
        setAvailableLicence(user?.sub_user_licence_id?.available_licence)
      }
      if (user.role === USER_ROLES.SUPERADMIN) {
        setIsSuperAdmin(true);
      }
      if (user.role === USER_ROLES.COMPANY_ADMIN) {
        setCompanyAdmin(true);
      }
      if (user.role === USER_ROLES.CALL_CENTRE_ADMIN) {
        setCallCenterAdmin(true);
      }
      if (user.role === USER_ROLES.COMPANY_ADMIN){
        setCompanyUserRole(true);
      }
      if (user.role === USER_ROLES.RESELLER) {
        setIsReseller(true);
      }
      setLoading(false);
    }
    superadminCheck();
  }, []);

  useEffect(() => {
    if (toastProps) {
      setShowToast(true);
    }
  }, [toastProps]);

  useEffect(() => {
    if (!loading) { 
      getApiCall(API_ACL_SETTING_LIST, {})
        .then(onAClSettingListSuccess)
        .catch(onError);
    }
  }, [loading]);

  useEffect(() => {
    checkAuth(setToastProps, setShowToast)
  }, []);

  // useEffect(() => {
  //   setUsername(""); 
  //   setPassword(""); 
  // }, []);

  useEffect(() => {
    getUserCreateAccessRoleLabel()
      .then((resp) => {
        if (resp) {
          setActiveUserAccessRole(resp[0].value);

          const isUserAssociatedCompany = ifUserAssociateCompany(resp[0].value);
          setIfRenderCompanyFields(isUserAssociatedCompany);
        }
      })
      .catch(onError);
  }, []);


  function onAClSettingListSuccess(resp) {
    if (isSuperAdmin) {
      setAClSettingList(resp);
    } else {
      getUser().then((user) => {
        if (user) {
          if (user?.acl_settings) {
            setAClSettingList([user.acl_settings]);
          }
        }
      });
    }
  }


  function validateForm() {
    let valid = true;
    if (!username.trim()) {
      valid = false;
      setToastProps({ message: "Username required", type: "danger" });
    }
    if (!name.trim()) {
      valid = false;
      setToastProps({ message: "Name required", type: "danger" });
    }
    if (!email.trim()) {
      valid = false;
      setToastProps({ message: "Name required", type: "danger" });
    } else if (!password.trim()) {
      valid = false;
      setToastProps({ message: "Password required", type: "danger" });
    } else if (password.trim().length < 6) {
      valid = false;
      setToastProps({
        message: "Password must be of minimum 6 characters",
        type: "danger",
      });
    } else if (!confirmPassword.trim()) {
      valid = false;
      setToastProps({ message: "Confirm passowrd required", type: "danger" });
    } else if (password.trim() != confirmPassword.trim()) {
      valid = false;
      setToastProps({
        message: "Password and confirm password not matching",
        type: "danger",
      });
      
    // } else if (!licence && isSuperAdmin) {
    //   valid = false;
    //   setToastProps({
    //     message: "Licence Is required",
    //     type: "danger",
    //   });
    } else if (ifRenderCompanyFields && !(selectedUserType === USER_ROLES.CALL_CENTRE_ADMIN || isCallCenterAdmin)) {
      valid = validateCompanyFields();
    } else if (selectedUserType == "role_ccadmin") {
      if (selectedFlowType === "" || selectedFlowType === null) {
        valid = false;
        setToastProps({ message: "Flow type required", type: "danger" });
      }
    } 

    if (emailError) {
      valid = false;
      setToastProps({ message: "Enter correct Email", type: "danger" });
    }

    if (isSuperAdmin || isCompanyAdmin || isCallCenterAdmin) {
      if (!selectedUserType.trim()) {
        valid = false;
        setToastProps({ message: "UserType required", type: "danger" });
      }
    }

    return valid;
  }

  function validateCompanyFields() {
    let valid = true;

    if (!companyUserRole) {
      if (!companyName.trim()) {
        valid = false;
        setToastProps({ message: "Company name required", type: "danger" });
      } else if (!validatePhone(companyPhone)) {
        valid = false;
        setToastProps({
            message: "Company phone invalid format",
            type: "danger",
        });
      } else if (!companyPincode.trim()) {
        valid = false;
        setToastProps({ message: "Company pincode required", type: "danger" });
      } else if (!/^\d{4}$/.test(companyPincode)) {
        valid = false;
        setToastProps({ message: "Company pincode must be exactly 4 digits", type: "danger" });
      } else if (!companyAddress.trim()) {
        valid = false;
        setToastProps({ message: "Company address required", type: "danger" });
      }
    }

    return valid;
}

  const updateAvailableLicences = async (sub_licence) => {
    try {
      const userData = await AsyncStorage.getItem('user');
      if (!userData) return;

      const user = JSON.parse(userData);

      if (user.sub_user_licence_id && user.sub_user_licence_id.available_licence) {
        let available_licence = user.sub_user_licence_id.available_licence;

        let newAvailableLicences = {
          role_cadmin: Math.max(available_licence.role_cadmin - sub_licence.role_cadmin, 0),
          role_ccadmin: Math.max(available_licence.role_ccadmin - sub_licence.role_ccadmin, 0),
          role_ccagent: Math.max(available_licence.role_ccagent - sub_licence.role_ccagent, 0),
          role_ccteamlead: Math.max(available_licence.role_ccteamlead - sub_licence.role_ccteamlead, 0),
          agent: Math.max(available_licence.agent - sub_licence.agent, 0),
          channel: Math.max(available_licence.channel - sub_licence.channel, 0),
        };

        // Apply additional subtractions based on conditions
        if (companyUserRole) {
          if (selectedUserType === USER_ROLES.COMPANY_ADMIN) {
            newAvailableLicences.role_cadmin = Math.max(newAvailableLicences.role_cadmin - 1, 0);
          } else if (selectedUserType === USER_ROLES.CALL_CENTRE_ADMIN) {
            newAvailableLicences.role_ccadmin = Math.max(newAvailableLicences.role_ccadmin - 1, 0);
          }
        }

        if (!companyUserRole && !isReseller && !isSuperAdmin) {
          if (selectedUserType === USER_ROLES.CALL_CENTRE_ADMIN) {
            newAvailableLicences.role_ccadmin = Math.max(newAvailableLicences.role_ccadmin - 1, 0);
          } else if (selectedUserType === USER_ROLES.CALL_CENTRE_AGENT) {
            newAvailableLicences.role_ccagent = Math.max(newAvailableLicences.role_ccagent - 1, 0);
          } else if (selectedUserType === USER_ROLES.CALL_CENTRE_TEAM_LEAD) {
            newAvailableLicences.role_ccteamlead = Math.max(newAvailableLicences.role_ccteamlead - 1, 0);
          }
        }

        user.sub_user_licence_id.available_licence = newAvailableLicences;
        return user
        // Save updated user data back to AsyncStorage
        // await AsyncStorage.setItem('user', JSON.stringify(user));
      } else {
        console.log('sub_user_licence_id or available_licence not found in user object');
      }
    } catch (error) {
      console.error('Error updating available licences:', error);
    }
  };

  const updateLocalStorage = async (finalData) => {
    try {
        // Save updated user data back to AsyncStorage
        await AsyncStorage.setItem('user', JSON.stringify(finalData));
    } catch (error) {
      console.error('Error updating available licences:', error);
    }
  };


  async function submitForm() {
    setButtonDisable(true);

    if (!validateForm()) {
      setButtonDisable(false);
      return;
    }

    if (Object.values(errors).some(value => value !== "")) {
      setToastProps({ message: "Enter All correct values", type: "danger" });
      setButtonDisable(false);
      return;
    }

    const sub_licence = {
      role_cadmin: Number(companyUser),
      role_ccadmin: Number(callCenterAdminUser),
      role_ccagent: Number(callCenterAdminAgent),
      role_ccteamlead: Number(callCenterAdminTeamlead),
      agent: Number(agent),
      channel: Number(channel)
    };

    const companyParams = {
      name: companyName,
      phone: companyPhone,
      pincode: companyPincode,
      address: companyAddress,
    };
    const params = {
      username,
      password,
      name,
      email,
      acl_settings: (selectedACL === "") ? null: selectedACL,
      // ...(isSuperAdmin && { licence: licence }),
      ...(companyUserRole && selectedUserType == "role_ccadmin" && { flow_type: Number(selectedFlowType) })
    };

    if (!(selectedUserType === USER_ROLES.CALL_CENTRE_ADMIN || isCallCenterAdmin)) {
      params.company = companyParams
    }

    params.role = (isSuperAdmin || isCompanyAdmin || isCallCenterAdmin) ? selectedUserType : activeUserAccessRole

    let finalData
    if (!isReseller && !isSuperAdmin) {
      finalData = await updateAvailableLicences(sub_licence);
      params.parent_licence = finalData?.sub_user_licence_id?.available_licence
    }

    if (!isSuperAdmin) {
      params.sub_licence = sub_licence
    }

    try {
      const resp = await postApiCall(API_USERS_SIGNUP, params);
  
      if (resp) {
        await onUserCreateSuccess(resp, finalData);
      } else {
        throw new Error(resp?.message || "User creation failed");
      }
    } catch (error) {
      onUserCreateError(error);
    }
  }

  async function onUserCreateSuccess(resp, finalData) {
    if (!isReseller && !isSuperAdmin) {
      await updateLocalStorage(finalData);
    }
  
    setToastProps({ message: "User created successfully", type: "success" });
  
    setTimeout(() => {
      navigate(`/${ROUTES.USERS.LIST}`);
    }, 1500);
  }

  function onUserCreateError(err) {
    if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
      errorCheck(err, navigate, setToastProps, setShowToast)
   } else {
     setButtonDisable(false);
     setToastProps({message: JSON.stringify(err), type: 'danger'});
   }
  }

  
  function onError(err) {
    if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
     errorCheck(err, navigate, setToastProps, setShowToast)
    } else {
     console.log(err)
    }
 }

 const handleEmailChange = (e) => {
  const value = e.target.value;
  setEmail(value);
  setEmailError(validateEmail(value));
};

  return (
    <>
      <div>
        <Row>
          <Col xl="12" lg="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">New User Information</h4>
                  {/* <RenderUserAccessRolesList
                    activeUserAccessRole={activeUserAccessRole}
                    userAccessRoles={userAccessRoles}
                    setActiveUserAccessRole={setActiveUserAccessRole}
                  /> */}
                </div>
              </Card.Header>
              <Card.Body>
                <div className="new-user-info">
                  <form>
                    <div className="row">
                      <Form.Group className="col-md-6 form-group">
                        {/* <Form.Control
                          type="text"
                          id="username"
                          placeholder="User Name"
                          autoComplete="off"
                          maxLength={100}
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          required
                        /> */}
                        <Form.Control
                          type="text"
                          id="username"
                          placeholder="User Name"
                          autoComplete="off"
                          maxLength={100}
                          value={username}
                          readOnly
                          onFocus={(e) => e.target.removeAttribute("readOnly")}
                          onChange={(e) => setUsername(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="col-md-6 form-group">
                        <Form.Control
                          type="text"
                          id="name"
                          placeholder="Name"
                          maxLength={100}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </div>
                    <div className="row">
                      <Form.Group className="col-md-6 form-group">
                        <Form.Control
                          type="email"
                          id="email"
                          placeholder="Email"
                          maxLength={100}
                          value={email}
                          onChange={handleEmailChange}
                          required
                        />
                        {emailError && <span className="text-danger">{emailError}</span>}
                      </Form.Group>
                      <Form.Group className="col-md-6 form-group">
                          <Form.Select
                            id="aclSetting"
                            required
                            value={selectedACL}
                            className="p-2"
                            onChange={(e) => setSelectedACL(e.target.value)}
                          >
                            <option value={""}>ACL Setting</option>
                            {aclSettingList.map(function (val, idx) {
                              return (
                                <option key={idx} value={val._id}>
                                  {val.acl_name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="row">
                        {/* <Form.Control
                          type="text"
                          id="username"
                          placeholder="User Name"
                          autoComplete="off"
                          maxLength={100}
                          value={username}
                          readOnly
                          onFocus={(e) => e.target.removeAttribute("readOnly")}
                          onChange={(e) => setUsername(e.target.value)}
                          required
                        /> */}
                      <Form.Group className="col-md-6 form-group">
                        <Form.Control
                          type="password"
                          id="pass"
                          placeholder="Password"
                          autoComplete="off"
                          maxLength={100}
                          value={password}
                          readOnly
                          onFocus={(e) => e.target.removeAttribute("readOnly")}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="col-md-6 form-group">
                        <Form.Control
                          type="password"
                          id="rpass"
                          placeholder="Confirm Password"
                          autoComplete="off"
                          maxLength={100}
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </div>
                    {ifRenderCompanyFields && !(selectedUserType === USER_ROLES.CALL_CENTRE_ADMIN) ? (
                      RenderUserCompanyFields({
                        company_name: companyName,
                        set_company_name: setCompanyName,
                        company_phone: companyPhone,
                        set_company_phone: setCompanyPhone,
                        company_address: companyAddress,
                        set_company_address: setCompanyAddress,
                        company_pincode: companyPincode,
                        set_company_pincode: setCompanyPincode,
                      })
                    ) : (
                      <></>
                    )}
                    {/* Row with userType and licence dropdowns */}
                      {(isSuperAdmin || isCompanyAdmin || isCallCenterAdmin) ? (
                        <div className="row">
                          {/* userType Dropdown */}
                          <Form.Group className="col-md-6 form-group">
                            <Form.Select
                              id="userType"
                              required
                              value={selectedUserType}
                              className="p-2"
                              onChange={(e) => setSelectedUserType(e.target.value)}
                            >
                              <option value="" disabled hidden>
                                UserType
                              </option>
                              {isSuperAdmin && (
                                <>
                                  <option key="1" value="role_sub_superadmin">
                                    Sub-Superadmin
                                  </option>
                                  <option key="2" value="role_reseller">
                                    Reseller
                                  </option>
                                </>
                              )}
                              {isCompanyAdmin && (
                                <>
                                  <option key="1" value="role_cadmin">
                                    Company User
                                  </option>
                                  <option key="2" value="role_ccadmin">
                                    Call-Center
                                  </option>
                                </>
                              )}
                              {isCallCenterAdmin && (
                                <>
                                  <option key="1" value="role_ccadmin">
                                    Call Centre Admin User
                                  </option>
                                  <option key="2" value="role_ccagent">
                                    Call Centre Admin Agent
                                  </option>
                                  <option key="3" value="role_ccteamlead">
                                    Call Centre Admin Team Lead
                                  </option>
                                </>
                              )}
                            </Form.Select>
                          </Form.Group>

                          {selectedUserType === "role_ccadmin"  && companyUserRole && (
                            <Form.Group className="col-md-6 form-group">
                             <Form.Select
                                id="callCenterType"
                                required
                                value={selectedFlowType || ""}
                                className="p-2"
                                onChange={(e) => setSelectedFlowType(e.target.value)}
                                >
                               <option value="" disabled hidden>
                                 Select Flow Type
                               </option>
                               <option value="0">Asterisk</option>
                               <option value="1">Jambonz</option>
                             </Form.Select>
                           </Form.Group>
                         )}

                          {/* Licence Dropdown */}
                          {/* {isSuperAdmin && 
                          <Form.Group className="col-md-6 form-group">
                            <Form.Select
                              id="licence"
                              required
                              value={licence}
                              className="p-2"
                              onChange={(e) => setSelectedLicence(e.target.value)}
                            >
                              <option value="" disabled hidden>
                                Select Licence
                              </option>
                              {Array.from({ length: 10 }, (_, i) => (
                                <option key={(i + 1) * 10} value={(i + 1) * 10}>
                                  {(i + 1) * 10}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                          } */}
                        </div>
                      ) : (
                        /* Row with only licence dropdown when userType is not rendered */
                        <>
                        {/* {isSuperAdmin && <div className="row">
                          <Form.Group className="col-md-6 form-group">
                            <Form.Select
                              id="licence"
                              required
                              value={licence}
                              className="p-2"
                              onChange={(e) => setSelectedLicence(e.target.value)}
                            >
                              <option value="" disabled hidden>
                                Select Licence
                              </option>
                              {Array.from({ length: 10 }, (_, i) => (
                                <option key={(i + 1) * 10} value={(i + 1) * 10}>
                                  {(i + 1) * 10}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </div>
                        } */}
                        </>
                      )}

                     {!isSuperAdmin && 
                     <div className="scroll-container">
                       <Table
                        striped
                        bordered
                        hover
                        responsive
                        style={{ textAlign: "center" }}
                       >
                        <thead>
                          <tr>
                            {!isCallCenterAdmin && (<th style={{ width: "15%" }}>Company User</th>)}
                            <th style={{ width: "15%" }}>
                              Callcenter Admin User
                             </th>
                            <th style={{ width: "15%" }}>
                              Callcenter Admin Agent
                            </th>
                            <th style={{ width: "15%" }}>
                              Callcenter Admin Teamlead
                            </th>
                            <th style={{ width: "25%" }}>Total Agent</th>
                            <th style={{ width: "25%" }}>Channels</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                              {!isCallCenterAdmin && (
                                <td>
                                {" "}
                                  <Form.Group className="form-group">
                                    <Form.Control
                                      type="number"
                                      id="companyUser"
                                      placeholder="Company User"
                                      maxLength={100}
                                      min={0}
                                      max={availableLicence?.role_cadmin}
                                      value={companyUser}
                                      onChange={(e) => setCompanyUser(validateInput(e.target.value, "role_cadmin"))}
                                      required
                                    />
                                    {errors.role_cadmin && <small className="text-danger">{errors.role_cadmin}</small>}
                                  </Form.Group>
                                </td>
                              )}
                             
                              <td> 
                                <Form.Group className="form-group">
                                <Form.Control
                                  type="number"
                                  id="companyAdminUser"
                                  placeholder="Callcenter Admin User"
                                  maxLength={100}
                                  min={0}
                                  max={availableLicence?.role_ccadmin}
                                  value={callCenterAdminUser}
                                  onChange={(e) => setCallCenterAdminUser(validateInput(e.target.value, "role_ccadmin"))}
                                  required
                                />
                                {errors.role_ccadmin && <small className="text-danger">{errors.role_ccadmin}</small>}
                              </Form.Group>
                            </td>
                            <td>                       
                              <Form.Group className="form-group">
                              <Form.Control
                              type="number"
                              id="callcenterAdminAgent"
                              placeholder="Callcenter Admin Agent"
                              maxLength={100}
                              min={0}
                              max={availableLicence?.role_ccagent}
                              value={callCenterAdminAgent}
                              onChange={(e) => setCallCenterAdminAgent(validateInput(e.target.value, "role_ccagent"))}
                              required
                              />
                              {errors.role_ccagent && <small className="text-danger">{errors.role_ccagent}</small>}
                              </Form.Group>
                             </td>
                             <td>                            
                              <Form.Group className="form-group">
                                <Form.Control
                                type="number"
                                id="callcenterAdminTeamLead"
                                placeholder="Callcenter Admin Teamlead"
                                maxLength={100}
                                min={0}
                                max={availableLicence?.role_ccteamlead}
                                value={callCenterAdminTeamlead}
                                onChange={(e) => setCallCenterAdminTeamlead(validateInput(e.target.value, "role_ccteamlead"))}
                                required
                                />
                                {errors.role_ccteamlead && <small className="text-danger">{errors.role_ccteamlead}</small>}
                                </Form.Group>
                              </td>
                              <td style={{ minHeight: "60px" , minWidth:"160px" }}>                        
                                <Form.Group className="form-group">
                                <Form.Control
                                type="number"
                                id="agent"
                                placeholder="Agent"
                                maxLength={100}
                                min={0}
                                max={availableLicence?.agent}
                                value={agent}
                                onChange={(e) => setAgent(validateInput(e.target.value, "agent"))}
                                required
                                />
                                {errors.agent && <small className="text-danger">{errors.agent}</small>}
                                </Form.Group>
                                </td>
                                <td style={{ minHeight: "60px" , minWidth:"160px" }}>                        
                                  <Form.Group className="form-group">
                                  <Form.Control
                                  type="number"
                                  id="channel"
                                  placeholder="Channel"
                                  maxLength={100}
                                  min={0}
                                  max={availableLicence?.channel}
                                  value={channel}
                                  onChange={(e) => setChannel(validateInput(e.target.value, "channel"))}
                                  required
                                  />
                                  {errors.channel && <small className="text-danger">{errors.channel}</small>}
                                  </Form.Group>
                                </td>
                               </tr>
                            </tbody>
                        </Table>
                      </div>
                     }
                    <Button
                      onClick={async () => {
                        submitForm();
                      }}
                      type="button"
                      variant="btn btn-primary"
                      className="mt-4"
                      disabled={buttonDisable}
                    >
                      Add New User &nbsp;
                      {buttonDisable ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                    </Button>
                  </form>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      <ToastNotification
        show_toast={showToast}
        set_show_toast={setShowToast}
        {...toastProps}
      />
    </>
  );
};

export default UserAdd;
