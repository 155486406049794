import React, { useState, useEffect } from 'react';
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import Card from '../../../components/Card'; // Assuming the same Card component is used
import { getApiCall } from '../../../service/getApiCall';
import { API_AGENTS_LIST } from '../../../service/url';
import { useLocation } from 'react-router-dom';
import { postApiCall } from '../../../service/postApiCall';
import { useNavigate } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';
import LottieAnimation from '../../../components/lottie/lottie';
import './slider.css'
import { DeleteIcon, EditIcon } from '../../../components/setting/elements';
import { BACKEND_API_BASE_URL} from "../../../utilities/constant";

const GroupSettings = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [showAddMembersModal, setShowAddMembersModal] = useState(false);
    const [agentGroup, setAgentGroup] = useState({});

    const [showTimeScheduleModal, setShowTimeScheduleModal] = useState(false);
    const [showWhisperModal, setShowWhisperModal] = useState(false);
    const [agents, setAgents] = useState([]);
    const [showCallStrategyModal, setShowCallStrategyModal] = useState(false);
  
    const [selectedAgentIds, setSelectedAgentIds] = useState([]);
    const [startTime, setStartTime] = useState("00:00");
    const [endTime, setEndTime] = useState("23:59");

    const [weekDays, setWeekDays] = useState(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]);
    const [memberWeekDays, setmemberWeekDays] = useState(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]);
    const [selectedStrategy, setSelectedStrategy] = useState("ROUNDROBIN RINGING");
    const [sticky, setSticky] = useState(false)
    const [time, setTime] = useState(false);
    const [callStrategy, setCallStratergy] = useState([])
    const [showEditModal, setShowEditModal] = useState(false)
    const [showToastModal, setShowToastModal] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [assignedAgents, setAssignedAgents] = useState([]); 
    const [availableAgents, setAvailableAgents] = useState([]); 
    const [groupId, setGroupId] = useState(location.state?.groupId);
    const [editData, setEditData] = useState({
        id: '',
        startTime: "00:00",
        endTime: "23:59",
        weekDays: [],
        agent_id : ''
    });
    const [timeScheduledDate, setTimeScheduledDate] = useState({
        startTime: "00:00",
        endTime: "23:59",
        weekDays: [],
    });
    const [timeScheduleWholeDay, setTimeScheduleWholeDay] = useState(false);
    const [editDataWholeDay, setEditDataWholeDay] = useState(false);
    const [showMusicOnHold, setShowMusicOnHold] = useState(false);



    const [wholeDay, setWholeDay] = useState(false);
    const [groupScheduledId, setGroupScheduledId] = useState('');
    const [selectedWhisper, setSelectedWhisper] = useState('');
    const [selectedMusicHold, setSelectedMusicHold] = useState('');



      useEffect(() => {
          getApiCall(`${BACKEND_API_BASE_URL}/v1/prompt?prompt_status=1`)
            .then(onPromptsSuccess)
            .catch(onError)
        }, []);


    function onPromptsSuccess(resp) {
        setAudio(resp)
    }
      
    function onError(err) {
        console.log(err);
    }

    const [audio, setAudio] = useState([]);
        


    const handleWholeDayChange = (e) => {
        const isChecked = e.target.checked;
        setWholeDay(isChecked);
    
        if (isChecked) {
            setStartTime("00:00");
            setEndTime("23:59");
        } else {
            setStartTime("");
            setEndTime("");
        }
    };
    
    const handleStartTimeChange = (e) => {
        if (!wholeDay) {
            setStartTime(e.target.value);
        }
    };
    
    const handleEndTimeChange = (e) => {
        if (!wholeDay) {
            setEndTime(e.target.value);
        }
    };
    

    const showToast = (message) => {
        setToastMessage(message);
        setShowToastModal(true);

        // Hide modal automatically after 3 seconds
        setTimeout(() => {
            setShowToastModal(false);
        }, 3000);
    };

    const handleAddMembersClick = () => {
        setShowAddMembersModal(true);
    };

    const handleModalClose = () => {
        setShowAddMembersModal(false);
    };

    const handleTimeScheduleClick = () => {
        setStartTime("");
        setEndTime("");
        setShowTimeScheduleModal(true);
    };
    

    const handleTimeScheduleClose = () => {
        setTime(true)
        setShowTimeScheduleModal(false);
    };

    const handleWhisperClick = () => {
        setShowWhisperModal(true);
    };

    const handleWhisperClose = () => {
        setShowWhisperModal(false);
    };

    const handleCallStrategyClick = () => {
        setShowCallStrategyModal(true);
    };

    const handleCallStrategyCloseall = () => {
        setShowCallStrategyModal(false);
    }

    const handleCallStrategyClose = () => {

        const finalPayload = {
            strategy: selectedStrategy,
            type: 'strategy'
        }

        postApiCall(`/v1/agent-group/${groupId}`, finalPayload).then((response) => {
            if (response) {
                setShowCallStrategyModal(false)
                showToast('Call Stratergy Updated');
                setShowAddMembersModal(false);
            }
        }).catch((err) => {
            toast.error(err)
        })
        setShowCallStrategyModal(false);
    };

    const handleSaveTimeSchedule = () => {
        timeScheduledDate.type = 'time_schedule'
        timeScheduledDate.group_schedule_id = groupScheduledId

        postApiCall(`/v1/agent-group/${groupId}`, timeScheduledDate)
            .then((response) => {
                if (response) {
                    setGroupScheduledId(response?.agent?._id)
                    setShowTimeScheduleModal(false);
                    showToast('Time Schedule Updated');
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    };
    
    

    const getAgentGroupData = async () => {
        try {
            const response = await getApiCall(`/v1/agent-group/${groupId}`);
            if (response)
                {
                    setTimeScheduledDate({
                        startTime: response?.group_schedule_id?.start_time || '',
                        endTime: response?.group_schedule_id?.end_time || '',
                        weekDays: response?.group_schedule_id?.week_days || []
                    });
                    setSelectedStrategy(response?.strategy)
                    setSelectedWhisper(response?.whisper_id)
                setAgentGroup(response);
                setGroupScheduledId(response.group_schedule_id)
            } else {
                toast.error("Failed to fetch assigned agents.");
            }
        } catch (error) {
            console.error("Error fetching assigned agents:", error);
            toast.error("Error fetching assigned agents.");
        }
    };

    

    const handleEditClick = (agent) => {
        setEditData({
            agent_id: agent.agent?._id,
            id: agent.schedule?._id,
            startTime: agent.schedule?.start_time || "00:00:00 AM",
            endTime: agent.schedule?.end_time || "11:59:59 PM",
            weekDays: agent.schedule?.week_days || ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
        });
        setShowEditModal(true);
    };
    
    


    const fetchAgentData = async () => {
        try {
            const [agentsResponse, assignedAgentsResponse] = await Promise.all([
                getApiCall(API_AGENTS_LIST),
                getApiCall(`/v1/agent-group/${groupId}/assigned-agents`)
            ]);

            console.log('agentsResponseagentsResponseagentsResponse', agentsResponse)

            console.log('assignedAgentsResponseassignedAgentsResponse', assignedAgentsResponse)


            if (agentsResponse) {
                const assignedFromList = agentsResponse.filter((agent) => agent.is_allocated === 1);
                const available = agentsResponse.filter((agent) => agent.is_allocated === 0);
                setAvailableAgents(available);
            }
    
            if (assignedAgentsResponse) {
                setAssignedAgents(assignedAgentsResponse);
            }
    
        } catch (error) {
            console.error("Error fetching agents:", error);
            toast.error("Failed to fetch agent data.");
        }
    };
    
    const handleAddMembersSave = async () => {
        const selectedAgents = availableAgents
            .filter((agent) => selectedAgentIds.includes(agent._id))
            .map((agent) => ({
                ...agent,
                startTime,
                endTime,
                is_allocated: 1, // Mark agent as allocated
            }));
    
        const AddmemberPayload = {
            type:'add_member',
            agent_id: selectedAgents.map((data) => data._id),
            member_count: selectedAgents?.length,
            memberSchedulePayload: {
                week_days: memberWeekDays,
                start_time: startTime,
                end_time: endTime,
            }
        };
    
        try {
            // Step 1: Update agents in the group
            const [addMembersResponse] = await Promise.all([
                postApiCall(`/v1/agent-group/${groupId}`, AddmemberPayload),
            ]);
            if (addMembersResponse) {
                showToast('Members added, schedules updated, and allocation updated successfully');
    
                setAssignedAgents((prev) => [...prev, ...selectedAgents]);
                setAvailableAgents((prev) =>
                    prev.filter((agent) => !selectedAgentIds.includes(agent._id))
                );
    
                setSelectedAgentIds([]);
                setShowAddMembersModal(false);
                await fetchAgentData();
            } else {
                toast.error("Failed to complete all member actions. Please try again.");
            }
        } catch (error) {
            console.error("Error in handleAddMembersSave:", error);
            toast.error(`Error: ${error.message || 'Something went wrong'}`);
        }
    };
    
    



    const handleSticky = (value) => {
        const finalPayload = {
            sticky: value
        }

        postApiCall(`/v1/agent-group/${groupId}`, finalPayload).then((response) => {
            if (response) {
                toast.success(value ? "Sticky On " : "Sticky Off");
                setShowAddMembersModal(false);
            }
        }).catch((err) => {
            toast.error(err)
        })
    }

    
    const handleMusicOnHold = (value) => {

        const finalPayload = {}
        if (value) {
          setShowMusicOnHold(true)
          return;
        } else {
            finalPayload.music_on_hold = null
        }

        postApiCall(`/v1/agent-group/${groupId}`, finalPayload).then((response) => {
            if (response) {
                toast.success("Ringing Set");
                setShowMusicOnHold(false);
            }
        }).catch((err) => {
            toast.error(err)
        })
    }

    const fetchCallStratergies = () => {

        getApiCall('/v1/call-stratergy').then((data) => {
            setCallStratergy(data.strategies);
        }).catch((err) => {
            toast.error("Error fetching call stratergies", { duration: 4000 })
        })
    }

    // useEffect(async () => {
    //     if (location.state?.groupId) {
    //         setGroupId(location.state.groupId);
    //     }
    //     await fetchAgentData();
    //     fetchCallStratergies();
    //     await getAgentGroupData()
    // }, []);
    
    useEffect(() => {
        const fetchData = async () => {
            if (location.state?.groupId) {
                setGroupId(location.state.groupId);
            }
            
            await fetchAgentData();  // Pass groupId as a parameter
            fetchCallStratergies();
            await getAgentGroupData();
        };
    
        fetchData();
    }, []);
    
    const handleRemoveAgent = async (agentId) => {
        try {
            await postApiCall(`/v1/agent-group/remove-agent/${groupId}`, { agent_id: agentId });
            showToast('Agent removed successfully');
            await fetchAgentData()
        } catch (error) {
            toast.error('Failed to remove agent');
        }
    };

    const handleSaveAgentEdit = async () => {
        const finalPayload = {
            agent_id : editData.agent_id,
            id : editData.id,
            start_time: editData.startTime,
            end_time: editData.endTime,
            week_days: editData.weekDays,
        };

        await postApiCall(`/v1/agent-group/schedule/${groupId}`, finalPayload).then(async (response) => {
            if (response) {
                setShowEditModal(false);
                await fetchAgentData()
            }
        }).catch((err) => {
            console.error('Error updating agent time schedule:', err);
            toast.error('Failed to update agent time schedule');
        })
    
        // try {
        //     await postApiCall(`/v1/agent-group/schedule/${groupId}`, finalPayload);
        //     showToast('Agent time schedule updated successfully');
        //     setShowEditModal(false);
    
        //     setAssignedAgents((prev) =>
        //         prev.map((agent) =>
        //             agent._id === editData.id
        //                 ? {
        //                       ...agent,
        //                       time_schedule: {
        //                           start_time: formattedStartTime,
        //                           end_time: formattedEndTime,
        //                           week_days: editData.weekDays,
        //                       },
        //                   }
        //                 : agent
        //         )
        //     );
        // } catch (error) {
        //     console.error('Error updating agent time schedule:', error);
        //     toast.error('Failed to update agent time schedule');
        // }
    };

    const handlesave = async () => {
        showToast('Agent Group Updated Successfully');
        navigate('/agent-group-list')

        // const finalPayload = {
        //     agent_id: assignedAgents.map((agent) => agent._id),
        //     strategy: selectedStrategy,
        //     sticky: sticky,
        //     time_schedule: [
        //         {
        //             week_days: [...weekDays],
        //             time: time,
        //         },
        //     ],
        //     add_member_schedule: [
        //         {
        //             week_days: memberWeekDays,
        //             start_time: startTime,
        //             end_time: endTime,
        //         },
        //     ],
        // };


        // postApiCall(`/v1/agent-group/${groupId}`, finalPayload)
        //     .then((response) => {
        //         if (response) {
        //             navigate('/agent-group-list')
        //         }
        //     })
        //     .catch((error) => {
        //         console.log("error", error);
        //     });
    };

    const handleWhisperSave = () => {

        const finalPayload = {
            whisper_id: selectedWhisper,
        }

        postApiCall(`/v1/agent-group/${groupId}`, finalPayload).then((response) => {
            if (response) {
                showToast('Whisper Updated');
                setShowWhisperModal(false);
            }
        }).catch((err) => {
            toast.error(err)
        })
        setShowWhisperModal(false);
    };

    const handleMusicHoldSave = () => {

        const finalPayload = {
            music_on_hold: selectedMusicHold,
        }

        postApiCall(`/v1/agent-group/${groupId}`, finalPayload).then((response) => {
            if (response) {
                showToast('Music On Hold Set');
                setShowMusicOnHold(false);
            }
        }).catch((err) => {
            toast.error(err)
        })
        setShowMusicOnHold(false);
    };


    return (
        <div style={{ marginTop: '20px' }}>
            <Toaster />
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header>
                            <h4>Group Settings</h4>
                        </Card.Header>
                        <Card.Body>
                            <p>You can configure the IVR settings according to the created call group from adding members to time schedules and call strategies.</p>

                            <div style={{ display: "flex", justifyContent: "space-between" }}>

                                <div className="cardy">
                                    <div className="card cardx">
                                        <button className="btn btn-danger btn-sm me-3 mt-4 btncardy" onClick={handleAddMembersClick}>+ Add Members</button>
                                        <p className="mb-0">Include your member in this group to assign workflows specifically according to time schedules and call strategies.</p>
                                    </div>

                                </div>

                                <div className="cardy">
                                    <div className="card cardx">
                                    <button className="btn btn-danger btn-sm me-3 mt-4 btncardy " onClick={handleTimeScheduleClick} >+ Time Schedule</button>
                                        <p className="mb-0">Account owner can configure when calls are routed to available members based on provided scheduled business hours.</p>
                                        

                                    </div>
                                </div>

                                <div className="cardy">
                                    <div className="card cardx">
                                    <button className="btn btn-danger btn-sm me-3 btncardy mt-4" onClick={handleCallStrategyClick}>+ Call Strategy</button>
                                        <p className="mb-0">It provides a method to handle call flows for members with several extensions using a variety of routing strategies.</p>
                                        

                                    </div>
                                </div>


                                {/*Agent whisper */}
                                <div className="cardy" >
                                    <div className="card cardx" >
                                    <button className="btn btn-danger btn-sm me-3 mt-4 btncardy" onClick={handleWhisperClick}>+ Agent Whisper</button>
                                    <div className="shine"></div>
                                        <p className="mb-0">Agent Whisper voice messages so that your team can hear this voice prompt first before connecting with the customer.</p>
                                        
                                    </div>
                                    <div className="card">
                                        <span className="icon">
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="mb-3">
                                <div className="d-flex align-items-center">
                                    <button className="btn btn-danger btn-sm me-3" style={{backgroundColor:"#4D869C", border:"none"}} onClick={handleWhisperClick}>+ Configure Whisper</button>
                                    <p className="mb-0">Configure Whisper voice messages so that your team can hear this voice prompt first before connecting with the customer.</p>
                                </div>
                                <hr style={{ border: 'none', height: '1px', backgroundColor: '#dee2e6', margin: '0.5rem 0' }} />
                            </div> */}

                            <div className="mt-4">
                                <p>Only the assigned member will receive calls from the customer. If they don't pick up, the call will get forwarded to the next member automatically:</p>
                                <div className="d-flex align-items-center mb-3">
                                    <div className="form-check me-4">
                                        <input type="radio" id="normal" name="stickyMember" className="form-check-input" />
                                        <label htmlFor="normal" className="form-check-label">Normal</label>
                                    </div>
                                    <div className="form-check me-4">
                                        <input type="radio" id="advance" name="stickyMember" className="form-check-input" />
                                        <label htmlFor="advance" className="form-check-label">Advance</label>
                                    </div>
                                    <div className="form-check">
                                        <input type="radio" id="off" name="stickyMember" className="form-check-input" defaultChecked />
                                        <label htmlFor="off" className="form-check-label">Off</label>
                                    </div>
                                </div>
                                <hr />
                            </div>

                            <div className="mt-4">
                                <p>Multi IVR Sticky:</p>
                                <div className="d-flex align-items-center mb-3">
                                    <div className="form-check me-4">
                                        <input type="radio" id="multiIvrOn" name="multiIvr" className="form-check-input" onChange={() => handleSticky(true)} />
                                        <label htmlFor="multiIvrOn" className="form-check-label" >On</label>
                                    </div>
                                    <div className="form-check">
                                        <input type="radio" id="multiIvrOff" name="multiIvr" className="form-check-input" defaultChecked onChange={() => handleSticky(false)} />
                                        <label htmlFor="multiIvrOff" className="form-check-label">Off</label>
                                    </div>
                                </div>
                                <hr />
                            </div>

                            <div className="mt-4">
                                {/* <p>Multi IVR Sticky:</p> */}
                                <div className="d-flex align-items-center mb-3">
                                    <div className="form-check me-4">
                                        <input type="radio" id="musicOnHold" name="music" className="form-check-input" onChange={() => handleMusicOnHold(true)} />
                                        <label htmlFor="musicOnHold" className="form-check-label" >Music On Hold</label>
                                    </div>
                                    <div className="form-check">
                                        <input type="radio" id="ringing" name="music" className="form-check-input" defaultChecked onChange={() => handleMusicOnHold(false)} />
                                        <label htmlFor="ringing" className="form-check-label">Ringing</label>
                                    </div>
                                </div>
                                <hr />
                            </div>

                            <div className="mt-4 text-center">
                                <button className="btn btn-danger btncardy" onClick={handlesave}  >
                                    Save
                                </button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>



            <div className="mt-4">
    <h5>Assigned Agents</h5>
    <table className="table table-bordered" style={{ marginTop: "20px" }}>
        <thead>
            <tr>
                <th>S.No</th>
                <th>Team Member Name</th>
                <th>Mobile Number</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Agent Status</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
    {assignedAgents.length > 0 ? (
        assignedAgents.map((agent, index) => (
            <tr key={agent.agent?._id}>
                <td>{index + 1}</td>
                <td>{agent.agent?.agent_name}</td>
                <td>{agent.agent?.agent_number}</td>
                <td>{agent.schedule?.start_time || "00:00"}</td>
                <td>{agent.schedule?.end_time || "23:59"}</td>
                <td>{agent.agent?.login_status === "1" ? "Active" : "Inactive"}</td>
                <td>
                    <div className="actions" style={{ display: 'flex', gap: '5px' }}>
                        <div onClick={() => handleEditClick(agent)}>
                            <EditIcon title="Edit" />
                        </div>
                        <div>
                            <DeleteIcon
                                title="Delete"
                                handleCallback={() => handleRemoveAgent(agent.agent?._id)}
                            />
                        </div>
                    </div>
                </td>
            </tr>
        ))
    ) : (
        <tr>
            <td colSpan="8" className="text-center">No assigned agents found</td>
        </tr>
    )}
</tbody>

    </table>
</div>





            {/* Add Members Modal */}


            <Modal show={showAddMembersModal} onHide={handleModalClose} size="lg" centered>
                <Modal.Header closeButton >
                    <Modal.Title>Add Members in Call Group</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Row>
                        <Col>

                            <div style={{
                                maxHeight: '300px',
                                overflowY: 'auto',
                                scrollbarWidth: 'thin', // For Firefox
                            }}>
                                <table className="table table-bordered" >
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Team Member Name</th>
                                            <th>Mobile Number</th>
                                            <th>

                                                <input
                                                    type="checkbox"
                                                    // style={{ marginLeft: '10px' }}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            // Select all agents
                                                            setSelectedAgentIds(availableAgents.map((agent) => agent._id));
                                                        } else {
                                                            // Deselect all agents
                                                            setSelectedAgentIds([]);
                                                        }
                                                    }}
                                                    checked={selectedAgentIds.length === availableAgents.length && availableAgents.length > 0}
                                                />
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {availableAgents.map((agent, index) => (
                                            <tr key={agent._id}>
                                                <td>{index + 1}</td>
                                                <td>{agent.agent_name}</td>
                                                <td>{agent.agent_number}</td>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedAgentIds.includes(agent._id)}
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                setSelectedAgentIds((prev) => [...prev, agent._id]);
                                                            } else {
                                                                setSelectedAgentIds((prev) =>
                                                                    prev.filter((id) => id !== agent._id)
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>


                                </table>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Form>
                            {/* Whole Day Checkbox */}
                            <Form.Check
                                type="checkbox"
                                label="Whole Day"
                                id="wholeDay"
                                className="mb-3"
                                checked={wholeDay}
                                onChange={handleWholeDayChange}
                            />

                            {/* Start Time */}
                            <Form.Group className="mb-3">
                                <Form.Label>Start Time</Form.Label>
                                <Form.Control
                                    type="time"
                                    value={startTime}
                                    placeholder="00:00:00 AM"
                                    disabled={wholeDay}
                                    onChange={handleStartTimeChange}
                                />
                            </Form.Group>

                            {/* End Time */}
                            <Form.Group className="mb-3">
                                <Form.Label>End Time</Form.Label>
                                <Form.Control
                                    type="time"
                                    value={endTime}
                                    placeholder="11:59:59 PM"
                                    disabled={wholeDay}
                                    onChange={handleEndTimeChange}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                    <Form.Label>Select Days</Form.Label>
                                    <div>
                                        {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(
                                            (day) => (
                                                <Form.Check
                                                    inline
                                                    key={day}
                                                    label={day}
                                                    type="checkbox"
                                                    checked={memberWeekDays.includes(day)}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setmemberWeekDays((prev) => [...prev, day]);
                                                        } else {
                                                            setmemberWeekDays((prev) => prev.filter((d) => d !== day));
                                                        }
                                                    }}
                                                />
                                            )
                                        )}
                                    </div>
                                </Form.Group>
                        </Form>
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleAddMembersSave}>Add member</Button>
                </Modal.Footer>
            </Modal>


            {/* Call strategy */}
            <Modal show={showCallStrategyModal} onHide={handleCallStrategyCloseall} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Select Call Strategy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Select a Strategy:</Form.Label>
                            <div>
                                {callStrategy.map(
                                    (strategy) => (
                                        <Form.Check
                                            style={{ marginTop: "5px" }}
                                            type="radio"
                                            key={strategy}
                                            label={strategy}
                                            name="callStrategy"
                                            id={strategy.replace(/\s+/g, "-").toLowerCase()}
                                            checked={selectedStrategy === strategy}
                                            onChange={() => setSelectedStrategy(strategy)}
                                        />
                                    )
                                )}
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCallStrategyCloseall}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleCallStrategyClose}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>





            {/* Add Time Schedule Modal */}
            <Modal show={showTimeScheduleModal} onHide={handleTimeScheduleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Time Schedule Setting</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form>
                    <Form.Check
                        type="checkbox"
                        label="Whole Day"
                        id="wholeDay"
                        className="mb-3"
                        checked={timeScheduleWholeDay}
                        onChange={(e) => {
                            setTimeScheduleWholeDay(e.target.checked);
                            setTimeScheduledDate((prev) => ({
                                ...prev,
                                startTime: e.target.checked ? "00:00" : "",
                                endTime: e.target.checked ? "23:59" : "",
                            }));
                        }}
                    />

                    {/* Start Time */}
                    <Form.Group className="mb-3">
                            <Form.Label>Start Time</Form.Label>
                            <Form.Control
                                type="time"
                                value={timeScheduledDate.startTime}
                                placeholder="00:00"
                                disabled={timeScheduleWholeDay}
                                onChange={(e) =>
                                    setTimeScheduledDate((prev) => ({
                                        ...prev,
                                        startTime: e.target.value,
                                    }))
                                }
                            />
                    </Form.Group>

                    {/* End Time */}
                    <Form.Group className="mb-3">
                            <Form.Label>End Time</Form.Label>
                            <Form.Control
                                type="time"
                                value={timeScheduledDate.endTime}
                                placeholder="23:59"
                                disabled={timeScheduleWholeDay}
                                onChange={(e) =>
                                    setTimeScheduledDate((prev) => ({
                                        ...prev,
                                        endTime: e.target.value,
                                    }))
                                }
                            />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Select Days</Form.Label>
                        <div>
                            {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day) => (
                                <Form.Check
                                    inline
                                    key={day}
                                    label={day}
                                    type="checkbox"
                                    checked={timeScheduledDate?.weekDays?.includes(day)}
                                    onChange={(e) => {
                                        setTimeScheduledDate((prev) => ({
                                            ...prev,
                                            weekDays: e.target.checked
                                                ? [...prev.weekDays, day]
                                                : prev.weekDays.filter((d) => d !== day),
                                        }));
                                    }}
                                />
                            ))}
                        </div>
                    </Form.Group>
                </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleTimeScheduleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSaveTimeSchedule}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Show whisper model */}
            <Modal show={showWhisperModal} onHide={() => setShowWhisperModal(false)} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Agent Whisper Prompt</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                        <Form.Label>Select Prompt</Form.Label>
                          <Form.Control
                            as="select"
                            name="audio"
                            value={selectedWhisper}
                            onChange={(e) => setSelectedWhisper(e.target.value)}
                          >
                          <option value="">Select Prompt</option>
                          {audio.map((actionKey) => {
                            return (
                              <option key={actionKey._id} value={actionKey._id}>
                                {actionKey.prompt_name}
                              </option>
                            );
                          })}
                          </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleWhisperSave}>Submit</Button>
                    <Button variant="secondary" onClick={handleWhisperClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

             {/* Music on Hold*/}
             <Modal show={showMusicOnHold} onHide={() => setShowMusicOnHold(false)} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Music On Hold Prompt</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                        <Form.Label>Select Prompt</Form.Label>
                          <Form.Control
                            as="select"
                            name="audio"
                            value={selectedMusicHold}
                            onChange={(e) => setSelectedMusicHold(e.target.value)}
                          >
                          <option value="">Select Prompt</option>
                          {audio.map((actionKey) => {
                            return (
                              <option key={actionKey._id} value={actionKey._id}>
                                {actionKey.prompt_name}
                              </option>
                            );
                          })}
                          </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleMusicHoldSave}>Submit</Button>
                    <Button variant="secondary" onClick={() => setShowMusicOnHold(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* LOTTIE MODEL FOR TOAST */}
            <Modal show={showToastModal} centered onHide={() => setShowToastModal(false)} size="sm">
                <Modal.Body className="text-center">
                    <h5>{toastMessage}</h5>
                    <LottieAnimation />
                </Modal.Body>
            </Modal>

            {/* EDIT MODAL */}
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Member Schedule</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form>
                    <Form.Check
                        type="checkbox"
                        label="Whole Day"
                        id="wholeDay"
                        className="mb-3"
                        checked={editDataWholeDay}
                        onChange={(e) => {
                            setEditDataWholeDay(e.target.checked);
                            setEditData((prev) => ({
                                ...prev,
                                startTime: e.target.checked ? "00:00" : "",
                                endTime: e.target.checked ? "23:59" : "",
                            }));
                        }}
                    />

                    {/* Start Time */}
                    <Form.Group className="mb-3">
                            <Form.Label>Start Time</Form.Label>
                            <Form.Control
                                type="time"
                                value={editData.startTime}
                                placeholder="00:00"
                                disabled={editDataWholeDay}
                                onChange={(e) =>
                                    setEditData((prev) => ({
                                        ...prev,
                                        startTime: e.target.value,
                                    }))
                                }
                            />
                    </Form.Group>

                    {/* End Time */}
                    <Form.Group className="mb-3">
                            <Form.Label>End Time</Form.Label>
                            <Form.Control
                                type="time"
                                value={editData.endTime}
                                placeholder="23:59"
                                disabled={editDataWholeDay}
                                onChange={(e) =>
                                    setEditData((prev) => ({
                                        ...prev,
                                        endTime: e.target.value,
                                    }))
                                }
                            />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Select Days</Form.Label>
                        <div>
                            {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day) => (
                                <Form.Check
                                    inline
                                    key={day}
                                    label={day}
                                    type="checkbox"
                                    checked={editData?.weekDays?.includes(day)}
                                    onChange={(e) => {
                                        setEditData((prev) => ({
                                            ...prev,
                                            weekDays: e.target.checked
                                                ? [...prev.weekDays, day]
                                                : prev.weekDays.filter((d) => d !== day),
                                        }));
                                    }}
                                />
                            ))}
                        </div>
                    </Form.Group>
                </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSaveAgentEdit}
                    >
                        Save Changes
                    </Button>

                </Modal.Footer>
            </Modal>




        </div>
    );
};

export default GroupSettings;
