import React, { useEffect, useState, useRef } from 'react';
import Card from '../../../../components/Card';
import { Dropdown } from 'primereact/dropdown';
import ToastNotification from '../../../../components/Toast';
import { getApiCall , postApiCall} from '../../../../service/httpCall';
import { API_NUMBERS_LIST , API_AGENTS_LIST, API_AGENT_GROUPS_LIST, API_IVR_LIST, API_INBOUND_ROUTING} from '../../../../service/url';
import { useNavigate, useParams } from 'react-router-dom';
import LottieAnimation from '../../../../components/lottie/lottie';
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import { WEEK_DAYS, ROUTES} from '../../../../utilities/constant'
import agentImg from '../../../../assets/images/icons/agent.png';
import callgroupImg from '../../../../assets/images/icons/callgroup.png';
import ivrImg from '../../../../assets/images/icons/ivr.png';


const InboundCalls = () => {
    const [number, setNumber] = useState([]);
    const [ivr, setIvr] = useState([]);
    const [agent, setAgent] = useState([]);
    const [callGroup, setCallGroup] = useState([])
    const { cid } = useParams();
    const [toastProps, setToastProps] = useState(null);
    const [showToastModal, setShowToastModal] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [selectedIvr, setSelectedIvr] = useState(null);
    const [selectedNumber, setSelectedNumber] = useState(null);
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [selectedCallGroup, setSelectedCallGroup] = useState(null);
    const [startTime, setStartTime] = useState("00:00");
    const [endTime, setEndTime] = useState("00:00");
    const [weekDays, setWeekDays] = useState([]);
    const [timeScheduleModel, setTimeScheduleModel] = useState(false);
    const [previousTime, setPreviousTime] = useState({ start: "", end: "" });
    const [previousWeekDays, setPreviousWeekDays] = useState([]);
    const [isWholeDay, setIsWholeDay] = useState(false);
    const [isWholeWeek, setIsWholeWeek] = useState(false);
    const [scheduleData, setScheduleData] = useState({})
    const [action, setAction] = useState('')
    const [numberList, setNumberList] = useState([])
    const [numberType, setNumberType] = useState('')

    const dropdownRef = useRef(null);
    const [isDropdownFocused, setIsDropdownFocused] = useState(false);

    useEffect(() => {
      const handleKeyDown = (e) => {
        if (isDropdownFocused) {
          e.preventDefault();
        }
      };
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, [isDropdownFocused]);
  
    const handleFocus = () => {
      setIsDropdownFocused(true);
    };
  
    const handleBlur = () => {
      setIsDropdownFocused(false);
    };

    const navigate = useNavigate();

    const showToasts = (message) => {
        setToastMessage(message);
        setShowToastModal(true);

        // Hide modal automatically after 3 seconds
        setTimeout(() => {
            setShowToastModal(false);
        }, 3000);
    };

    useEffect(() => {
            if (toastProps) {
                setShowToast(true);
            }
    
        }, [toastProps]);


    const fetchNumber = () =>{
        getApiCall(API_NUMBERS_LIST).then((response)=>
        {
            if(response)
            {
              setNumber(response)
            }
        })
    }

    const fetchIVR = () =>{
      const apiIvrListUrl = API_IVR_LIST.replace(':cid', cid);
      getApiCall(apiIvrListUrl).then((response)=>
      {
          if(response)
          {
            setIvr(response)
          }
      })
    }
    const fetchAgent = () =>{
      getApiCall(API_AGENTS_LIST).then((response)=>
      {
          if(response)
          {
            setAgent(response)
          }
      })
    }
    const fetchCallGroup = () =>{
      getApiCall(API_AGENT_GROUPS_LIST).then((response)=>
      {
          if(response)
          {
            setCallGroup(response)
          }
      })
    }

    useEffect(()=> {
      fetchNumber();
      fetchIVR();
      fetchAgent();
      fetchCallGroup();
    },
    [])

    useEffect(()=> {
      handleNumberTypeFilter('DID')
      setNumberType('DID')
     },
    [number])

      const handleWholeDayChange = (e) => {
        const isChecked = e.target.checked;
        setIsWholeDay(isChecked); 
        if (isChecked) {
          setPreviousTime({ start: startTime, end: endTime });
          setStartTime("00:00");
          setEndTime("23:59");
        } else {
          setStartTime(previousTime.start || "");
          setEndTime(previousTime.end || "");
        }
      };
    
      const handleWholeWeekChange = (e) => {
        const isChecked = e.target.checked;
        setIsWholeWeek(isChecked); 
        if (isChecked) {
          setPreviousWeekDays(weekDays);
          setWeekDays(WEEK_DAYS);
        } else {
          setWeekDays(previousWeekDays || []);
        }
      };
    
      const handleSetSchedule = () => {
        if (endTime === "00:00" || weekDays.length === 0) {
          setToastProps({message: 'Please fill all fields', type: 'danger'});
          return; 
        }
        setScheduleData({
          start_time: startTime,
          end_time: endTime,
          week_days: weekDays
        })
        setTimeScheduleModel(false)
      };

      const handleScheduleClick = () => {
        setTimeScheduleModel(true);
      
        if (scheduleData && Object.keys(scheduleData).length > 0) {
          setStartTime(scheduleData.start_time);
          setEndTime(scheduleData.end_time);
          setWeekDays(scheduleData.week_days);
      
          if (scheduleData.week_days.length === 7) {
            setIsWholeWeek(true);
          } else {
            setIsWholeWeek(false)
          }
      
          if (
            scheduleData.start_time === "00:00" &&
            scheduleData.end_time === "23:59"
          ) {
            setIsWholeDay(true);
          } else {
            setIsWholeDay(false)
          }
        }
      };

      const handleActionChange = (e) => {
        setAction(e.value);
        setStartTime('00:00')
        setEndTime('00:00')
        setWeekDays([])
        setIsWholeDay(false)
        setIsWholeWeek(false)
        setScheduleData({})
      };

      function validateInboundCall() {
        let valid = true;
      
        if (!selectedNumber) {
          valid = false;
          setToastProps({ message: 'Number is required', type: 'danger' });
        } else if (!action) {
          valid = false;
          setToastProps({ message: 'Action is required', type: 'danger' });
        } else if (action === 'ivr' && !selectedIvr) {
          valid = false;
          setToastProps({ message: 'IVR must be selected', type: 'danger' });
        } else if (action === 'agent' && !selectedAgent) {
          valid = false;
          setToastProps({ message: 'Agent must be selected', type: 'danger' });
        } else if (action === 'callGroup' && !selectedCallGroup) {
          valid = false;
          setToastProps({ message: 'Call Group must be selected', type: 'danger' });
        }
      
        return valid;
      }
      

      const handleInboundCreate = () => {
        if (!validateInboundCall()) {
          return;
        }
        const final = {
          number: selectedNumber._id,
          numberType: numberType,
          action: action,
        };
      
        if (action === 'ivr') {
          final.id = selectedIvr.flow_id;
          final.name = selectedIvr.flow_name;
        }
      
        if (action === 'agent') {
          final.id = selectedAgent._id;
          final.name = selectedAgent.agent_name;
          final.agentSchedule = scheduleData;
        }
      
        if (action === 'callGroup') {
          final.id = selectedCallGroup._id;
          final.name = selectedCallGroup.group_name;
        }

        postApiCall(API_INBOUND_ROUTING, final)
          .then(onInboundCallSuccess)
          .catch(onInboundCallError);
      };

      function onInboundCallSuccess(resp) {
        showToasts("Inboud Routing Completed!")
        setTimeout(() => {
          navigate(`/${ROUTES.NUMBERS.LIST}`);
        }, 1500);
      }
      
      function onInboundCallError(err) {
          setToastProps({message: JSON.stringify(err), type: 'danger'});
      }

      const handleNumberTypeFilter = (type) => {
        setNumberType(type)
        let filteredData;
        if (type === 'DID') {
          filteredData = number.filter(item => item.number_type === 'DID' && item.routing_destination === null);
        } else if (type === 'VMN') {
          filteredData = number.filter(item => item.number_type === 'VMN' && item.routing_destination !== null);
        } else {
          filteredData = number.filter(item => item.number_type === 'TOLL FREE' && item.routing_destination !== null);
        }
        setNumberList(filteredData)
      };
      

    return (
      <>
        <div style={{ marginTop: "20px" }}>
          <Row>
            <Col sm="12">
              <Card>
                <Card.Header className="d-block">
                  <h4 className="card-title">Inbound Calls Configuration</h4>
                  <div className='button-group'>
                    <button className={`btn btn-primary btn-sm button-spacing ${numberType === 'DID' ? 'active' : ''}`} onClick={() => handleNumberTypeFilter('DID')}>DID</button>
                    <button className={`btn btn-primary btn-sm button-spacing ${numberType === 'VMN' ? 'active' : ''}`} onClick={() => handleNumberTypeFilter('VMN')}>VMN</button>
                    <button className={`btn btn-primary btn-sm button-spacing ${numberType === 'TOLL FREE' ? 'active' : ''}`} onClick={() => handleNumberTypeFilter('TOLL FREE')}>TOLL FREE</button>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row className="mb-3">
                    <Col md="6">
                      <label htmlFor="numberDropdown" className="form-label">
                        Select {numberType}
                      </label>
                      <Dropdown
                        style={{ height: "50%", alignItems: "center" }}
                        id="numberDropdown"
                        value={selectedNumber}
                        options={numberList}
                        onChange={(e) => setSelectedNumber(e.value)}
                        optionLabel="actual_number" 
                        optionKey="_id"
                        placeholder={`Select ${numberType}`}
                        className="form-control"
                      />
                    </Col>
                    <Col md="6">
                      <label htmlFor="ivrDropdown" className="form-label">
                          Select Action
                        </label>
                        <Dropdown
                          ref={dropdownRef}
                          style={{ height: "50%", alignItems: "center" }}
                          id="ivrDropdown"
                          value={action}
                          options={[
                            {
                              label : 'Select Action', value: ''
                            },{
                              label:<>
                              IVR <img src={ivrImg} style={{ width: "20px", height: "20px", marginLeft: "5px", marginTop: "-3px" }} alt="IVR" />
                            </>, value: 'ivr'
                          }, {
                            label : <>
                            Agent <img src={agentImg} style={{ width: "20px", height: "20px", marginLeft: "5px", marginTop: "-3px" }} alt="Agent"/>
                            </>, value: 'agent'
                          },{
                           label : <>
                            Call Group <img src={callgroupImg} style={{ width: "23px", height: "23px", marginLeft: "5px", marginTop: "-1px" }} alt="Call Group"/>
                            </>, value: 'callGroup'
                          }]}
                          onChange={handleActionChange}
                          placeholder="Select Action"
                          className="form-control"
                          filter={false}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                        />
                    </Col>
                  </Row>
                  {action === 'ivr' && (
                    <Row className="mb-3">
                    <Col md="8">
                      <label htmlFor="ivrDropdown" className="form-label">
                        Select IVR
                      </label>
                      <Dropdown
                        style={{ height: "50%", alignItems: "center" }}
                        id="ivrDropdown"
                        value={selectedIvr}
                        options={ivr}
                        onChange={(e) => setSelectedIvr(e.value)}
                        optionLabel="flow_name" 
                        optionKey="flow_id"
                        placeholder="Select IVR"
                        className="form-control"
                      />
                    </Col>
                    {/* <Col md="4">
                      <button
                        onClick={handleScheduleClick}
                        style={{
                          padding: "8px 16px",
                          backgroundColor: "#28A745",
                          color: "#fff",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                          marginLeft: "5px",
                          marginTop: "34px"
                        }}
                      >
                        Time Schedule
                      </button>
                    </Col> */}
                    
                    </Row>
                  )}
                  {action === 'agent' && (
                    <Row className="mb-3">
                    <Col md="8">
                      <label htmlFor="agentDropdown" className="form-label">
                        Select Agent
                      </label>
                      <Dropdown
                        style={{ height: "50%", alignItems: "center" }}
                        id="agentDropdown"
                        value={selectedAgent}
                        options={agent}
                        onChange={(e) => setSelectedAgent(e.value)}
                        optionLabel="agent_name" 
                        optionKey="_id"
                        placeholder="Select Agent"
                        className="form-control"
                      />
                    </Col>
                    <Col md="4">
                      <button
                        onClick={handleScheduleClick}
                        style={{
                          padding: "8px 16px",
                          backgroundColor: "#28A745",
                          color: "#fff",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                          marginLeft: "5px",
                          marginTop: "34px"
                        }}
                      >
                        Time Schedule
                      </button>
                    </Col>
                    </Row>
                  )}
                  {action === 'callGroup' && (
                    <Row className='mb-3'>  
                    <Col md="8">
                      <label htmlFor="agentGroupDropdown" className="form-label">
                        Select Call Group
                      </label>
                      <Dropdown
                        style={{ height: "50%", alignItems: "center" }}
                        id="agentGroupDropdown"
                        value={selectedCallGroup}
                        options={callGroup}
                        onChange={(e) => setSelectedCallGroup(e.value)}
                        optionLabel="group_name" 
                        optionKey="_id"
                        placeholder="Select Call Group"
                        className="form-control"
                      />
                    </Col>
                    <Col md="4">
                      <button
                        onClick={handleScheduleClick}
                        style={{
                          padding: "8px 16px",
                          backgroundColor: "#28A745",
                          color: "#fff",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                          marginLeft: "5px",
                          marginTop: "34px"
                        }}
                      >
                        Time Schedule
                      </button>
                    </Col>
                    </Row>
                  )}
                </Card.Body>
                <Card.Footer>
                  <button
                    onClick={handleInboundCreate}
                    style={{
                      padding: "8px 16px",
                      backgroundColor: "#28A745",
                      color: "#fff",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      marginLeft: "5px",
                    }}
                    >
                    Create
                  </button>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </div>

        <ToastNotification show_toast={showToast} set_show_toast={setShowToast} {...toastProps} />

        {/* LOTTIE MODEL FOR TOAST */}
        <Modal show={showToastModal} centered onHide={() => setShowToastModal(false)} size="sm">
                <Modal.Body className="text-center">
                    <h5>{toastMessage}</h5>
                    <LottieAnimation />
                </Modal.Body>
        </Modal>

        <Modal show={timeScheduleModel} onHide={() => setTimeScheduleModel(false)} size="lg" centered>
          <Modal.Header closeButton >
            <Modal.Title>Set Time Schedule Of {action}</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <Row>
              <Col>
                <Form>
                  <Form.Check
                    type="checkbox"
                    label="Whole Day"
                    id="wholeDay"
                    className="mb-3"
                    checked={isWholeDay} 
                    onChange={handleWholeDayChange}
                  />
                  <Form.Group className="mb-3">
                    <Form.Label>Start Time</Form.Label>
                    <Form.Control
                      type="time"
                      value={startTime}
                      onChange={(e) => setStartTime(e.target.value)}
                      disabled={isWholeDay}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>End Time</Form.Label>
                    <Form.Control
                      type="time"
                      value={endTime}
                      onChange={(e) => setEndTime(e.target.value)}
                      disabled={isWholeDay}
                    />
                  </Form.Group>
                  <Form.Check
                    type="checkbox"
                    label="Whole Week"
                    id="wholeWeek"
                    className="mb-3"
                    checked={isWholeWeek}
                    onChange={handleWholeWeekChange}
                  />
                  <Form.Group className="mb-3">
                    <Form.Label>Select Days</Form.Label>
                    <div>
                      {WEEK_DAYS.map((day) => (
                      <Form.Check
                        inline
                        key={day}
                        label={day}
                        type="checkbox"
                        checked={weekDays.includes(day)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setWeekDays((prev) => [...prev, day]);
                          } else {
                            setWeekDays((prev) => prev.filter((d) => d !== day));
                          }
                        }}
                        disabled={isWholeWeek}
                      />
                      ))}
                    </div>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setTimeScheduleModel(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSetSchedule}>Set Schedule</Button>
          </Modal.Footer>
        </Modal> 
      </>
    );
};

export default InboundCalls;
