import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { getApiCall } from "../../../service/httpCall"; // Adjust the path if necessary
import Loader from "../../../components/partials/components/loader";
import ToastNotification from "../../../components/Toast";
import '@fortawesome/fontawesome-free/css/all.min.css';
import "../../../assets/custom/css/custom.css";
import { API_INCOMING_SUMMARY_LIST, DOWNLOAD_REPORT } from '../../../service/url';
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { postApiCall } from '../../../service/httpCall';
import LottieAnimation from '../../../components/lottie/lottie';
import Modal from "react-bootstrap/Modal";


const IncomingSummary = () => {
  const [incomingSummary, setIncomingSummary] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toastProps, setToastProps] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
      name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
  
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedDid, setSelectedDid] = useState(null);
  const [didOptions, setDidOptions] = useState([]); 
  const [showToastModal, setShowToastModal] = useState(false);
  const [toastMessage, setToastMessage] = useState('');



  const showToasts = (message) => {
    setToastMessage(message);
    setShowToastModal(true);

    // Hide modal automatically after 3 seconds
    setTimeout(() => {
        setShowToastModal(false);
    }, 3000);
};
  
    const handleFilter = () => {
      if ((!startDate || !endDate) && !selectedDid) {
        setFilteredData(incomingSummary);
        return;
      }
  
      const startOfDay = new Date(startDate);
      startOfDay.setHours(0, 0, 0, 0);
    
      const endOfDay = new Date(endDate);
      endOfDay.setHours(23, 59, 59, 999);

      let filtered = filteredData

      if (startDate || endDate) {
        filtered = incomingSummary.filter((data) => {
            const incommingData = new Date(data.schedule_date);
            return incommingData >= startOfDay && incommingData <= endOfDay;
        });
      }

      if (selectedDid) {
        filtered = filtered.filter(data => data.did == selectedDid);
      }
  
      setFilteredData(filtered);
    };

   const fetchIncomingSummary = () => {
         getApiCall(API_INCOMING_SUMMARY_LIST)
            .then((resp) => {
               if (resp) {
                    const updatedList = resp.map((val, idx) => {
                       val['key'] = idx + 1;
                       val['schedule_date'] = new Date(val['schedule_date']).toGMTString();
                       return val;
                    });
                setIncomingSummary(updatedList)
                setFilteredData(updatedList)

                const uniqueDids = [...new Set(updatedList.map(item => item.did))];
                setDidOptions(uniqueDids.map(did => ({ label: did, value: did })));
             }
            })
            .catch((error) => {
                console.log(error)
            })
      };
     
     useEffect(
       fetchIncomingSummary,
     []);

     const handleReset = () => {
        setStartDate(null);
        setEndDate(null);
        setSelectedDid(null);
        setFilteredData(incomingSummary);
      };   

      const downloadFile = (rowData) => {
        if (!rowData) {
            console.error("Row data is missing.");
            return;
        }

        const params = {
          did: rowData.did,
          user_id: rowData.user_id?._id,
          status: 0,
          report_type: 'full'
        }

        postApiCall(DOWNLOAD_REPORT, params)
                 .then(onDownloadReportCreateSuccess)
                 .catch(onDownloadReportCreateError);

        function onDownloadReportCreateSuccess(resp) {
              showToasts('Your Report Generation is in-progress')
              // setToastProps({ message: 'Data Center created successfully', type: 'success' });
              // setTimeout(() => {
              //    navigate(`/${ROUTES.DATA_CENTER.LIST}`);
              // }, 1500);
           }
        
           function onDownloadReportCreateError(err) {
                // setButtonDisable(false);
                setToastProps({message: JSON.stringify(err), type: 'danger'});
           }
                 
    
       
        // const headers = ["DID", "User ID", "Schedule Date", "Nos Processed", "Total Nos", "Connected Calls", "SMS Count", "Billing Duration", "DTMF Count", "Retry Count", "Webhook Count"];
        
        // const csvData = [
        //     headers.join(","), 
        //     [
        //         rowData.did,
        //         rowData.user_id?._id, 
        //         rowData.schedule_date,
        //         rowData.nos_processed,
        //         rowData.total_nos,
        //         rowData.connected_calls,
        //         rowData.sms_count,
        //         rowData.billing_duration,
        //         rowData.dtmf_count,
        //         rowData.retry_count,
        //         rowData.webhook_count
        //     ].join(",")
        // ].join("\n");
    
        // const blob = new Blob([csvData], { type: "text/csv" });
        // const url = URL.createObjectURL(blob);
        // const link = document.createElement("a");
        // link.href = url;
        // link.download = `row_data_${rowData.did || "unknown"}.csv`;
        // document.body.appendChild(link);
        // link.click();
    
        // document.body.removeChild(link);
        // URL.revokeObjectURL(url);
    };    

   const actionBodyTemplate = (rowData) => {
     return (
       <div className="d-flex justify-space-between gap-2">
         <button
           className="btn-icons m-1"
           onClick={() => downloadFile(rowData)}
           style={{ marginLeft: '8px', border: 'none', background: 'none', padding: '0' }}
         >
           <i className="fa fa-download" aria-hidden="true" style={{ color: 'blue', fontSize: '24px'  }}></i>
         </button>
     </div>
     );
   };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
    <div>
      <div className="card" style={{ marginTop: "20px" }}>
        <div className="card-header d-flex justify-content-between align-items-center">
          <h4 className="card-title">Incoming Summary</h4>
        </div>
        <div className="card-body">
                <div className="d-flex align-items-center mb-3" style={{ marginLeft: "10px" }}>
                  <Calendar
                    value={startDate}
                    onChange={(e) => setStartDate(e.value)}
                    placeholder="Start Date"
                    showIcon
                  />
                  <span className="mx-2">to</span>
                  <Calendar
                    value={endDate}
                    onChange={(e) => setEndDate(e.value)}
                    placeholder="End Date"
                    showIcon
                  />
                  <Dropdown
                    value={selectedDid}
                    options={didOptions}
                    onChange={(e) => setSelectedDid(e.value)}
                    placeholder="Select DID"
                    className="mx-2"
                />
                  <Button
                    label="Filter"
                    onClick={handleFilter}
                    className="p-button-sm p-button-primary mx-2"
                  />
                  <Button
                    label="Reset"
                    onClick={handleReset}
                    className="p-button-sm p-button-secondary"
                  />
                </div>
          <div className="scroll-container">
            <DataTable
              value={filteredData}
              paginator
              rows={10}
              tableStyle={{ minWidth: "50rem" }}
              filters={filters}
            >
              <Column field="did" header="Did" filterPlaceholder="Search by name" />
              <Column field="user_id._id" header="User Id" filterPlaceholder="Search by URL" />
              <Column field="schedule_date" header="Schedule Date" filterPlaceholder="Search by duration" />
              <Column field="nos_processed" header="Nos Processed" filterPlaceholder="Search by category" />
              <Column field="total_nos" header="Total Nos" filterPlaceholder="Search by name" />
              <Column field="connected_calls" header="Connected Calls" filterPlaceholder="Search by URL" />
              <Column field="sms_count" header="SMS Count" filterPlaceholder="Search by duration" />
              <Column field="billing_duration" header="Billing Duration" filterPlaceholder="Search by category" />
              <Column field="dtmf_count" header="Dtmf Count" filterPlaceholder="Search by category" />
              <Column field="retry_count" header="Retry Count" filterPlaceholder="Search by category" />
              <Column field="webhook_count" header="Webhook Count" filterPlaceholder="Search by category" />
              <Column
                header="Actions"
                body={actionBodyTemplate}
                style={{ textAlign: "center", width: "12rem" }}
              />
            </DataTable>
          </div>
        </div>
      </div>
      <ToastNotification
        show_toast={showToast}
        set_show_toast={setShowToast}
        {...toastProps}
      />
      {/* LOTTIE MODEL FOR TOAST */}
             <Modal show={showToastModal} centered onHide={() => setShowToastModal(false)} size="sm">
                      <Modal.Body className="text-center">
                          <h5>{toastMessage}</h5>
                          <LottieAnimation animationData={"https://assets6.lottiefiles.com/packages/lf20_touohxv0.json"} />
                      </Modal.Body>
                  </Modal>
    </div>
          </>    
  );
};

export default IncomingSummary;
