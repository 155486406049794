import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import {
  DATA_CENTER_TYPES,
  DATA_CENTER_DOMESTIC_TYPE,
  DATA_CENTER_INTERNATIONAL,
  DOMESTIC_COUNTRY,
} from "../../../utilities/constant";
import {
  API_COUNTRY_CODES_LIST,
  API_STATES_LIST,
  API_CITIES_LIST,
} from "../../../service/url";
import { postApiCall, getApiCall } from "../../../service/httpCall";
import {validateEmail} from '../../../utilities/helpers'


function RenderDataTypeFields(props) {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  
  props = props["props"];
  
  useEffect(() => {
    if (props.dataCenterType == DATA_CENTER_INTERNATIONAL) {
      getApiCall(API_COUNTRY_CODES_LIST, {})
        .then((response) => {
          
          const filteredCountries = response.filter(
            (country) => country.name !== DOMESTIC_COUNTRY.value
          );
          setCountries(filteredCountries);
        })
        .catch((error) =>
          console.error("Error while fetching countries:", error)
        );
    }
  }, [props.dataCenterType]);

  useEffect(() => {
    if (props.country) {
      const params = {
        countryCode: props.country,
      };
      postApiCall(API_STATES_LIST, params)
        .then((response) => {
          setStates(response);
        })
        .catch((error) => console.error("Error while fetching states:", error));
    }
  }, [props.country]);

  useEffect(() => {
    if (props.state && props.country) {
      const params = {
        countryCode: props.country,
        stateCode: props.state,
      };
      postApiCall(API_CITIES_LIST, params)
        .then((response) => {
          setCities(response);
        })
        .catch((error) => console.error("Error while fetching cities:", error));
    }
  }, [props.state, props.country]);

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    const selectedCountryName =
      e.target.options[e.target.selectedIndex].textContent;

    props.setCountry(selectedCountry);

    props.setCountryData((prevData) => ({
      ...prevData,
      [selectedCountry]: selectedCountryName,
    }));
  };

  const handleStatechange = (e) => {
    const selectedState = e.target.value;
    const selectedStateName =
      e.target.options[e.target.selectedIndex].textContent;

    props.setState(selectedState);

    props.setStateData((prevData) => ({
      ...prevData,
      [selectedState]: selectedStateName,
    }));
  };

  if (
    props.dataCenterType !== "" &&
    props.dataCenterType == DATA_CENTER_DOMESTIC_TYPE
  ) {
    props.setCountry(DOMESTIC_COUNTRY.label);
    return (
      <>
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && <Form.Label htmlFor="status">State:</Form.Label>}
          <Form.Select
            id="state"
            value={props.state}
            className="p-2"
            onChange={handleStatechange}
          >
            <option value={""}>State</option>
            {states.map(function (val, idx) {
              return (
                <option key={idx} value={val.isoCode}>
                  {val.name}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && <Form.Label htmlFor="status">City:</Form.Label>}
          <Form.Select
            id="city"
            value={props.city}
            className="p-2"
            onChange={(e) => props.setCity(e.target.value)}
          >
            <option value={""}>City</option>
            {cities.map(function (val, idx) {
              return (
                <option key={idx} value={val.name}>
                  {val.name}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      </>
    );
  } else if (props.dataCenterType == DATA_CENTER_INTERNATIONAL) {
    return (
      <>
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && (
            <Form.Label htmlFor="status">Country:</Form.Label>
          )}
          <Form.Select
            id="dataCenter_type"
            value={props.country}
            className="p-2"
            onChange={handleCountryChange}
          >
            <option value={""}>Country</option>
            {countries.map(function (val, idx) {
              return (
                <option key={idx} value={val.code}>
                  {val.name}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && <Form.Label htmlFor="status">State:</Form.Label>}
          <Form.Select
            id="state"
            value={props.state}
            className="p-2"
            onChange={handleStatechange}
          >
            <option value={""}>State</option>
            {states.map(function (val, idx) {
              return (
                <option key={idx} value={val.isoCode}>
                  {val.name}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && <Form.Label htmlFor="status">City:</Form.Label>}
          <Form.Select
            id="city"
            value={props.city}
            className="p-2"
            onChange={(e) => props.setCity(e.target.value)}
          >
            <option value={""}>City</option>
            {cities.map(function (val, idx) {
              return (
                <option key={idx} value={val.name}>
                  {val.name}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      </>
    );
  }
  return <></>;
}


export default function RenderDataCenterForm(props) {

  return (
    <>
      <div className="row">
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && <Form.Label htmlFor="status">Name:</Form.Label>}
          <Form.Control
            type="text"
            id="name"
            maxLength={100}
            value={props.name}
            placeholder="Name"
            onChange={(e) => props.setName(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && (
            <Form.Label htmlFor="status">Data Center Type:</Form.Label>
          )}
          <Form.Select
            id="dataCenter_type"
            value={props.dataCenterType}
            className="p-2"
            onChange={(e) => props.setDataCenterType(e.target.value)}
          >
            <option value={""}>Select Data Center Type</option>
            {DATA_CENTER_TYPES.map(function (val, idx) {
              return (
                <option key={idx} value={val.value}>
                  {val.label}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      </div>
      <div className="row">
        <RenderDataTypeFields props={props} />
      </div>
      <div className="row">
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && (
            <Form.Label htmlFor="status">Contact Person:</Form.Label>
          )}
          <Form.Control
            type="text"
            id="contect_person"
            value={props.contactPerson}
            placeholder="Contact Person"
            onChange={(e) => props.setContactPerson(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && (
            <Form.Label htmlFor="status">Contact Email:</Form.Label>
          )}
          <Form.Control
            type="text"
            id="contact_email"
            value={props.contactEmail}
            placeholder="Contact Email"
            onChange={(e) => {
              props.setContactEmail(e.target.value)
              props.setEmailError(validateEmail(e.target.value))
            }}
          />
          {props.emailError && <span className="text-danger">{props.emailError}</span>}
        </Form.Group>
      </div>
      <div className="row">
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && (
            <Form.Label htmlFor="status">Contact Number:</Form.Label>
          )}
          <Form.Control
            type="tel"
            id="contact_number"
            maxLength="15"
            value={props.contactNumber}
            placeholder="Contact Number"
            onChange={(e) => props.setContactNumber(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && (
            <Form.Label htmlFor="status">Data Center Company:</Form.Label>
          )}
          <Form.Control
            type="text"
            id="dataCenter_company"
            value={props.dataCenterCompany}
            placeholder="Data Center Company"
            onChange={(e) => props.setDataCenterCompany(e.target.value)}
          />
        </Form.Group>
      </div>
      <div className="row">
        <div className="col-md-6 form-group">
          {props.isEditMode && (
            <Form.Label htmlFor="status">Data Center Company Address:</Form.Label>
          )}
          <textarea
            rows="3"
            maxLength="500"
            id="dataCenter_address"
            value={props.dataCenterAddress}
            placeholder="Data Center Company Address"
            className="form-control"
            onChange={(e) => props.setDataCenterAddress(e.target.value)}
          />
        </div>
      </div>
    </>
  );
}
