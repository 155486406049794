import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { getApiCall } from "../../../service/httpCall"; // Adjust the path if necessary
import Loader from "../../../components/partials/components/loader";
import ToastNotification from "../../../components/Toast";
import '@fortawesome/fontawesome-free/css/all.min.css';
import "../../../assets/custom/css/custom.css";
import { API_INCOMING_SUMMARY_LIST, DOWNLOAD_REPORT } from '../../../service/url';
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { postApiCall } from '../../../service/httpCall';
import LottieAnimation from '../../../components/lottie/lottie';
import Modal from "react-bootstrap/Modal";


const DownloadReport = () => {
  const [incomingSummary, setDownloadReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toastProps, setToastProps] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
      name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
  
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedDid, setSelectedDid] = useState(null);
//   const [didOptions, setDidOptions] = useState([]); 
  const [showToastModal, setShowToastModal] = useState(false);
  const [toastMessage, setToastMessage] = useState('');



  const showToasts = (message) => {
    setToastMessage(message);
    setShowToastModal(true);

    // Hide modal automatically after 3 seconds
    setTimeout(() => {
        setShowToastModal(false);
    }, 3000);
};
  
    const handleFilter = () => {
      if ((!startDate || !endDate) && !selectedDid) {
        setFilteredData(incomingSummary);
        return;
      }
  
      const startOfDay = new Date(startDate);
      startOfDay.setHours(0, 0, 0, 0);
    
      const endOfDay = new Date(endDate);
      endOfDay.setHours(23, 59, 59, 999);

      let filtered = filteredData

      if (startDate || endDate) {
        filtered = incomingSummary.filter((data) => {
            const incommingData = new Date(data.requested_date);
            return incommingData >= startOfDay && incommingData <= endOfDay;
        });
      }
  
      setFilteredData(filtered);
    };

   const fetchDownloadReport = () => {
         getApiCall(DOWNLOAD_REPORT)
            .then((resp) => {
               if (resp) {
                    const updatedList = resp.map((val, idx) => {
                       val['key'] = idx + 1;
                       val['requested_date'] = new Date(val['requested_date']).toGMTString();
                       return val;
                    });
                setDownloadReport(updatedList)
                setFilteredData(updatedList)
             }
            })
            .catch((error) => {
                console.log(error)
            })
      };
     
     useEffect(
       fetchDownloadReport,
     []);

     const handleReset = () => {
        setStartDate(null);
        setEndDate(null);
        setFilteredData(incomingSummary);
      };   

    const downloadFile = (rowData) => {
        if (!rowData || !rowData.download_link) {
            console.error("Download link is missing.");
            return;
        }
    
        const link = document.createElement("a");
        link.href = rowData.download_link;
        link.download = rowData.download_link.split('/').pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="d-flex justify-space-between gap-2">
                <button
                    className="btn-icons m-1"
                    onClick={() => downloadFile(rowData)}
                    disabled={!rowData.download_link}
                    style={{
                        marginLeft: '8px',
                        border: 'none',
                        background: 'none',
                        padding: '0',
                        cursor: rowData.download_link ? 'pointer' : 'not-allowed',
                        opacity: rowData.download_link ? 1 : 0.5,
                    }}
                >
                    <i className="fa fa-download" aria-hidden="true" style={{ color: 'blue', fontSize: '24px' }}></i>
                </button>
            </div>
        );
    };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
    <div>
      <div className="card" style={{ marginTop: "20px" }}>
        <div className="card-header d-flex justify-content-between align-items-center">
          <h4 className="card-title">Download Reports</h4>
        </div>
        <div className="card-body">
                <div className="d-flex align-items-center mb-3" style={{ marginLeft: "10px" }}>
                  <Calendar
                    value={startDate}
                    onChange={(e) => setStartDate(e.value)}
                    placeholder="Start Date"
                    showIcon
                  />
                  <span className="mx-2">to</span>
                  <Calendar
                    value={endDate}
                    onChange={(e) => setEndDate(e.value)}
                    placeholder="End Date"
                    showIcon
                  />
                  <Button
                    label="Filter"
                    onClick={handleFilter}
                    className="p-button-sm p-button-primary mx-2"
                  />
                  <Button
                    label="Reset"
                    onClick={handleReset}
                    className="p-button-sm p-button-secondary"
                  />
                </div>
          <div className="scroll-container">
            <DataTable
              value={filteredData}
              paginator
              rows={10}
              tableStyle={{ minWidth: "50rem" }}
              filters={filters}
            >
              <Column field="did" header="Did" filterPlaceholder="Search by name" />
              <Column field="user_id" header="User Id" filterPlaceholder="Search by URL" />
              <Column field="requested_date" header="Schedule Date" filterPlaceholder="Search by duration" />
              <Column field="status" header="Status" filterPlaceholder="Search by category" />
              <Column field="report_type" header="Report type" filterPlaceholder="Search by name" />
              <Column field="download_link" header="Download link" filterPlaceholder="Search by URL" />
              <Column
                header="Actions"
                body={actionBodyTemplate}
                style={{ textAlign: "center", width: "12rem" }}
              />
            </DataTable>
          </div>
        </div>
      </div>
      <ToastNotification
        show_toast={showToast}
        set_show_toast={setShowToast}
        {...toastProps}
      />
      {/* LOTTIE MODEL FOR TOAST */}
             <Modal show={showToastModal} centered onHide={() => setShowToastModal(false)} size="sm">
                      <Modal.Body className="text-center">
                          <h5>{toastMessage}</h5>
                          <LottieAnimation animationData={"https://assets6.lottiefiles.com/packages/lf20_touohxv0.json"} />
                      </Modal.Body>
                  </Modal>
    </div>
          </>    
  );
};

export default DownloadReport;
