export function validatePhone(input_phone) {
   // return Boolean( String(input_phone).match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/) );
   const pilotNumberRegex = /^[a-zA-Z0-9]{1,10}$/; // Allow alphanumeric characters up to 10 characters
   return pilotNumberRegex.test(input_phone);
}

export const validateEmail = (email) => {
   const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
   if (!email) {
     return "Email is required";
   } else if (!emailPattern.test(email)) {
     return "Invalid email format";
   }
   return "";
 };
 