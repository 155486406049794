import React, { useState, useEffect, useRef } from 'react'
import {Row,Col,Image} from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Card from '../../../components/Card'
import { API_CALL_CENTRE_LIST } from '../../../service/url';
import { getApiCall } from '../../../service/httpCall';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import switchUserIcon from '../../../assets/images/icons/switch-user.png';
import { switchAs } from '../../../utilities/setting';
import ToastNotification from '../../../components/Toast';
import { USER_ROLES } from '../../../utilities/constant';
import { ROUTES } from '../../../utilities/constant';
import { useAuth, errorCheck } from '../auth/auth-utils';
import exportData from '../../../utilities/export';
import { Button } from 'primereact/button';
import "../../../assets/custom/css/custom.css";


//theme
import 'primereact/resources/themes/lara-light-indigo/theme.css';
//core
import 'primereact/resources/primereact.min.css';
import Loader  from '../../../components/partials/components/loader';

const CallCentreList = () => {

   const navigate = useNavigate();
   const { checkAuth } = useAuth();
   const [callCentreList, setCallCentreList] = useState([]);
   const [showToast, setShowToast] = useState(false);
   const [toastProps, setToastProps] = useState(null);
   const [filters, setFilters] = useState({
      name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    //   status: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
   });
   const [loading, setLoading] = useState(false);
   const [buttonDisable, setButtonDisable] = useState(false);

   const handleExport = async () => {
      setButtonDisable(true); 
      await exportData('CallCentre', setToastProps, setShowToast);
      setButtonDisable(false); 
   };

   useEffect( () => {
      setLoading(true);
      getApiCall(API_CALL_CENTRE_LIST, {})
      .then(onCallCentreListSuccess)
      .catch(onError)
      .finally(() => setLoading(false));

   }, []);

   useEffect( () => {

      if(toastProps){
         setShowToast(true);
      }

   }, [toastProps]);


   function onCallCentreListSuccess(resp){
      const updatedList = resp.map( (val, idx) => {
         val['key'] = idx+1;
         val['actions'] = RenderActionComponent(val._id, val.name);
         val['created_at'] = new Date(val['created_at']).toGMTString();
         return val;
      } );
      setCallCentreList(updatedList);
   }


   function switchAsCallCentre(id, name){

      const switchAccountSuccess = switchAs( USER_ROLES.CALL_CENTRE, id, name );
      if(switchAccountSuccess){
         setToastProps({type: 'success', message: `Account switched successfully`});
         setTimeout( function(){
            const callCentreUsersListPath = ROUTES.CALL_CENTRES.USERS.LIST.replace(':id', id);
            //navigate(`/${callCentreUsersListPath}`);
            window.location.href = callCentreUsersListPath;
         }, 1500);
      }

   }


   function RenderActionComponent(id, name){

      const editUrl = ROUTES.CALL_CENTRES.EDIT.replace(':id', id);

      return (
         <div className="flex align-items-center list-user-action">
            <Link className="btn btn-sm btn-icon btn-warning" data-toggle="tooltip" data-placement="top" title="Edit" data-original-title="Edit" to={`/${editUrl}`}>
               <span className="btn-inner">
                  <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                     <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                     <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
               </span>
            </Link>{' '}
            <span className='pointer' title='Switch account' onClick={e => switchAsCallCentre(id, name)}>
               <img alt='Switch account' src={switchUserIcon} width={32} />
            </span>
         </div>
      );

   }

//    const dropdownItemTemplate = (option) => {
//       return <span>{option}</span>;
//    }

//    const statusFilterTemplate = (options) => {
//       return <Dropdown value={options.value} options={statuses.current} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={dropdownItemTemplate} placeholder="Select One" className="p-column-filter" showClear />;
//    }


function onError(err) {
   if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
    errorCheck(err, navigate, setToastProps, setShowToast)
 } else {
   console.log(err)
 }
}

const handleCreateButtonClick = () => {
   navigate(`/${ROUTES.CALL_CENTRES.CREATE}`);
} 
   
 if (loading) {
   return <Loader />
}

  return(
     <>
       <div>
         <Row>
            <Col sm="12">
               <Card>
                  <Card.Header className="d-flex justify-content-between">
                     <div className="header-title">
                        <h4 className="card-title">Call Centre
                         List</h4>
                     </div>
                     <button className="btn btn-primary ml-auto" onClick={handleCreateButtonClick}>Create Call Centre</button>
                  </Card.Header>
                  <Card.Body className="px-0">
                     <Button 
                        label="Export Data" 
                        onClick={handleExport} 
                        className="p-button-active p-button-sm" 
                        style={{ marginBottom: '10px', marginLeft: '10px' }} 
                        disabled={buttonDisable} 
                     >
                        &nbsp;
                        {buttonDisable && (
                           <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                        )}
                     </Button>
                     <div className="scroll-container">
                        <div className="table-responsive border-bottom my-3">
                           <DataTable value={callCentreList} tableStyle={{ minWidth: '50rem' }} filters={filters} paginator rows={8}>
                              <Column field="key" header="#"></Column>
                              <Column field="name" filterField="name" header="name" filter filterPlaceholder="Search by name" ></Column>
                              <Column field="domain" filterField="domain" header="domain" filter filterPlaceholder="Search by domain" ></Column>
                              <Column field="created_at" header="Created Date"></Column>
                              <Column field="actions" header="Actions"></Column>
                           </DataTable>
                        </div>
                     </div>
                  </Card.Body>
                  
               </Card>
            </Col>
         </Row>
      </div>

      <ToastNotification show_toast={showToast} set_show_toast={setShowToast} {...toastProps} />

     </>
  )

}

export default CallCentreList;