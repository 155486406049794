import React, { useState, useEffect } from 'react'
import { Row, Col ,Modal,Form} from 'react-bootstrap'
import Card from '../../../components/Card'
import { getApiCall, postApiCall, postApiMediaCall } from '../../../service/httpCall';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import RenderDeleteModal from '../../../components/partials/components/delete-modal';
import ToastNotification from '../../../components/Toast';
import { ROUTES ,NUMBERS_TYPES,NUBMERS_STATUS_LABEL, BULK_UPDATE_DID, BULK_UPDATE_OTHERS, ASSIGN_DID_VMN, ASSIGN_DID_TOLLFREE} from '../../../utilities/constant';
import { EditIcon, DeleteIcon ,RefreshIcon} from '../../../components/setting/elements';
import { API_NUMBERS_LIST, API_NUMBER_DELETE, API_COUNTRY_CODES_LIST, API_DID_NUMBERS,API_NUMBER_STATUS,API_BULK_ASSIGN_DID,API_INDIVIDUAL_ASSIGN_DID,API_NUMBER_EDIT_BULK } from '../../../service/url';
import Loader  from '../../../components/partials/components/loader';
import { useAuth, errorCheck } from '../auth/auth-utils';
import { Routes, useNavigate } from "react-router-dom";
import { USER_ROLES } from '../../../utilities/constant';
import { getUser } from "../../../utilities/setting";
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import "../../../assets/custom/css/custom.css";
import "../../../assets/custom/css/tabs.css"
import exportData from '../../../utilities/export';
import downloadTemplate from '../../../utilities/downloadCsv'
//theme
import 'primereact/resources/themes/lara-light-indigo/theme.css';
//core
import 'primereact/resources/primereact.min.css';
// images for 
import agentImg from '../../../assets/images/icons/agent.png';
import callgroupImg from '../../../assets/images/icons/callgroup.png';
import ivrImg from '../../../assets/images/icons/ivr.png';
import { TabView, TabPanel } from 'primereact/tabview';


const numberTypeLabels = NUMBERS_TYPES.map(val => val['label']);
const categoryLabels = ['Free', 'Paid'];
const currencyLabels =['INR','USD'];


export default function NumberList() {
    const { checkAuth } = useAuth();
    const navigate = useNavigate();
    const [numbersList, setNumbersList] = useState([]);
    const [contentId, setContentId] = useState(undefined);
    const [filters, setFilters] = useState({
        actual_number: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        status: { value: null, matchMode: FilterMatchMode.CONTAINS },
        country_code: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        state_code: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        number_type: { value: null, matchMode: FilterMatchMode.EQUALS },
        category: { value: null, matchMode: FilterMatchMode.EQUALS },
        currency: { value: null, matchMode: FilterMatchMode.EQUALS },
        cost: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        operator: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    });
    const [numberType,setNumberType] = useState("All");
    const [enableDeleteModal, setEnableDeleteModal] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deleteNumberId, setDeleteNumberId] = useState(undefined);
    const [showToast, setShowToast] = useState(false);
    const [toastProps, setToastProps] = useState(null);
    const [countryCodeMap, setCountryCodeMap] = useState({});
    const [loading, setLoading] = useState(false);
    const [isSuperAdmin, checkIsSuperAdmin] = useState(false);
    const [moduleOperations, setModuleOperations] = useState({});
    const [selectedNumbers, setSelectedNumber] = useState([]);
    const [isMultiDelete, setIsMultiDelete] = useState(false);
    const [showAssignDidButton, setShowAssignDidButton] = useState(false);
    const [showAllocateModal, setShowAllocateModal] = useState(false);
    const [selectedDID, setSelectedDID] = useState(null);
    const [allocateNumberId, setAllocateNumberId] = useState(null);
    const [didOptions, setDidOptions] = useState([]);
    const [allocateStatus, setAllocateStatus] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [expiryDate, setExpiryDate] = useState(null);
    const [expiryDateError, setExpiryDateError] = useState("");
    const [showBulkAssignModal, setShowBulkAssignModal] = useState(false);
    const [csvFile, setCsvFile] = useState(undefined);
    const [selectedNumberType, setSelectedNumberType] = useState([]);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [bulkUpdateButton,setBulkUpdateButton] =useState(false);
    const [bulkUpdateModal,setBulkUpdateModal] =useState(false)
    const [updateNumberType, setUpdateNumberType] = useState('');
    const [userRole, setUserRole] = useState('');
    const [isCompanyUser, checkIsCompanyUser] = useState(false);
    const [isReseller, checkIsReseller] = useState(false);
    const [isCallcenterAdmin, checkIsCallcenterAdmin] = useState(false);
    const [searchValue, setSearchValue] = useState(null);
    const [originalNumbersList, setOriginalNumbersList] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [filteredNumbers, setFilteredNumbers] = useState([]);
    const [selectedNumbers1, setSelectedNumbers1] = useState([]);


    
    const handleExport = async () => {
        setButtonDisable(true); 
        await exportData('Numbers', setToastProps, setShowToast);
        setButtonDisable(false); 
    };
  
    const handleCheckboxToggle = (numberId) => {
      const isSelected = selectedNumbers.includes(numberId);
      setSelectedNumber(isSelected
          ? selectedNumbers.filter(id => id !== numberId)
          : [...selectedNumbers, numberId]);
    };
  
    const handleMultiDelete = () => {
          setIsMultiDelete(true);
          setEnableDeleteModal(true);
    };

    const handleAllocateClick = (id) => {
        checkAuth(setToastProps, setShowToast)
        let index = numbersList.map(i => i._id).indexOf(id)
        setAllocateNumberId(id);
        setSelectedDID(null);
        setShowAllocateModal(true);
        setSelectedNumberType(numbersList[index].number_type);
    };

    useEffect(() => {

        if (toastProps) {
            setShowToast(true);
        }

    }, [toastProps]);

    useEffect(() => {
        setLoading(true);
        getApiCall(API_NUMBERS_LIST)
            .then(onNumberListSuccess)
            .catch(onError)
            .finally(() => setLoading(false));

        getApiCall(API_COUNTRY_CODES_LIST)
            .then(resp => {
                let ccList = {};
                resp.map((val, idx) => {
                    const id = val['_id'];
                    const code = val['calling_code'];
                    ccList[id] = code;
                })
                setCountryCodeMap(ccList);
            })
            .catch(onError);

            getApiCall(API_DID_NUMBERS)
            .then(resp => {
                const didOptions = resp.map(did => ({
                    label: did.actual_number.toString(),
                    value: did._id.toString()
                }));
                setDidOptions(didOptions);
            })
            .catch(onError);
        
  
        getApiCall(API_NUMBER_STATUS)
            .then(resp => {
               const sList= resp.map(item =>({
                status: item.status,
                status_code: item.status_code,
                }))
                
                setAllocateStatus(sList)
            })
            .catch(onError)

    }, []);

    useEffect(() => {
        getUser().then((user) => {
          if (user) {
            setUserRole(user.role);
            if (user.role === USER_ROLES.SUPERADMIN) {
              checkIsSuperAdmin(true);
            }
            if (user.role === USER_ROLES.RESELLER) {
                checkIsReseller(true);
              }
            if (user.role === USER_ROLES.COMPANY_ADMIN) {
                checkIsCompanyUser(true);
            }
            if (user.role === USER_ROLES.CALL_CENTRE_ADMIN) {
                checkIsCallcenterAdmin(true);
            }
            if (user?.acl_settings) {
                setModuleOperations(JSON.parse(user.acl_settings.module_operations));
              }
          }
        });
      }, []);


        useEffect(() => {
          getUser().then((resp) => {
            if (resp) {
              setContentId(resp._id);
            }
          });
        }, []);

    function onNumberListSuccess(resp) {
        const updatedList = resp.map((val, idx) => {
            val['key'] = idx + 1;
            val['created_at'] = new Date(val['created_at']).toGMTString();
            return val;
        });
        setNumbersList(updatedList);
        setOriginalNumbersList(updatedList);
    }

      useEffect(() => {
        if(searchValue){
          setNumbersList(
             numbersList.filter((number) => number.actual_number.toString().includes(searchValue.trim()))
          );
        }
        else {
            setNumbersList(originalNumbersList); // Reset to the original list if no number is selected
        }
      }, [searchValue, numbersList]);

    function handleDeleteNumber(id) {
        checkAuth(setToastProps, setShowToast)
        setIsMultiDelete(false);
        setEnableDeleteModal(true);
        setDeleteNumberId(id);
    }

    const handleSelectAll = () => {
        if (selectedNumbers.length === numbersList.length) {
            setSelectedNumber([]);
        } else {
            const allNumberIds = numbersList.map(number => number._id);
            setSelectedNumber(allNumberIds);
        }
      };

      useEffect( () => {
        if (confirmDelete) {
            if (isMultiDelete) {
                postApiCall(API_NUMBER_DELETE, { numberIds: selectedNumbers })
                    .then(onDeleteSuccess)
                    .catch(onDeleteFail);
            } else {
                postApiCall(API_NUMBER_DELETE, { numberIds: [deleteNumberId] })
                    .then(onDeleteSuccess)
                    .catch(onDeleteFail);
            }
        }
        }, [confirmDelete]);
    

    function onDeleteSuccess(resp) {
        setToastProps({ type: 'success', message: 'Number deleted successfully' });
        let updatedList;
        if (isMultiDelete) {
            updatedList = numbersList.filter(val => !selectedNumbers.includes(val._id)).map((val, key) => {
                val['key'] = key + 1;
                return val;
            });
            setSelectedNumber([]);
        } else {
            updatedList = numbersList.filter(val => val._id !== deleteNumberId).map((val, key) => {
                val['key'] = key + 1;
                return val;
            });
        }
        setNumbersList(updatedList);
        setEnableDeleteModal(false);
        setDeleteNumberId(undefined);
        setConfirmDelete(false); 
    }

    function onDeleteFail(err) {
        if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
            errorCheck(err, navigate, setToastProps, setShowToast)
         } else {
        setToastProps({ type: 'danger', message: `Unable to delete Number: ${err}` });
         }
         setConfirmDelete(false);
    }

    const RenderActionComponent = ({id,number_type,routingType}) => {
        const numberEditRoute = ROUTES.NUMBERS.EDIT.replace(':id', id);
        const editDestinationRoute = ROUTES.IVR.UPDATE_INBOUND_CALLS.replace(':cid', id);
        return (
            <div className="flex align-items-center list-user-action">
                {(isSuperAdmin || moduleOperations?.Numbers?.edit) && (
                    <EditIcon linkTo={`/${numberEditRoute}`} />
                )}
                {' '}
                {!(selectedNumbers.length > 0) && (isSuperAdmin || moduleOperations?.Numbers?.delete) && (
                  <DeleteIcon handleCallback={() => handleDeleteNumber(id)} />
                )}
                {' '}
                {(number_type === 'VMN' || number_type === 'TOLL FREE') && (
                <Button className="btn btn-sm btn-icon btn-primary" title='Allocate' onClick={() => handleAllocateClick(id)}>
                    Allocate
                </Button>
                )}
                {((routingType === 'IVR' || routingType ==='AGENT' || routingType ==='CALLGROUP') && isCallcenterAdmin ) && (
                <Button className="btn btn-sm btn-icon btn-primary" title='Edit Destination' onClick={() =>  navigate(`/${editDestinationRoute}`)}>
                    Edit Destination
                </Button>
                )}


                
                
            </div>
        );

    }


    const handleBulkAssignClick = (type) => {
        setShowBulkAssignModal(true);

    };
    
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setCsvFile(file);
    };
    

    async function handleBulkAssign() {

        const formData = new FormData();
         formData.append('pool', 'pool');
         formData.append('phone_numbers', csvFile);
         formData.append("type", numberType); 
  
         postApiMediaCall(API_BULK_ASSIGN_DID, formData)
             .then(resp => {
                setToastProps({ type: 'success', message: 'Bulk DID assigned successfully' });
                            setShowBulkAssignModal(false);
                            getApiCall(API_NUMBERS_LIST)
                           .then(onNumberListSuccess)
                           .catch(onError);
                            
                 
             })
             .catch(err => {
                setToastProps({ type: 'danger', message: `Failed to assign bulk DID: ${err}` });
                            console.log("error",err);
             })
 
            }
    

    const handleAllocate = () => {
        const payload = {
            DID: selectedDID.label,
            DID_id: selectedDID.value,
            expiryDate: expiryDate,
            numberType:selectedNumberType,
            status: selectedStatus.toString(),
             _id: allocateNumberId,
           
           };

        postApiCall(API_INDIVIDUAL_ASSIGN_DID,payload)
            .then(() => {
                setToastProps({ type: 'success', message: 'Number allocated successfully' });
                setShowAllocateModal(false);
                setSelectedDID(null);
                setExpiryDate();
                getApiCall(API_NUMBERS_LIST)
                .then(onNumberListSuccess)
                .catch(onError);
              
            })
            .catch(err => {
                setToastProps({ type: 'danger', message: `Allocation failed: ${err}` });
            });
    };

    const handleBulkUpdate =() =>{
        const formData = new FormData();
         formData.append('phone_numbers', csvFile);
         formData.append("numberType", numberType);

         postApiMediaCall(API_NUMBER_EDIT_BULK, formData)
         .then(resp => {
            setToastProps({ type: 'success', message: 'Bulk Update Successfull.' });
                        setShowBulkAssignModal(false);
                        getApiCall(API_NUMBERS_LIST)
                        .then(onNumberListSuccess)
                        .catch(onError);
             
         })
         .catch(err => {
            setToastProps({ type: 'danger', message: `Failed to assign bulk update: ${err}` });
                        console.log("error",err);
         })
    }
            
    const dropdownItemTemplate = (option) => {
        return <span>{option}</span>;
    }

    const numberTypeFilterTemplate = options => {
        return <Dropdown value={options.value} options={numberTypeLabels} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={dropdownItemTemplate} placeholder="Select One" className="p-column-filter" showClear />;
     }

     const categoryFilterTemplate = options => {
        return <Dropdown value={options.value} options={categoryLabels} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={dropdownItemTemplate} placeholder="Select Category" className="p-column-filter" showClear />;
     }
     const currencyFilterTemplate = options => {
        return <Dropdown value={options.value} options={currencyLabels} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={dropdownItemTemplate} placeholder="Select Currency" className="p-column-filter" showClear />;
     }
    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={NUBMERS_STATUS_LABEL} onChange={(e) => options.filterCallback(e.value)} itemTemplate={dropdownItemTemplate} placeholder="Select One" className="p-column-filter" showClear />;
    }

    const countryCodeFilterTemplate = (options) => {
        const country_code_labels = Object.values(countryCodeMap);
        const country_code_labels_keys = Object.keys(countryCodeMap);
        let op = options.value;
        options.value = op
        return <Dropdown value={options.value} options={country_code_labels} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={dropdownItemTemplate} placeholder="Select One" className="p-column-filter" showClear />;
    }

    function onError(err) {
        if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
            errorCheck(err, navigate, setToastProps, setShowToast)
         } else {
           console.log(err)
         }
     }

    function renderBodyTemplate(data, props) {
        return (<div className="flex align-items-center gap-2">
            {countryCodeMap[data[props.field]]}
            {(rowData) => rowData.country_code || '-'}
        </div>);
    }
    
    const handleCreateButtonClick = () => {
        navigate(`/${ROUTES.NUMBERS.CREATE}`)
     } 

     const handleNumberTypeFilter = (type) => {
        setNumberType(type);
        setShowAssignDidButton(type === 'VMN' || type === 'TOLL FREE');
    
        setFilters((prevFilters) => {
            if (type === 'All') {
                const { number_type, ...remainingFilters } = prevFilters;
                return remainingFilters;
            }
            return {
                ...prevFilters,
                number_type: { value: type, matchMode: FilterMatchMode.EQUALS },
            };
        });
    
        if (type === '' || type === 'All') {
            setBulkUpdateButton(false);
        } else {
            setBulkUpdateButton(true);
        }
    };
    

    const resetAllocateModalState = () => {
        setSelectedDID(null);
        setSelectedStatus(null);
        setExpiryDate(null);
        setExpiryDateError("");
        setUpdateNumberType(null);
        
    };



    const handleExpiryDateChange = (e) => {
        const date = e.target.value;
        const today = new Date();
        const selectedDate = new Date(date);

        if (selectedDate <= today) {
            setExpiryDateError("Expiry date must be in the future");
        } else {
            setExpiryDateError("");
        }

        setExpiryDate(date);
    };

  
     const rowClassName = (data) => {
        return {
            'highlighted-row': selectedNumbers.includes(data._id)
          };
    }

    const dropdownOptionStatus = allocateStatus.map(item =>({label:item.status,value:item.status_code}))

    
    const handleBulkUpdateButtonClick = (type) => {
        setBulkUpdateModal(true);
    };


    const getColumnsAndFilename = (numberType) => {
        let columns;
        let filename;
    
        if (numberType === 'DID') {
            columns = BULK_UPDATE_DID;
            filename = `${numberType}-Update.csv`;
        } else if (numberType === 'VMN') {
            columns = BULK_UPDATE_OTHERS;
            filename = `${numberType}-Update.csv`;
        } else {
            columns = BULK_UPDATE_OTHERS;
            filename = `${numberType}-Update.csv`;
        }
    
        return { columns, filename };
    };

    const getColumnsAndFilenameForAssignDID = (numberType) => {
        let columns;
        let filename;
    
        if (numberType === 'VMN') {
            columns = ASSIGN_DID_VMN;
            filename = `${numberType}-AssignDID.csv`;
        } else {
            columns = ASSIGN_DID_TOLLFREE;
            filename = `${numberType}-AssignDID.csv`;
        }
    
        return { columns, filename };
    };
    

    if (loading) {
        return <Loader />
    }

    // useEffect(() => {
    //     filterNumbers();
    // }, []);

    const numberTypes = ['DID', 'VMN', 'TOLL FREE'];

    const filterNumbers = () => {
        const type = numberTypes[activeIndex];
        const filtered = numbersList.filter(num => num.number_type === type);
        setFilteredNumbers(filtered);
    };

    const handleCheckboxToggle1 = (id) => {
        setSelectedNumbers1(prev => 
            prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]
        );
    };
    return (
        <>
            <div>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <div className="header-title">
                                    <h4 className="card-title">Numbers List</h4>
                                </div>
                                <div>
                                    <button className="btn btn-primary me-2" onClick={() => {
                                        handleNumberTypeFilter('All')
                                        setActiveIndex(4)
                                        }}>Refresh</button>
                                    {(isSuperAdmin || (moduleOperations?.Numbers?.add && userRole !== USER_ROLES.CALL_CENTRE_ADMIN)) && (
                                        <button className="btn btn-primary" onClick={handleCreateButtonClick}>
                                            Create Number
                                        </button>
                                    )}{userRole === USER_ROLES.CALL_CENTRE_ADMIN && (
                                                <button 
                                                    className="btn btn-primary m-2" 
                                                    onClick={() =>  navigate(`/${ROUTES.IVR.INBOUND_CALLS.replace(":cid", contentId)}`)}>
                                                    Inbound Calling
                                                </button>
                                            )}
                                </div>
                            </Card.Header>
                            <Card.Body className="px-0">
                                <div className="custom-tab-container">
                                    <Row className="d-flex align-items-center">
                                    
                                        <Col className="d-flex justify-content-end">
                                            <Button
                                                label="Delete Selected"
                                                onClick={handleMultiDelete}
                                                className="p-button-danger"
                                                style={{ fontSize: "14px", padding: "0px 8px", marginLeft: "10px" }}
                                                disabled={selectedNumbers.length === 0}
                                            />
                                            <Button 
                                                label="Export Data" 
                                                onClick={handleExport} 
                                                className="p-button-active" 
                                                style={{ fontSize: "14px", padding: "0px 8px", marginLeft: "10px" }}
                                                disabled={buttonDisable} 
                                                >
                                                &nbsp;
                                                {buttonDisable && (
                                                    <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                                                )}
                                            </Button>

                                            <Form.Group style={{height: "45px"}}>
                                                <Form.Control
                                                    style={{marginLeft: "10px",marginRight: "10px", margintop: "5px" ,border: "1px solid #ced4da", fontSize: "14px", height: "44px", width: "200px"}}
                                                    placeholder = 'Search by Number...'
                                                    type="number"
                                                    maxLength={100}
                                                    value={searchValue}
                                                    onChange={(e) => setSearchValue(e.target.value)}
                                                    required
                                                />
                                            </Form.Group>

                                            {(showAssignDidButton) && (
                                                <button className="btn btn-success btn-sm button-spacing" onClick={() => handleBulkAssignClick(numberType)}>Assign User</button>
                                            )}
                                            {(bulkUpdateButton) && (
                                                <button className="btn btn-success btn-sm button-spacing" onClick={() => handleBulkUpdateButtonClick(numberType)}>Bulk Update</button>
                                            )}

                                        </Col>
                                       

                                    </Row>
                                    <TabView 
                                        activeIndex={activeIndex} 
                                        // style={{ border: "1px solid grey" }} 
                                        onTabChange={(e) => {
                                        
                                        setActiveIndex(e.index)
                                        handleNumberTypeFilter(["DID", "VMN", "TOLL FREE"][e.index])}}>
                                        {["DID", "VMN", "TOLL FREE"].map((tab, index) => (
                                        <TabPanel
                                            key={index}
                                            header={
                                            <span
                                            style={{
                                                display: "inline-block",
                                                // padding: "10px 15px",
                                                // border: activeIndex === index ? "1px solid grey" : "1px solid transparent",
                                                borderRadius: "5px",
                                                fontWeight: activeIndex === index ? "bold" : "normal",
                                                border: 'none'
                                                // backgroundColor: activeIndex === index ? "#f8f8f8" : "transparent", // Light grey background
                                              }}
                                            >
                                                {tab}
                                            </span>
                                            }
                                        >
                                            <div className="scroll-container">
                                                <DataTable value={numbersList} tableStyle={{ minWidth: '50rem' }} filters={filters} paginator rows={8} rowClassName={rowClassName}>
                                                    <Column 
                                                        header={<Checkbox 
                                                        checked={selectedNumbers.length === numbersList.length && numbersList.length > 0} 
                                                        onChange={handleSelectAll} 
                                                        />} 
                                                        headerStyle={{ width: '3rem' }} 
                                                        body={(rowData) => (
                                                            <Checkbox 
                                                            checked={selectedNumbers.includes(rowData._id)} 
                                                            onChange={() => handleCheckboxToggle(rowData._id)} 
                                                            />
                                                            )} 
                                                    />
                                                    <Column field="actual_number" filterField="actual_number" header={numberType} filter filterPlaceholder="Search by Number" body={(rowData) => rowData.actual_number || '-'}></Column>
                                                    <Column field="country_code" filterField="country_code" header="Country Code" filter filterPlaceholder="Search by Country Code" body={(rowData) => rowData.country_code || '-'}></Column>
                                                    <Column field="state_code" filterField="state_code" header="State Code" filter filterPlaceholder="Search by State Code" body={(rowData) => rowData.state_code || '-'}></Column>
                                                    {!(isReseller || isCompanyUser) && (
                                                        <Column field="cost" filterField="cost" header="Cost" filter filterPlaceholder="Search by Cost" body={(rowData) => rowData.cost || '-'}></Column>
                                                    )}
                                                    {!(isReseller || isCompanyUser) && (
                                                        <Column field="operator" filterField="operator" header="Operator" filter filterPlaceholder="Search by Operator" body={(rowData) => rowData.operator || '-'}></Column>
                                                    )}
                                                    {!(isReseller || isCompanyUser) && (
                                                        <Column field="category" filterField="category" header="Category" filter filterPlaceholder="Search by Category" filterElement={categoryFilterTemplate} body={(rowData) => rowData.category || '-'}></Column>
                                                    )}
                                                    {!(isReseller || isCompanyUser) && (
                                                        <Column field="currency" filterField="currency" header="Currency" filter filterPlaceholder="Search by Currency"  filterElement={currencyFilterTemplate} body={(rowData) => rowData.currency || '-'}></Column>
                                                    )}
                                                
                                                    <Column field="number_type" filterField="number_type" header="Number Type" filter filterPlaceholder="Search by Number Type" filterElement={numberTypeFilterTemplate} body={(rowData) => rowData.number_type || '-'}></Column>
                                                    <Column field="status" header="Status" filterElement={statusFilterTemplate} filter body={(rowData) => rowData.status || '-'}></Column>
                                                    {!(isReseller || isCompanyUser) && (
                                                        <Column field="routing_id" filterField="routing_id" header="Routing Id" filter filterPlaceholder="Search by Routing Id" body={(rowData) => rowData.routing_id || '-'}></Column>
                                                    )}
                                                    {!(isReseller || isCompanyUser) && (
                                                        <Column field="routing_type" filterField="routing_type" header="Routing Type" filter filterPlaceholder="Search by Routing Type"  
                                                        body={(rowData) => {
                                                            if (rowData.routing_type) {
                                                            if (rowData.routing_type == "AGENT") {
                                                                return <img src={agentImg} alt='Agent' style={{ width: "23px", height: "23px" }} title="Agent"/>
                                                            }
                                                            else if (rowData.routing_type == "IVR") {
                                                                return <img src={ivrImg} alt='IVR' style={{ width: "23px", height: "23px" }} title='IVR'/>
                                                            }
                                                            else if (rowData.routing_type == "CALLGROUP") {
                                                                return <img src={callgroupImg} alt='Call Group' style={{ width: "23px", height: "23px" }} title='Call Group'/>
                                                            }
                                                            return rowData.routing_type;
                                                            } else {
                                                            return '-';
                                                            }
                                                        }}></Column>
                                                    )}
                                                    {!(isReseller || isCompanyUser) && (
                                                        <Column field="routing_destination" filterField="routing_destination" header="Routing Destination" filter filterPlaceholder="Search by Routing"  body={(rowData) => rowData.routing_destination || '-'}></Column>
                                                    )}
                                                
                                                    <Column field="created_at" header="Created Date"></Column>
                                                    {((isSuperAdmin || moduleOperations?.Numbers?.edit || moduleOperations?.Numbers?.delete)) && (
                                                    //   <Column header="Action" body={actionBodyTemplate} />
                                                    <Column  header="Action" body={(rowData)=><RenderActionComponent id={rowData._id} number_type={rowData.number_type} routingType={rowData.routing_type} />}></Column>
                                                    )}
                                                </DataTable>
                                            </div>
                                        </TabPanel>
                                        ))}
                                    </TabView>
                                   
                                    {(!["DID", "VMN", "TOLL FREE"].includes(["DID", "VMN", "TOLL FREE"][activeIndex])) && (
                                        <div className="scroll-container">
                                            <DataTable value={numbersList} tableStyle={{ minWidth: '50rem' }} filters={filters} paginator rows={8} rowClassName={rowClassName}>
                                                <Column 
                                                    header={<Checkbox 
                                                    checked={selectedNumbers.length === numbersList.length && numbersList.length > 0} 
                                                    onChange={handleSelectAll} 
                                                    />} 
                                                    headerStyle={{ width: '3rem' }} 
                                                    body={(rowData) => (
                                                        <Checkbox 
                                                        checked={selectedNumbers.includes(rowData._id)} 
                                                        onChange={() => handleCheckboxToggle(rowData._id)} 
                                                        />
                                                        )} 
                                                />
                                                <Column field="actual_number" filterField="actual_number" header={numberType} filter filterPlaceholder="Search by Number" body={(rowData) => rowData.actual_number || '-'}></Column>
                                                <Column field="country_code" filterField="country_code" header="Country Code" filter filterPlaceholder="Search by Country Code" body={(rowData) => rowData.country_code || '-'}></Column>
                                                <Column field="state_code" filterField="state_code" header="State Code" filter filterPlaceholder="Search by State Code" body={(rowData) => rowData.state_code || '-'}></Column>
                                                {!(isReseller || isCompanyUser) && (
                                                    <Column field="cost" filterField="cost" header="Cost" filter filterPlaceholder="Search by Cost" body={(rowData) => rowData.cost || '-'}></Column>
                                                )}
                                                {!(isReseller || isCompanyUser) && (
                                                    <Column field="operator" filterField="operator" header="Operator" filter filterPlaceholder="Search by Operator" body={(rowData) => rowData.operator || '-'}></Column>
                                                )}
                                                {!(isReseller || isCompanyUser) && (
                                                    <Column field="category" filterField="category" header="Category" filter filterPlaceholder="Search by Category" filterElement={categoryFilterTemplate} body={(rowData) => rowData.category || '-'}></Column>
                                                )}
                                                {!(isReseller || isCompanyUser) && (
                                                    <Column field="currency" filterField="currency" header="Currency" filter filterPlaceholder="Search by Currency"  filterElement={currencyFilterTemplate} body={(rowData) => rowData.currency || '-'}></Column>
                                                )}
                                            
                                                <Column field="number_type" filterField="number_type" header="Number Type" filter filterPlaceholder="Search by Number Type" filterElement={numberTypeFilterTemplate} body={(rowData) => rowData.number_type || '-'}></Column>
                                                <Column field="status" header="Status" filterElement={statusFilterTemplate} filter body={(rowData) => rowData.status || '-'}></Column>
                                                {!(isReseller || isCompanyUser) && (
                                                    <Column field="routing_id" filterField="routing_id" header="Routing Id" filter filterPlaceholder="Search by Routing Id" body={(rowData) => rowData.routing_id || '-'}></Column>
                                                )}
                                                {!(isReseller || isCompanyUser) && (
                                                    <Column field="routing_type" filterField="routing_type" header="Routing Type" filter filterPlaceholder="Search by Routing Type"  
                                                    body={(rowData) => {
                                                        if (rowData.routing_type) {
                                                        if (rowData.routing_type == "AGENT") {
                                                            return <img src={agentImg} alt='Agent' style={{ width: "23px", height: "23px" }} title="Agent"/>
                                                        }
                                                        else if (rowData.routing_type == "IVR") {
                                                            return <img src={ivrImg} alt='IVR' style={{ width: "23px", height: "23px" }} title='IVR'/>
                                                        }
                                                        else if (rowData.routing_type == "CALL GROUP") {
                                                            return <img src={callgroupImg} alt='Call Group' style={{ width: "23px", height: "23px" }} title='Call Group'/>
                                                        }
                                                        return rowData.routing_type;
                                                        } else {
                                                        return '-';
                                                        }
                                                    }}></Column>
                                                )}
                                                {!(isReseller || isCompanyUser) && (
                                                    <Column field="routing_destination" filterField="routing_destination" header="Routing Destination" filter filterPlaceholder="Search by Routing"  body={(rowData) => rowData.routing_destination || '-'}></Column>
                                                )}
                                            
                                                <Column field="created_at" header="Created Date"></Column>
                                                {((isSuperAdmin || moduleOperations?.Numbers?.edit || moduleOperations?.Numbers?.delete)) && (
                                                //   <Column header="Action" body={actionBodyTemplate} />
                                                <Column  header="Action" body={(rowData)=><RenderActionComponent id={rowData._id} number_type={rowData.number_type} routingType = {rowData.routing_type} />}></Column>
                                                )}
                                            </DataTable>
                                        </div>
                                    )}
                                        
                                   
                                </div>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>

            {/* Delete Modal */}
            <RenderDeleteModal 
                show={enableDeleteModal} 
                setShow={setEnableDeleteModal} 
                setConfirmDelete={setConfirmDelete} 
                isMultiDelete={isMultiDelete} 
                clearSelectedData={() => setSelectedNumber([])}
            />

            <ToastNotification show_toast={showToast} set_show_toast={setShowToast} {...toastProps} />

            {/* Assign Individual DID Modal */}
            {showAllocateModal && (
                <Modal show={showAllocateModal} onHide={() => {setShowAllocateModal(false); resetAllocateModalState();}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Allocate DID</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="formDid">
                                <Form.Label>Select DID</Form.Label>
                                <Dropdown
                                    value={selectedDID?.value}
                                    options={didOptions}
                                    onChange={(e) => {
                                        const selectedOption = didOptions.find(option => option.value === e.value);
                                        setSelectedDID(selectedOption)
                                    }}
                                    placeholder="Select a DID"
                                    className="form-control p-0" 
                                    style={{height:"45px"}}
                                    appendTo="self"
                                    
                                />
                            </Form.Group>
                            <Form.Group controlId="formStatus">
                                <Form.Label>Select Status</Form.Label>
                                <Dropdown
                                    value={selectedStatus}
                                    options={dropdownOptionStatus}
                                    onChange={(e) => setSelectedStatus(e.value)}
                                    placeholder="Select a Status"
                                    className="form-control p-0"style={{ height: "45px" }}
                                    appendTo="self"
                                />
                            </Form.Group>

                            {selectedStatus && ["Reserved", "Blocked"].includes(dropdownOptionStatus.find(option => option.value === selectedStatus)?.label) && (
                               <div className="expiry-date-container">
                                  <Form.Group as={Col} controlId="expiryDate">
                                     <Form.Label>Expiry Date</Form.Label>
                                     <Form.Control
                                        type="date"
                                        value={expiryDate}
                                        onChange={handleExpiryDateChange}
                                        isInvalid={!!expiryDateError}
                                      />
                                   <Form.Control.Feedback type="invalid">
                                        {expiryDateError}
                                   </Form.Control.Feedback>
                                 </Form.Group>
                                </div>
                            )}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer className="custom-modal-footer">
                        <Button className="p-button-secondary" onClick={() => {setShowAllocateModal(false); resetAllocateModalState();}}>
                            Close
                        </Button>
                        <Button className="p-button-primary" onClick={handleAllocate}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}


                {/* Assign BULK DID Modal */}
                <Modal show={showBulkAssignModal} onHide={() => setShowBulkAssignModal(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title  style={{fontSize: '16px'}}>Bulk Assign DID for {numberType}</Modal.Title>
                  </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Button variant="primary" 
                            onClick={(event) => {
                                const { columns, filename } = getColumnsAndFilenameForAssignDID(numberType);
                                downloadTemplate(event, columns, filename);
                            }}
                            style={{ marginBottom: '15px' }}
                        >
                           Download Template
                        </Button>
                        <Form.Group controlId="formFile">
                            {/* <Form.Label>Upload CSV File</Form.Label> */}
                            <Form.Control type="file" onChange={(e) =>handleFileUpload(e)} accept=".csv" />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="custom-modal-footer">
                    <Button variant="p-button-secondary button-sm" onClick={() => {setShowBulkAssignModal(false); setShowAssignDidButton(false); setBulkUpdateButton(false); handleNumberTypeFilter('')}}>
                      Close
                    </Button>
                    <Button variant="p-button-primary button-sm" onClick={handleBulkAssign}>
                        Upload
                    </Button>
                </Modal.Footer>
                </Modal>

                {/* Bulk Update Model  */}
                <Modal show={bulkUpdateModal} onHide={()=> setBulkUpdateModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Bulk Update</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Form>
                            
                                <div className='row'>
                                    <Form.Group className="col-md-12 form-group">
                                        <Form.Label>Select Number Type</Form.Label>
                                        <Form.Select
                                            id="numberType"
                                            value={updateNumberType}
                                            className="p-2"
                                            onChange={(e) =>setUpdateNumberType(e.target.value)}
                                            required
                                        >
                                            <option value={""}>Select Number Type</option>
                                            {NUMBERS_TYPES.map(function(val,idx){
                                            return(
                                                <option key ={idx} value={val.value}>
                                                {val.label}
                                                </option>
                                            )
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div>
                                    {updateNumberType && (
                                            <Button variant="primary" 
                                                onClick={(event) => {
                                                    const { columns, filename } = getColumnsAndFilename(updateNumberType);
                                                    downloadTemplate(event, columns, filename);
                                                }}
                                                style={{
                                                    marginBottom: '15px',
                                                    backgroundColor: '#3a57e8',
                                                    borderColor: '#3a57e8',
                                                    fontSize: '16px',
                                                    borderRadius: '5px',
                                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)'
                                                    }}
                                                    >
                                                    Download Template
                                            </Button>
                                        )}
                            </div>
                            {updateNumberType && (
                                <Form.Group controlId="formFile">
                                    <Form.Label>Upload </Form.Label>
                                    <Form.Control type="file" onChange={(e) =>handleFileUpload(e)} accept=".csv, .xls, application/vnd.ms-excel" />
                                </Form.Group>
                            )}
                            </Form>
                        </Modal.Body>

                        <Modal.Footer className="custom-modal-footer">
                            <Button variant="p-button-secondary" onClick={() => {setBulkUpdateModal(false); setBulkUpdateButton(false); resetAllocateModalState(); setShowAssignDidButton(false); handleNumberTypeFilter('')}}>
                            Close
                            </Button>
                            <Button variant="p-button-primary" onClick={() => {handleBulkUpdate(); setBulkUpdateModal(false); setBulkUpdateButton(false); resetAllocateModalState(); setShowAssignDidButton(false);}}>
                                Upload
                            </Button>
                        </Modal.Footer>
                    
                </Modal>

        </>
    )

}