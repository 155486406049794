import React, { useState, useEffect } from 'react';
import { Row, Col, Modal, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/Card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import Loader from '../../components/partials/components/loader';
import ToastNotification from '../../components/Toast';
import { getApiCall, deleteApiCall, postApiCall } from '../../service/httpCall';
import { API_QUEUES, API_EXTENSION, API_QUEUE_DELETE,API_QUEUE_UPDATE } from '../../service/url';
import RenderDeleteModal from '../../components/partials/components/delete-modal';

const Queues = () => {
    const [queueList, setQueueList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedQueues, setSelectedQueues] = useState([]);
    const [enableDeleteModal, setEnableDeleteModal] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastProps, setToastProps] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [extensionList, setExtensionList] = useState([]);
    const [isEdit, setIsEdit] = useState(false); // Tracks whether we are in edit mode


    const navigate = useNavigate();
    // Modal State
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        extension: '', 
        max_wait_time: '',
        open_wrapup: 1,
        default_wrapup_tag: 'CALL_COMPLETED',
        wrapper_session_timeout: '',
    });
    const [formErrors, setFormErrors] = useState({});

    const handleEditQueue = (queue) => {
        setFormData({
            _id: queue._id, // Include `_id` for editing
            name: queue.name,
            extension: queue.extension,
            max_wait_time: queue.max_wait_time,
            open_wrapup: queue.open_wrapup || 1,
            default_wrapup_tag: queue.default_wrapup_tag || 'CALL_COMPLETED',
            wrapper_session_timeout: queue.wrapper_session_timeout || '',
        });
        setIsEdit(true); // Set to edit mode
        setShowModal(true); // Open the modal
    };
    
    

    
    const handleDeleteQueue = (queueId) => {
        const payload = { queueIds: [queueId] }; // Send a single queue ID in an array
    
        postApiCall(API_QUEUE_DELETE, payload)
            .then((response) => {
                if (response.acknowledged) {
                    setToastProps({ type: 'success', message: 'Queue deleted successfully' });
                    setShowToast(true);
                    fetchQueues(); // Refresh the queue list after deletion
                } else {
                    setToastProps({ type: 'danger', message: response.message || 'Failed to delete queue' });
                    setShowToast(true);
                }
            })
            .catch((err) => {
                setToastProps({ type: 'danger', message: `Unable to delete queue: ${err.message}` });
                setShowToast(true);
            });
    };
    

    const fetchExtensions = () => {
        getApiCall(API_EXTENSION)
            .then((resp) => {
                if (resp) {
                    setExtensionList(resp); // Set the list of extensions
                } else {
                    setToastProps({ type: 'danger', message: 'Failed to fetch extensions' });
                    setShowToast(true);
                }
            })
            .catch((error) => {
                console.error('Error fetching extensions:', error);
                setToastProps({ type: 'danger', message: 'Error fetching extensions' });
                setShowToast(true);
            });
    };
    

    useEffect(() => {
        if (showToast) {
            const timer = setTimeout(() => {
                setShowToast(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [showToast]);

    useEffect(() => {
        if (confirmDelete) {
            handleDeleteSelectedQueues();
        }
    }, [confirmDelete]);

    const fetchQueues = () => {
        setLoading(true);
        getApiCall(API_QUEUES)
            .then((resp) => {
                if (resp) {
                    const formattedList = resp.map((item) => ({
                        _id: item._id,
                        name: item.name,
                        extension: item.extension?.extension || 'N/A',
                        max_wait_time: item.max_wait_time,
                        created_by: item.created_by?.username || 'Unknown',
                        created_at: new Date(item.created_at).toLocaleString(),
                    }));
                    setQueueList(formattedList);
                } else {
                    setToastProps({ type: 'danger', message: 'Failed to fetch queues' });
                    setShowToast(true);
                }
            })
            .catch((error) => {
                console.error('Fetch Error:', error);
                setToastProps({ type: 'danger', message: 'Error fetching queues' });
                setShowToast(true);
            })
            .finally(() => setLoading(false));
    };

    const handleDeleteSelectedQueues = () => {
        if (selectedQueues.length === 0) return;
    
        const payload = { queueIds: selectedQueues }; // Backend expects 'queueIds'
    
        postApiCall(API_QUEUE_DELETE, payload)
            .then((response) => {
                if (response.acknowledged) {
                    console.log(response.acknowledged);
                    setToastProps({ type: 'success', message: 'Queues deleted successfully' });
                    setShowToast(true);
                    fetchQueues(); 
                    setSelectedQueues([]); // Clear selected queues
                } else {
                    setToastProps({ type: 'danger', message: response.message || 'Failed to delete queues' });
                    setShowToast(true);
                }
            })
            .catch((err) => {
                setToastProps({ type: 'danger', message: `Unable to delete queues: ${err.message}` });
                setShowToast(true);
            })
            .finally(() => setEnableDeleteModal(false)); // Close the delete modal
    };
    
    
    useEffect(() => {
        fetchQueues();
        fetchExtensions(); // Fetch extensions on component mount
    }, []);
    
    

    
    

    const onCheckboxChange = (e, queueId) => {
        const { checked } = e.target;
        setSelectedQueues((prevSelected) =>
            checked ? [...prevSelected, queueId] : prevSelected.filter((id) => id !== queueId)
        );
    };
    
    const onHeaderCheckboxChange = (e) => {
        const { checked } = e.target;
        if (checked) {
            const allQueueIds = queueList.map((queue) => queue._id);
            setSelectedQueues(allQueueIds);
        } else {
            setSelectedQueues([]);
        }
    };
    

    const isValidNumber = (value) => /^\d+$/.test(value);

    const handleAddQueue = () => {
        // Validate input fields
        const errors = {};
        if (!formData.name.trim()) errors.name = 'Queue name cannot be empty';
        if (!formData.extension) {
            errors.extension = 'Extension cannot be empty';
        } else if (formData.extension < 10 || formData.extension > 49) {
            errors.extension = 'Extension must be between 10 and 49';
        }
        if (!formData.max_wait_time) errors.max_wait_time = 'Max wait time cannot be empty';
        if ((formData.open_wrapup === 1 || formData.open_wrapup === 2) && !formData.default_wrapup_tag) {
            errors.default_wrapup_tag = 'Default wrapup tag cannot be empty';
        }
        if (!formData.wrapper_session_timeout) {
            errors.wrapper_session_timeout = 'Wrapper session timeout cannot be empty';
        }
    
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }
    
        // Map default_wrapup_tag to numeric values
        const wrapupTagMapping = {
            CALL_COMPLETED: 1,
            CALL_FAILED: 2,
            CALL_DROPPED: 3,
        };
    
        const payload = {
            ...formData,
            max_wait_time: Number(formData.max_wait_time),
            wrapper_session_timeout: Number(formData.wrapper_session_timeout),
            default_wrapup_tag: wrapupTagMapping[formData.default_wrapup_tag] || null,
        };
    
        setIsSubmitting(true);
    
        // API call based on mode
        const apiCall = isEdit 
            ? postApiCall(`${API_QUEUE_UPDATE}/${formData._id}`, payload) // Update API
            : postApiCall(API_QUEUES, payload); // Create API
    
        apiCall
            .then(() => {
                const successMessage = isEdit 
                    ? 'Queue updated successfully' 
                    : 'Queue created successfully';
                setToastProps({ type: 'success', message: successMessage });
                setShowToast(true);
                fetchQueues(); // Refresh the queue list
                setShowModal(false);
                setFormData({
                    name: '',
                    extension: '',
                    max_wait_time: '',
                    open_wrapup: 1,
                    default_wrapup_tag: 'CALL_COMPLETED',
                    wrapper_session_timeout: '',
                });
                setFormErrors({});
            })
            .catch((err) => {
                const errorMessage = isEdit 
                    ? `Failed to update queue: ${err.message}` 
                    : `Failed to create queue: ${err.message}`;
                setToastProps({ type: 'danger', message: errorMessage });
                setShowToast(true);
            })
            .finally(() => setIsSubmitting(false));
    };
    
    
    
    

    return (
        <div style={{ marginTop: '20px' }}>
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <h4>Queue List</h4>
                            <Button
    className="btn btn-primary"
    onClick={() => {
        setFormData({
            name: '',
            extension: '',
            max_wait_time: '',
            open_wrapup: 1,
            default_wrapup_tag: 'CALL_COMPLETED',
            wrapper_session_timeout: '',
        });
        setIsEdit(false); // Set to add mode
        setShowModal(true); // Open the modal
    }}
>
    Add Queue
</Button>

                        </Card.Header>
                        <Card.Body className="px-0">
                            {loading ? (
                                <Loader />
                            ) : (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
                                    <Button
    label="Delete Selected"
    onClick={() => setEnableDeleteModal(true)}
    className="p-button-danger p-button-sm"
    style={{ marginLeft: '10px' }}
    disabled={selectedQueues.length === 0} // Disable button if no queues are selected
/>

</div>

<DataTable value={queueList} paginator rows={10} emptyMessage="No queues found.">
    {/* Checkbox Column for Selecting Rows */}
    <Column
        header={
            <Checkbox
                onChange={onHeaderCheckboxChange}
                checked={selectedQueues.length === queueList.length && queueList.length > 0}
            />
        }
        body={(rowData) => (
            <Checkbox
                onChange={(e) => onCheckboxChange(e, rowData._id)}
                checked={selectedQueues.includes(rowData._id)}
            />
        )}
        style={{ width: '3rem' }}
    />

    {/* Other Columns */}
    <Column field="name" header="Queue Name" />
    <Column field="extension" header="Extension" />
    <Column field="max_wait_time" header="Max Wait Time" />
    <Column field="created_by" header="Created By" />
    <Column field="created_at" header="Created At" />

    {/* Actions Column */}
    <Column
    body={(rowData) => (
        <div className="actions">
            <Button
                style={{ backgroundColor: '#f16a1b', marginRight: '10px', border: '#f16a1b' }}
                className="btn btn-sm btn-icon btn-warning"
                onClick={() => handleEditQueue(rowData)} // Call `handleEditQueue`
                title="Edit"
            >
                Edit
            </Button>
            <Button
                style={{ backgroundColor: '#c03221', border: '#c03221' }}
                className="btn btn-sm btn-icon btn-danger"
                onClick={() => handleDeleteQueue(rowData._id)} // Handle delete
                title="Delete"
            >
                Delete
            </Button>
        </div>
    )}
    header="Actions"
/>

</DataTable>

                                </>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <RenderDeleteModal
    show={enableDeleteModal}
    setShow={setEnableDeleteModal}
    setConfirmDelete={setConfirmDelete}
    isMultiDelete={true}
    clearSelectedData={() => setSelectedQueues([])}
    onConfirm={handleDeleteSelectedQueues} // Pass the handler for deletion
/>



            {/* Add Queue Modal */}
            <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
    <Modal.Header closeButton>
        <Modal.Title>{isEdit ? 'Edit Queue' : 'New Queue'}</Modal.Title> {/* Dynamically update title */}
    </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Queue Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.name}
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                placeholder="Enter queue name"
                                required
                            />
                            {formErrors.name && <span className="text-danger">{formErrors.name}</span>}
                        </Form.Group>
                        <Form.Group style={{ marginTop: '10px' }}>
    <Form.Label>Extension</Form.Label>
    <Form.Select
        value={formData.extension}
        onChange={(e) => setFormData({ ...formData, extension: e.target.value })}
        required
    >
        <option value="">Select an Extension</option>
        {extensionList.map((ext) => (
            <option key={ext._id} value={ext._id}>
                {ext.extension} ({ext.description || 'No Description'})
            </option>
        ))}
    </Form.Select>
    {formErrors.extension && <span className="text-danger">{formErrors.extension}</span>}
</Form.Group>

                        <Form.Group style={{ marginTop: '10px' }}>
                            <Form.Label>Max Wait Time</Form.Label>
                            <Form.Control
                                type="number"
                                value={formData.max_wait_time}
                                onChange={(e) => setFormData({ ...formData, max_wait_time: e.target.value })}
                                placeholder="Enter maximum wait time"
                                required
                            />
                            {formErrors.max_wait_time && <span className="text-danger">{formErrors.max_wait_time}</span>}
                        </Form.Group>
                        <Form.Group style={{ marginTop: '10px' }}>
                            <Form.Label>Open Wrapup</Form.Label>
                            <Form.Check
                                type="radio"
                                label="After Call"
                                value={1}
                                checked={formData.open_wrapup === 1}
                                onChange={() => setFormData({ ...formData, open_wrapup: 1 })}
                            />
                            <Form.Check
                                type="radio"
                                label="During Call"
                                value={2}
                                checked={formData.open_wrapup === 2}
                                onChange={() => setFormData({ ...formData, open_wrapup: 2 })}
                            />
                            <Form.Check
                                type="radio"
                                label="Turn Off"
                                value={0}
                                checked={formData.open_wrapup === 0}
                                onChange={() => setFormData({ ...formData, open_wrapup: 0 })}
                            />
                        </Form.Group>
                        <Form.Group style={{ marginTop: '10px' }}>
                            <Form.Label>Default Wrapup Tag</Form.Label>
                            <Form.Select
                                value={formData.default_wrapup_tag}
                                onChange={(e) => setFormData({ ...formData, default_wrapup_tag: e.target.value })}
                                required
                            >
                                <option value="CALL_COMPLETED">CALL_COMPLETED</option>
                                <option value="CALL_FAILED">CALL_FAILED</option>
                                <option value="CALL_DROPPED">CALL_DROPPED</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group style={{ marginTop: '10px' }}>
                            <Form.Label>Wrapper Session Timeout</Form.Label>
                            <Form.Control
                                type="number"
                                value={formData.wrapper_session_timeout}
                                onChange={(e) =>
                                    setFormData({ ...formData, wrapper_session_timeout: e.target.value })
                                }
                                placeholder="Enter wrapper session timeout"
                                required
                            />
                            {formErrors.wrapper_session_timeout && (
                                <span className="text-danger">{formErrors.wrapper_session_timeout}</span>
                            )}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)} style={{ marginRight: '20px' }}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleAddQueue} disabled={isSubmitting}>
    {isSubmitting 
        ? isEdit 
            ? 'Updating...' // Show "Updating..." when in edit mode and submitting
            : 'Creating...' // Show "Creating..." when in add mode and submitting
        : isEdit 
            ? 'Update Queue' // Show "Update Queue" when in edit mode and not submitting
            : 'Create Queue' // Show "Create Queue" when in add mode and not submitting
    }
</Button>

                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Queues;
