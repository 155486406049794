import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button,Table } from "react-bootstrap";
import Card from "../../../components/Card";
import ToastNotification from "../../../components/Toast";
import { API_ACL_SETTING_LIST, API_USERS_PROFILE } from "../../../service/url";
import { postApiCall, getApiCall } from "../../../service/httpCall";
import { useNavigate, useParams } from "react-router-dom";
import { USER_STATUS } from "../../../utilities/constant";
import RenderUserCompanyFields from "../../../components/partials/dashboard/users/company";
import {getUser,
  getUserModules,
  getUserUpdateAccessRoleLabel,
  ifUserAssociateCompany,
} from "../../../utilities/setting";
import { validatePhone } from "../../../utilities/helpers";
import { ROUTES } from "../../../utilities/constant";
import { useAuth, errorCheck } from '../auth/auth-utils';
import { USER_ROLES } from '../../../utilities/constant';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {validateEmail} from '../../../utilities/helpers'
import "../../../assets/custom/css/custom.css";



const VALIDATION_MIN_PASSWORD_LENGTH = 6;

const UserAdd = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { checkAuth } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastProps, setToastProps] = useState(null);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyPincode, setCompanyPincode] = useState("");
  const [companyId, setCompanyId] = useState(undefined);
  const [activeUserAccessRole, setActiveUserAccessRole] = useState(undefined);
  const [userAccessRoles, setUserAccessRoles] = useState([]);
  const [aclSettingList, setAClSettingList] = useState([]);
  const [selectedACL, setSelectedACL] = useState("");
  const [ifRenderCompanyFields, setIfRenderCompanyFields] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedUserType, setSelectedUserType] = useState("");
  const [isCompanyAdmin, setCompanyAdmin] = useState(false);
  const [isCallCenterAdmin, setCallCenterAdmin] = useState(false);
  const [isSuperAdminLogged, setIsSuperAdminLogged] = useState(false);
  const [isResellerLogged, setIsResellerLogged] = useState(false);
  const [isCompanyUserLogged, setIsCompanyUserLogged] = useState(false);
  const [isCallCenterAdminLogged, setIsCallCenterAdminLogged] = useState(false);
  const [isReseller, setIsReseller] = useState(false);
  const [channel, setChannel] = useState(0);
  const [companyUser , setCompanyUser] = useState(0);
  const [callCenterAdminUser , setCallCenterAdminUser] = useState(0);
  const [callCenterAdminAgent , setCallCenterAdminAgent] = useState(0);
  const [callCenterAdminTeamlead , setCallCenterAdminTeamlead] = useState(0);
  const [agent , setAgent] = useState(0);
  const [subLicenceId, setSubLicenceId] = useState("");
  const [selectedFlowType, setSelectedFlowType] = useState("");
  const [companyUserRole, setCompanyUserRole] = useState(false);
  const [currentEditUserRole, setCurrentEditUserRole] = useState(null);
  const [availableLicence, setAvailableLicence] = useState({});
  const [errors, setErrors] = useState({
      role_cadmin: "",
      role_ccadmin: "",
      role_ccagent: "",
      role_ccteamlead: "",
      agent: "",
      channel: ""
    });

  const [currentUserLicence, setCurrentUserLicence] = useState({
      role_cadmin: "",
      role_ccadmin: "",
      role_ccagent: "",
      role_ccteamlead: "",
      agent: "",
      channel: ""
  });

  const [emailError, setEmailError] = useState("");
  
  
  
    const validateInput = (value, field) => {
      if (value === "") {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: "This field cannot be empty" }));
        return "";
      }
    
      const numValue = Number(value);
  
      if (isResellerLogged) {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
        return numValue
      }
    
      if (isNaN(numValue)) {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: "Invalid number" }));
        return "";
      }
  
      if (numValue > availableLicence[field]) {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: `Value cannot be more than ${availableLicence[field]}` }));
        return numValue;
      }
    
      setErrors((prevErrors) => ({ ...prevErrors, [field]: "" })); 
      return numValue;
    };

  useEffect(() => {
    async function superadminCheck() {
      const user = JSON.parse(await AsyncStorage.getItem('user'));
      if (user.role !== USER_ROLES.RESELLER && user.role !== USER_ROLES.SUPERADMIN) {
        const updatedAvailableLicence = {
          role_cadmin: (user?.sub_user_licence_id?.available_licence?.role_cadmin || 0) + (companyUser || 0),
          role_ccadmin: (user?.sub_user_licence_id?.available_licence?.role_ccadmin || 0) + (callCenterAdminUser || 0),
          role_ccagent: (user?.sub_user_licence_id?.available_licence?.role_ccagent || 0) + (callCenterAdminAgent || 0),
          role_ccteamlead: (user?.sub_user_licence_id?.available_licence?.role_ccteamlead || 0) + (callCenterAdminTeamlead || 0),
          agent: (user?.sub_user_licence_id?.available_licence?.agent || 0) + (agent || 0),
          channel: (user?.sub_user_licence_id?.available_licence?.channel || 0) + (channel || 0),
        };
        setAvailableLicence(updatedAvailableLicence);
      }

      if (user.role === USER_ROLES.SUPERADMIN) {
        setIsSuperAdminLogged(true)
      }
      if (user.role === USER_ROLES.RESELLER) {
        setIsResellerLogged(true)
      }
      if (user.role === USER_ROLES.COMPANY_ADMIN) {
        setIsCompanyUserLogged(true)
      }
      if (user.role === USER_ROLES.CALL_CENTRE_ADMIN) {
        setIsCallCenterAdminLogged(true)
      }
      if (selectedUserType === USER_ROLES.SUPERADMIN) {
        setIsSuperAdmin(true);
      }
      if (selectedUserType === USER_ROLES.COMPANY_ADMIN) {
        setCompanyAdmin(true);
      }
      if (selectedUserType === USER_ROLES.CALL_CENTRE_ADMIN) {
        setCallCenterAdmin(true);
      }
      if (user.role === USER_ROLES.COMPANY_ADMIN){
        setCompanyUserRole(true);
      }

      if (user.role === USER_ROLES.RESELLER) {
        setIsReseller(true);
      }
      
      setLoading(false);
    }
    superadminCheck();
  }, [selectedUserType]);

 
  function getProfileApiUrl() {
    return API_USERS_PROFILE.replace(":id", id);
  }

  useEffect(() => {
    if (!loading) { // Ensure superadminCheck has completed
      getApiCall(API_ACL_SETTING_LIST, {})
        .then(onAClSettingListSuccess)
        .catch(onError);
    }
  }, [loading]);

  useEffect(() => {
    const profileApiUrl = getProfileApiUrl();
    getApiCall(profileApiUrl, {}).then(onProfileGetSuccess).catch(onError);
  }, [id]);


  useEffect(() => {
    checkAuth(setToastProps, setShowToast)
  }, []);

  useEffect(() => {
    if (toastProps) {
      setShowToast(true);
    }
  }, [toastProps]);

  useEffect(() => {
    getUserUpdateAccessRoleLabel()
      .then((resp) => {
        if (resp) {
          setUserAccessRoles(resp);
          setActiveUserAccessRole(resp[0].value);
          const isUserAssociatedCompany = ifUserAssociateCompany(resp[0].value);
          setIfRenderCompanyFields(isUserAssociatedCompany);
        }
      })
      .catch(onError);
  }, []);

  function onProfileGetSuccess(resp) {
    // setSelectedLicence(resp.licence);
    setSubLicenceId(resp.sub_user_licence_id?._id);
    setCurrentEditUserRole(resp?.role);
    //Set user details
    setUsername(resp.username);
    setStatus(resp.status);
    setName(resp.name);
    setEmail(resp.email);
    setSelectedACL(resp.acl_settings?._id);
    setSelectedUserType(resp.role)
    // setSelectedLicence(resp.licence)
    setSelectedFlowType(resp?.flow_type || null);

    setCompanyUser(resp?.sub_user_licence_id?.available_licence?.role_cadmin);
    setCallCenterAdminUser(resp?.sub_user_licence_id?.available_licence?.role_ccadmin);
    setCallCenterAdminAgent(resp?.sub_user_licence_id?.available_licence?.role_ccagent);
    setCallCenterAdminTeamlead(resp?.sub_user_licence_id?.available_licence?.role_ccteamlead);
    setAgent(resp?.sub_user_licence_id?.available_licence?.agent);
    setChannel(resp?.sub_user_licence_id?.available_licence?.channel);

    setCurrentUserLicence({
      role_cadmin: resp?.sub_user_licence_id?.available_licence?.role_cadmin,
      role_ccadmin: resp?.sub_user_licence_id?.available_licence?.role_ccadmin,
      role_ccagent: resp?.sub_user_licence_id?.available_licence?.role_ccagent,
      role_ccteamlead: resp?.sub_user_licence_id?.available_licence?.role_ccteamlead,
      agent: resp?.sub_user_licence_id?.available_licence?.agent,
      channel: resp?.sub_user_licence_id?.available_licence?.channel
    })

    //Set company details
    const companyDetails = resp.companies?._id;
    if (companyDetails) {
      setCompanyName(companyDetails.name);
      setCompanyPhone(companyDetails.phone);
      setCompanyPincode(companyDetails.pincode);
      setCompanyAddress(companyDetails.address);
      setCompanyId(companyDetails["_id"]);
      // setCompanyId(companyDetails);

    }
    
  }

  function onAClSettingListSuccess(resp) {
    // setAClSettingList(resp);

    if (isSuperAdminLogged) {
      setAClSettingList(resp);
    } else {
      getUser().then((user) => {
        if (user) {
          if (user?.acl_settings) {
            setAClSettingList([user.acl_settings]);
          }
        }
      });
    }
  }

  function validateForm() {
    let valid = true;

    if (!username.trim()) {
      valid = false;
      setToastProps({ message: "Username required", type: "danger" });
    } else if (!status || isNaN(status)) {
      valid = false;
      setToastProps({ message: "Status required", type: "danger" });
    } else if (password.trim() && confirmPassword.trim()) {
      if (
        password.trim().length < VALIDATION_MIN_PASSWORD_LENGTH ||
        confirmPassword.trim().length < VALIDATION_MIN_PASSWORD_LENGTH
      ) {
        valid = false;
        setToastProps({
          message: `Password and confirm password must be of minimum ${VALIDATION_MIN_PASSWORD_LENGTH} characters`,
          type: "danger",
        });
      } else if (password.trim() != confirmPassword.trim()) {
        valid = false;
        setToastProps({
          message: "Password and confirm password not matching",
          type: "danger",
        });
      } 
      // else if (!licence && isSuperAdmin) {
      //   valid = false;
      //   setToastProps({
      //     message: "Licence Is required",
      //     type: "danger",
      //   });
      // }
    } else if (ifRenderCompanyFields && !(isCallCenterAdmin)) {
      valid = validateCompanyFields();
    }

    if (isSuperAdmin || isCompanyAdmin || isCallCenterAdmin) {
      if (!selectedUserType.trim()) {
        valid = false;
        setToastProps({ message: "UserType required", type: "danger" });
      }
    }

    return valid;
  }

  const updateAvailableLicences = async (sub_licence) => {
    try {
      const userData = await AsyncStorage.getItem('user');
      if (!userData) return;

      const user = JSON.parse(userData);
      if (user.sub_user_licence_id && user.sub_user_licence_id.available_licence) {
        // let available_licence = user.sub_user_licence_id.available_licence;
        const available_licence = {
          role_cadmin: (user?.sub_user_licence_id?.available_licence?.role_cadmin || 0) + (currentUserLicence.role_cadmin || 0),
          role_ccadmin: (user?.sub_user_licence_id?.available_licence?.role_ccadmin || 0) + (currentUserLicence.role_ccadmin || 0),
          role_ccagent: (user?.sub_user_licence_id?.available_licence?.role_ccagent || 0) + (currentUserLicence.role_ccagent || 0),
          role_ccteamlead: (user?.sub_user_licence_id?.available_licence?.role_ccteamlead || 0) + (currentUserLicence.role_ccteamlead || 0),
          agent: (user?.sub_user_licence_id?.available_licence?.agent || 0) + (currentUserLicence.agent || 0),
          channel: (user?.sub_user_licence_id?.available_licence?.channel || 0) + (currentUserLicence.channel || 0),
        };

        let newAvailableLicences = {
          role_cadmin: Math.max(available_licence.role_cadmin - sub_licence.role_cadmin, 0),
          role_ccadmin: Math.max(available_licence.role_ccadmin - sub_licence.role_ccadmin, 0),
          role_ccagent: Math.max(available_licence.role_ccagent - sub_licence.role_ccagent, 0),
          role_ccteamlead: Math.max(available_licence.role_ccteamlead - sub_licence.role_ccteamlead, 0),
          agent: Math.max(available_licence.agent - sub_licence.agent, 0),
          channel: Math.max(available_licence.channel - sub_licence.channel, 0),
        };

        // Apply additional subtractions based on conditions
        // if (companyUserRole) {
        //   if (selectedUserType === USER_ROLES.COMPANY_ADMIN) {
        //     newAvailableLicences.role_cadmin = Math.max(newAvailableLicences.role_cadmin - 1, 0);
        //   } else if (selectedUserType === USER_ROLES.CALL_CENTRE_ADMIN) {
        //     newAvailableLicences.role_ccadmin = Math.max(newAvailableLicences.role_ccadmin - 1, 0);
        //   }
        // }

        // if (!companyUserRole && !isReseller && !isSuperAdmin) {
        //   if (selectedUserType === USER_ROLES.CALL_CENTRE_ADMIN) {
        //     newAvailableLicences.role_ccadmin = Math.max(newAvailableLicences.role_ccadmin - 1, 0);
        //   } else if (selectedUserType === USER_ROLES.CALL_CENTRE_AGENT) {
        //     newAvailableLicences.role_ccagent = Math.max(newAvailableLicences.role_ccagent - 1, 0);
        //   } else if (selectedUserType === USER_ROLES.CALL_CENTRE_TEAM_LEAD) {
        //     newAvailableLicences.role_ccteamlead = Math.max(newAvailableLicences.role_ccteamlead - 1, 0);
        //   }
        // }

        user.sub_user_licence_id.available_licence = newAvailableLicences;
        return user
        // Save updated user data back to AsyncStorage
        // await AsyncStorage.setItem('user', JSON.stringify(user));
      } else {
        console.log('sub_user_licence_id or available_licence not found in user object');
      }
    } catch (error) {
      console.error('Error updating available licences:', error);
    }
  };

  const updateLocalStorage = async (finalData) => {
    try {
        // Save updated user data back to AsyncStorage
        await AsyncStorage.setItem('user', JSON.stringify(finalData));
    } catch (error) {
      console.error('Error updating available licences:', error);
    }
  };

  async function submitForm() {
    setButtonDisable(true);

    if (!validateForm()) {
      setButtonDisable(false);
      return;
    }

    if (Object.values(errors).some(value => value !== "")) {
      setToastProps({ message: "Enter All correct values", type: "danger" });
      setButtonDisable(false);
      return;
    }

    const sub_licence = {
      role_cadmin: Number(companyUser),
      role_ccadmin: Number(callCenterAdminUser),
      role_ccagent: Number(callCenterAdminAgent),
      role_ccteamlead: Number(callCenterAdminTeamlead),
      agent: Number(agent),
      channel: Number(channel)
    };

    const companyParams = {
      name: companyName,
      phone: companyPhone,
      pincode: companyPincode,
      address: companyAddress,
      id: companyId,
    };
    const params = { username, name, email, status,
      acl_settings: (selectedACL === "") ? null: selectedACL,
      // ...(isSuperAdmin && { licence: licence })
    };

    if (!isCallCenterAdmin) {
      params.company = companyParams
    }

    params.role = (isSuperAdmin || isCompanyAdmin || isCallCenterAdmin) ? selectedUserType : activeUserAccessRole

    if (password) {
      params["password"] = password;
    }

    // if (isReseller) {
    //   params.sub_licence = available_licence;
    // }

    if (companyUserRole) {
      params.flow_type = Number(selectedFlowType)
    }

    
    let finalData
    if (!isResellerLogged && !isSuperAdminLogged) {
      finalData = await updateAvailableLicences(sub_licence);
      params.parent_licence = finalData?.sub_user_licence_id?.available_licence
    }
    
    if (!isSuperAdminLogged) {
      params.sub_licence = sub_licence
    }
    
    try {
      const profileApiUrl = getProfileApiUrl();
      const resp = await postApiCall(profileApiUrl, params);
    
      if (resp) {
        await onUserUpdateSuccess(resp, finalData);
      } else {
        throw new Error(resp?.message || "User Updation failed");
      }
    } catch (error) {
      onError(error);
    }
  }

   async function onUserUpdateSuccess(resp, finalData) {
      if (!isReseller && !isSuperAdmin) {
        await updateLocalStorage(finalData);
      }
      setToastProps({ message: "User updated successfully", type: "success" });
      setTimeout(() => {
        navigate(`/${ROUTES.USERS.LIST}`);
      }, 1500);
    }

  function onError(err) {
    if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
      errorCheck(err, navigate, setToastProps, setShowToast)
   } else {
     setButtonDisable(false);
     setToastProps({message: JSON.stringify(err), type: 'danger'});
   }
 }

  function RenderUserStatusOptions() {
    let arrStatusOptions = [<option key="select-status" value="">Select status</option>];

    USER_STATUS.map((val,idx) => {
      arrStatusOptions.push(<option key={idx} value={val.value}>{val.label}</option>);
    });

    return arrStatusOptions;
  }

  function validateCompanyFields() {
    let valid = true;
    if (!companyName?.trim()) {
      valid = false;
      setToastProps({ message: "Company name required", type: "danger" });
    } else if (!validatePhone(companyPhone)) {
      valid = false;
      setToastProps({
        message: "Company phone invalid format",
        type: "danger",
      });
    } else if (!companyPincode?.trim()) {
      valid = false;
      setToastProps({ message: "Company pincode required", type: "danger" });
    } else if (!/^\d{4}$/.test(companyPincode)) {
      valid = false;
      setToastProps({ message: "Company pincode must be exactly 4 digits", type: "danger" });
    } else if (!companyAddress?.trim()) {
      valid = false;
      setToastProps({ message: "Company address required", type: "danger" });
    }

    if (emailError) {
      valid = false;
      setToastProps({ message: "Enter correct Email", type: "danger" });
    }

    return valid;
  }

  
 const handleEmailChange = (e) => {
  const value = e.target.value;
  setEmail(value);
  setEmailError(validateEmail(value));
};

  return (
    <>
      <div>
        <Row>
          <Col xl="12" lg="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">User Information</h4>
                </div>
              </Card.Header>
              <Card.Body>
                <div className="new-user-info">
                  <form>
                    <div className="row">
                      <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="username">User Name:</Form.Label>
                        <Form.Control
                          type="text"
                          id="username"
                          placeholder="User Name"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="name">Full Name:</Form.Label>
                        <Form.Control
                          type="text"
                          id="name"
                          maxLength={100}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </div>
                    <div className="row">
                      <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="email">Email:</Form.Label>
                        <Form.Control
                          type="email"
                          id="email"
                          maxLength={100}
                          value={email}
                          onChange={handleEmailChange}
                          required
                        />
                        {emailError && <span className="text-danger">{emailError}</span>}
                      </Form.Group>
                      <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="status">Status:</Form.Label>
                        <select
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                          className="form-select p-2"
                          id="status"
                        >
                          <RenderUserStatusOptions />
                        </select>
                      </Form.Group>
                    </div>
                    <div className="row">
                      <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="pass">Password:</Form.Label>
                        <Form.Control
                          type="password"
                          id="pass"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </Form.Group>
                      {/* <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="rpass">
                          Confirm Password:
                        </Form.Label>
                        <Form.Control
                          type="password"
                          id="rpass"
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </Form.Group> */}
                       <Form.Group className="col-md-6 form-group">
                       <Form.Label htmlFor="aclSetting">ACL Setting:</Form.Label>
                          <Form.Select
                            id="aclSetting"
                            required
                            value={selectedACL}
                            className="p-2"
                            onChange={(e) => setSelectedACL(e.target.value)}
                          >
                            <option value={""}>ACL Setting</option>
                            {aclSettingList.map(function (val, idx) {
                              return (
                                <option key={idx} value={val._id}>
                                  {val.acl_name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                    </div>
                    {(selectedUserType !== USER_ROLES.CALL_CENTRE_ADMIN &&
                      selectedUserType !== USER_ROLES.CALL_CENTRE_AGENT &&
                      selectedUserType !== USER_ROLES.CALL_CENTRE_AGENT
                    ) &&  (
                      <RenderUserCompanyFields
                      isEditMode={true}
                      company_name={companyName}
                      company_phone={companyPhone}
                      company_pincode={companyPincode}
                      company_address={companyAddress}
                      set_company_name={setCompanyName}
                      set_company_phone={setCompanyPhone}
                      set_company_pincode={setCompanyPincode}
                      set_company_address={setCompanyAddress}
                    />
                    )}

                    {/* Row with userType and licence dropdowns */}
                    {(isSuperAdminLogged || isCompanyUserLogged || isCallCenterAdminLogged) ? (
                                            <div className="row">
                                              
                                              {/* <Form.Group className="col-md-6 form-group">
                                                <Form.Select
                                                  id="userType"
                                                  required
                                                  value={selectedUserType}
                                                  className="p-2"
                                                  onChange={(e) => setSelectedUserType(e.target.value)}
                                                >
                                                  <option value="" disabled hidden>
                                                    UserType
                                                  </option>
                                                  {isSuperAdmin && (
                                                    <>
                                                      <option key="1" value="role_sub_superadmin">
                                                        Sub-Superadmin
                                                      </option>
                                                      <option key="2" value="role_reseller">
                                                        Reseller
                                                      </option>
                                                    </>
                                                  )}
                                                  {isCompanyAdmin && (
                                                    <>
                                                      <option key="1" value="role_cadmin">
                                                        Company User
                                                      </option>
                                                      <option key="2" value="role_ccadmin">
                                                        Call-Center
                                                      </option>
                                                    </>
                                                  )}
                                                  {isCallCenterAdmin && (
                                                    <>
                                                      <option key="1" value="role_ccadmin">
                                                        Call Centre Admin User
                                                      </option>
                                                      <option key="2" value="role_ccagent">
                                                        Call Centre Admin Agent
                                                      </option>
                                                      <option key="3" value="role_ccteamlead">
                                                        Call Centre Admin Team Lead
                                                      </option>
                                                    </>
                                                  )}
                                                </Form.Select>
                                              </Form.Group> */}

                                              {(isCompanyUserLogged && currentEditUserRole == USER_ROLES.CALL_CENTRE_ADMIN) && (
                                                  <Form.Group className="col-md-6 form-group">
                                                  <Form.Select
                                                      id="callCenterType"
                                                      required
                                                      value={selectedFlowType}
                                                      className="p-2"
                                                      onChange={(e) => setSelectedFlowType(e.target.value)}
                                                      >
                                                    <option value="" disabled hidden>
                                                      Select Flow Type
                                                    </option>
                                                    <option value="0">Asterisk</option>
                                                    <option value="1">Jambonz</option>
                                                  </Form.Select>
                                                </Form.Group>
                                              )}

                                              {/* {companyUserRole && 
                                              <Form.Group className="col-md-6 form-group">
                                              <Form.Control
                                              type="text"
                                              id="callCenterType"
                                              required
                                              className="p-2"
                                              value={selectedFlowType == 1 ? "Jambonz" : "Asterisk"}
                                              readOnly
                                              />
                                             </Form.Group>

                                              } */}
                    
                                              {/* Licence Dropdown */}
                                              {/* {isSuperAdmin && 
                                              <Form.Group className="col-md-6 form-group">
                                                <Form.Select
                                                  id="licence"
                                                  required
                                                  value={licence}
                                                  className="p-2"
                                                  onChange={(e) => setSelectedLicence(e.target.value)}
                                                >
                                                  <option value="" disabled hidden>
                                                    Select Licence
                                                  </option>
                                                  {Array.from({ length: 10 }, (_, i) => (
                                                    <option key={(i + 1) * 10} value={(i + 1) * 10}>
                                                      {(i + 1) * 10}
                                                    </option>
                                                  ))}
                                                </Form.Select>
                                              </Form.Group>
                                              } */}
                                            </div>
                                          ) : (
                                            /* Row with only licence dropdown when userType is not rendered */
                                            <>
                                            {/* {isSuperAdmin &&
                                            <div className="row">
                                              <Form.Group className="col-md-6 form-group">
                                                <Form.Select
                                                  id="licence"
                                                  required
                                                  value={licence}
                                                  className="p-2"
                                                  onChange={(e) => setSelectedLicence(e.target.value)}
                                                >
                                                  <option value="" disabled hidden>
                                                    Select Licence
                                                  </option>
                                                  {Array.from({ length: 10 }, (_, i) => (
                                                    <option key={(i + 1) * 10} value={(i + 1) * 10}>
                                                      {(i + 1) * 10}
                                                    </option>
                                                  ))}
                                                </Form.Select>
                                              </Form.Group>
                                            </div>
                                             } */}
                                            </>
                                          )}

                     {!isSuperAdminLogged && 
                     <div className="scroll-container">

                       <Table
                        striped
                        bordered
                        hover
                        responsive
                        style={{ textAlign: "center" }}
                       >
                        <thead>
                          <tr>
                            {!isCallCenterAdminLogged && (<th style={{ width: "15%" }}>Company User</th>)}
                            <th style={{ width: "15%" }}>
                              Callcenter Admin User
                             </th>
                            <th style={{ width: "15%" }}>
                              Callcenter Admin Agent
                            </th>
                            <th style={{ width: "15%" }}>
                              Callcenter Admin Teamlead
                            </th>
                            <th style={{ width: "25%" }}>Total Agent</th>
                            <th style={{ width: "25%" }}>Channel</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                              {!isCallCenterAdminLogged && (
                                <td>
                                {" "}
                                  <Form.Group className="form-group">
                                    <Form.Control
                                      type="number"
                                      id="companyUser"
                                      placeholder="Company User"
                                      maxLength={100}
                                      min={0}
                                      max={availableLicence.role_cadmin}
                                      value={companyUser}
                                      onChange={(e) => setCompanyUser(validateInput(e.target.value, "role_cadmin"))}
                                      required
                                    />
                                    {errors.role_cadmin && <small className="text-danger">{errors.role_cadmin}</small>}
                                    </Form.Group>
                                  </td>
                              )}
                              <td> 
                              <Form.Group className="form-group">
                               <Form.Control
                                 type="number"
                                 id="companyAdminUser"
                                 placeholder="Callcenter Admin User"
                                 maxLength={100}
                                 min={0}
                                 max={availableLicence.role_ccadmin}
                                 value={callCenterAdminUser}
                                 onChange={(e) => setCallCenterAdminUser(validateInput(e.target.value, "role_ccadmin"))}
                                 required
                               />
                              {errors.role_ccadmin && <small className="text-danger">{errors.role_ccadmin}</small>}
                             </Form.Group>
                            </td>
                            <td>                       
                              <Form.Group className="form-group">
                                <Form.Control
                                type="number"
                                id="callcenterAdminAgent"
                                placeholder="Callcenter Admin Agent"
                                maxLength={100}
                                min={1}
                                max={availableLicence.role_ccagent}
                                value={callCenterAdminAgent}
                                onChange={(e) => setCallCenterAdminAgent(validateInput(e.target.value, "role_ccagent"))}
                                required
                                />
                                {errors.role_ccagent && <small className="text-danger">{errors.role_ccagent}</small>}
                              </Form.Group>
                             </td>
                             <td>                            
                              <Form.Group className="form-group">
                                  <Form.Control
                                  type="number"
                                  id="callcenterAdminTeamLead"
                                  placeholder="Callcenter Admin Teamlead"
                                  maxLength={100}
                                  min={0}
                                  max={availableLicence.role_ccteamlead}
                                  value={callCenterAdminTeamlead}
                                  onChange={(e) => setCallCenterAdminTeamlead(validateInput(e.target.value, "role_ccteamlead"))}
                                  required
                                  />
                                  {errors.role_ccteamlead && <small className="text-danger">{errors.role_ccteamlead}</small>}
                                </Form.Group>
                              </td>
                              <td style={{ minHeight: "60px" , minWidth:"160px" }}>                        
                                <Form.Group className="form-group">
                                  <Form.Control
                                  type="number"
                                  id="agent"
                                  placeholder="Agent"
                                  maxLength={100}
                                  min={0}
                                  max={availableLicence.agent}
                                  value={agent}
                                  onChange={(e) => setAgent(validateInput(e.target.value, "agent"))}
                                  required
                                  />
                                  {errors.agent && <small className="text-danger">{errors.agent}</small>}
                                </Form.Group>
                               </td>
                                <td style={{ minHeight: "60px" , minWidth:"160px" }}>                        
                                  <Form.Group className="form-group">
                                    <Form.Control
                                    type="number"
                                    id="channel"
                                    placeholder="Channel"
                                    maxLength={100}
                                    min={0}
                                    max={availableLicence.channel}
                                    value={channel}
                                    onChange={(e) => setChannel(validateInput(e.target.value, "channel"))}
                                    required
                                    />
                                    {errors.channel && <small className="text-danger">{errors.channel}</small>}
                                  </Form.Group>
                                </td>
                               </tr>
                            </tbody>
                         </Table>
                    </div>

                     }

                    <Button
                      onClick={async () => {
                        submitForm();
                      }}
                      className="mt-4"
                      type="button"
                      variant="btn btn-primary"
                      disabled={buttonDisable}
                    >
                      Update &nbsp;
                      {buttonDisable ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                    </Button>
                  </form>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      <ToastNotification
        show_toast={showToast}
        set_show_toast={setShowToast}
        {...toastProps}
      />
    </>
  );
};

export default UserAdd;
