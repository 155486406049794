import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { getApiCall } from "../../../service/httpCall"; // Adjust the path if necessary
import Loader from "../../../components/partials/components/loader";
import ToastNotification from "../../../components/Toast";
import { BACKEND_API_BASE_URL, USER_ROLES } from "../../../utilities/constant";
import { getUser } from "../../../utilities/setting"
import "./index.css";
import { Dialog } from "primereact/dialog";
import '@fortawesome/fontawesome-free/css/all.min.css';
import "../../../assets/custom/css/custom.css";




const ViewVoiceFiles = () => {
  const [prompts, setPrompts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toastProps, setToastProps] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [userId, setUserId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false); 
  const [audioUrl, setAudioUrl] = useState("");

  useEffect(() => {
    setLoading(true);
    // API call to fetch prompts with prompt_status: 1
    if (isSuperAdmin) {
      getApiCall(`${BACKEND_API_BASE_URL}/v1/prompt?prompt_status=1`)
        .then(onPromptsSuccess)
        .catch(onError)
        .finally(() => setLoading(false));
    }
    else {
      if (userId) {
        getApiCall(`${BACKEND_API_BASE_URL}/v1/prompt?prompt_status=1&user_id=${userId}`)
        .then(onPromptsSuccess)
        .catch(onError)
        .finally(() => setLoading(false));
      }
    }
  }, [isSuperAdmin, userId]);

  useEffect(() => {
    getUser().then((user) => {
      if (user) {
        console.log("user", user);
        if (user.role == USER_ROLES.SUPERADMIN) {
          setIsSuperAdmin(true);
          setUserId(null);
        }
        else {
          setIsSuperAdmin(false);
          setUserId(user._id);
        }
      }
    })
  }, []);

  const onPromptsSuccess = (resp) => {
    const updatedList = resp.map((item, index) => ({
      ...item,
      key: index + 1,
    }));
    setPrompts(updatedList);
  };

  const onError = (error) => {
    setToastProps({
      type: "danger",
      message: `Failed to load prompts: ${error}`,
    });
    setShowToast(true);
  };

  const downloadFile = async (fileUrl, filename) => {
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error('Failed to fetch the file');
      }
  
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = url;
      link.download = filename || fileUrl.split('/').pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error while downloading the file:', error);
    }
  };
   const handleListen = (url) => {
    setAudioUrl(url);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setAudioUrl("");
  };
 
 
   const actionBodyTemplate = (rowData) => {
 
     return (
 
       <div className="d-flex justify-space-between gap-2">
         <button
           className="btn-icons m-1"
           onClick={() => downloadFile(rowData.prompt_url, rowData.prompt_name)}
           style={{ marginLeft: '8px', border: 'none', background: 'none', padding: '0' }}
         >
           <i className="fa fa-download" aria-hidden="true" style={{ color: 'blue', fontSize: '24px'  }}></i>
         </button>
       <Button
         label="Listen"
         icon="pi pi-play"
         className="listen"
         id="btnprompt"
         onClick={() => handleListen(rowData.prompt_url)}
       />
     </div>
     );
   };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
    <div>
      <div className="card" style={{ marginTop: "20px" }}>
        <div className="card-header d-flex justify-content-between align-items-center">
          <h4 className="card-title">Voice List</h4>
        </div>
        <div className="card-body">
          <div className="scroll-container">
            <DataTable
              value={prompts}
              paginator
              rows={10}
              tableStyle={{ minWidth: "50rem" }}
            >
              <Column field="prompt_name" header="Name" filterPlaceholder="Search by name" />
              <Column field="prompt_url" header="URL" filterPlaceholder="Search by URL" />
              <Column field="prompt_duration" header="Duration" filterPlaceholder="Search by duration" />
              <Column field="prompt_category" header="Category" filterPlaceholder="Search by category" />
              <Column header="Status" body={<span className="status-text">Approved</span>} style={{ textAlign: "center" }} />
              <Column
                header="Actions"
                body={actionBodyTemplate}
                style={{ textAlign: "center", width: "12rem" }}
              />
            </DataTable>
          </div>
        </div>
      </div>
      <ToastNotification
        show_toast={showToast}
        set_show_toast={setShowToast}
        {...toastProps}
      />
    </div>
     <Dialog
            header="Audio Playback"
            visible={isModalVisible}
            style={{
              width: "40vw",
              position: "absolute",
              top: "10px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
            onHide={handleModalClose}
          >
            <audio controls style={{ width: "100%" }}>
              <source src={audioUrl} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </Dialog>
          </>    
  );
};

export default ViewVoiceFiles;
