import React, { useState, useEffect, useRef, memo } from "react";
import { Row, Col, Image, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../../../components/Card";
import {
  API_USERS_LIST,
  API_USERS_PROFILE,
  API_SWITCH_USER,
  API_CREDITS
} from "../../../service/url";
import { getApiCall, deleteApiCallWithHeader } from "../../../service/httpCall";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import {
  getUserReadAccessRoleLabel,
  getUser,
} from "../../../utilities/setting";
import RenderDeleteModal from "../../../components/partials/components/delete-modal";
import RenderInactiveUserModal from "../../../components/partials/components/inactive-user-modal";
import ToastNotification from "../../../components/Toast";
import { ROUTES, USERS_ROLE, USER_CREDITS_ACTION } from "../../../utilities/constant";
import Loader from "../../../components/partials/components/loader";
import { useNavigate } from "react-router-dom";
import { useAuth, errorCheck } from "../auth/auth-utils";
import switchUserIcon from "../../../assets/images/icons/switch-user.png";
import { switchUser } from "../../../utilities/setting";
import { USER_ROLES } from "../../../utilities/constant";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { postApiCall, patchApiCallWithHeader } from "../../../service/httpCall";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { API_USER_DELETE, API_USER_STATUS_UPDATE } from "../../../service/url";
import "../../../assets/custom/css/custom.css";
import exportData from "../../../utilities/export";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import { Dropdown as BootstrapDropdown } from "react-bootstrap";
import RenderShowPasswordModal from "../../../components/partials/components/show-password";
import RenderAddCreditModal from "../../../components/partials/components/add-credit-model";
import RenderRemoveCreditModal from "../../../components/partials/components/remove-credit-model";

const UserList = () => {
  const navigate = useNavigate();
  const { checkAuth } = useAuth();
  const [userlist, setUserlist] = useState([]);
  const [activeUserAccessRole, setActiveUserAccessRole] = useState(undefined);
  const [userAccessRoles, setUserAccessRoles] = useState([]);
  const [filters, setFilters] = useState({
    username: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    company_name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    email: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    status: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  });
  const statuses = useRef(["Active", "Inactive"]);
  const [enableInactiveModal, setEnableInactiveModal] = useState(false);
  const [inactiveUserId, setInactiveUserId] = useState(undefined);
  const [confirmUserInactive, setConfirmUserInactive] = useState(false);

  const [enableShowPasswordModal, setEnableShowPasswordModal] = useState(false);
  const [showPasswordId, setShowPasswordId] = useState(undefined);
  const [confirmShowPassword, setConfirmShowPassword] = useState(false);
  const [showPasswordUser, setShowPasswordUser] = useState(null);

  const [enableDeleteModal, setEnableDeleteModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(undefined);
  const [showToast, setShowToast] = useState(false);
  const [toastProps, setToastProps] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSuperAdmin, checkIsSuperAdmin] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isMultiDelete, setIsMultiDelete] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [moduleOperations, setModuleOperations] = useState({});
  const [userRole, setUserRole] = useState("");
  const [isCallCenterAdmin, setCallCenterAdmin] = useState(false);
  const [originalUserlist, setOriginalUserlist] = useState([]); // Store original user list
  const [selectedRole, setSelectedRole] = useState(null); // State for selected role filter
  const [selectedUsername, setSelectedUsername] = useState(null); // State for selected username filter
  const [noDataMessage, setNoDataMessage] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [uniqueUsernames, setUniqueUsernames] = useState([]);
  const [uniqueRoles, setUniqueRoles] = useState([]);
  const [defaultUniqueUsernames, setDefaultUniqueUsernames] = useState([]);
  const [showAddCreditModel, setShowAddCreditModel] = useState(false);
  const [confirmCredits, setConfirmCredits] = useState(false);
  const [addCreditsValue, setAddCreditsValue] = useState(0);
  //
  const [showRemoveCreditModel, setShowRemoveCreditModel] = useState(false);
  const [confirmReduceCredits, setConfirmReduceCredits] = useState(false);
  const [removeCreditsValue, setRemoveCreditsValue] = useState(0);
  const [isReseller, setIsReseller] = useState(false);
  const [isCompanyUser, setIsCompanyUser] = useState(false);


  const [addCreditsId, setAddCreditsId] = useState(null);
  const [removeCreditsId, setRemoveCreditsId] = useState(null);
  const [loggedInUserId, setLoggedInUserId] = useState(null);


  useEffect(() => {
    if(confirmCredits){
      if (addCreditsValue == 0) {
        setToastProps({ type: "danger", message: "Please add some credit value to increase" });
      }
      else {
        let apiUrl = API_CREDITS.replace(":id", addCreditsId);
        postApiCall(apiUrl, { id: addCreditsId, updatedCredit: addCreditsValue, action: USER_CREDITS_ACTION.ADD, fromUser: loggedInUserId })
        .then(onUserCreditsUpdatedSuccess)
        .catch(onUserCreditsUpdatedFailed)
      }
    }
  }, [confirmCredits, addCreditsValue]);

  useEffect(() => {
    if(confirmReduceCredits){
      if (removeCreditsValue == 0) {
        setToastProps({ type: "danger", message: "Please add some credit value to reduce" });
      }
      else {
        let apiUrl = API_CREDITS.replace(":id", removeCreditsId);
        postApiCall(apiUrl, { id: removeCreditsId, updatedCredit: removeCreditsValue, action: USER_CREDITS_ACTION.DEDUCT, fromUser: loggedInUserId })
        .then(onUserCreditsUpdatedSuccess)
        .catch(onUserCreditsUpdatedFailed)
      }
    }
  }, [confirmReduceCredits, removeCreditsValue]);

  function onUserCreditsUpdatedSuccess(resp) {
    setConfirmCredits(false);
    setConfirmReduceCredits(false);
    setRemoveCreditsValue(0);
    setAddCreditsValue(0);
    setShowAddCreditModel(false);
    setShowRemoveCreditModel(false);
    setToastProps({ type: "success", message: "User Credits Updated Successfully" });
  }

  function onUserCreditsUpdatedFailed(error) {
    setConfirmCredits(false);
    setConfirmReduceCredits(false);
    setRemoveCreditsValue(0);
    setAddCreditsValue(0);
    setShowAddCreditModel(false);
    setShowRemoveCreditModel(false);
    setToastProps({ type: "danger", message: error });
  }

  useEffect(() => {
    getApiCall(API_USERS_LIST, { role: activeUserAccessRole })
      .then((resp) => {
        const updatedList = resp.map((val, idx) => ({
          ...val,
          key: idx + 1,
          company_name: val.companies?.name,
          created_at: new Date(val.created_at).toGMTString(),
        }));
        setUserlist(updatedList); // Set the userlist for display
        setOriginalUserlist(updatedList); // Save the original list
      })
      .catch(onError)
      .finally(() => setLoading(false));
  }, [activeUserAccessRole]);

  const getOriginalList = () => {
    getApiCall(API_USERS_LIST, { role: activeUserAccessRole })
      .then((resp) => {
        const updatedList = resp.map((val, idx) => ({
          ...val,
          key: idx + 1,
          company_name: val.companies?.name,
          created_at: new Date(val.created_at).toGMTString(),
        }));
        setUserlist(updatedList); // Set the userlist for display
        setOriginalUserlist(updatedList); // Save the original list
      })
      .catch(onError)
      .finally(() => setLoading(false));
  };

  const clearFilters = () => {
    setSelectedRole(null);
    setSelectedUsername(null);
    setSearchValue("");
    setUserlist(originalUserlist);
    setNoDataMessage("");
  };

  useEffect(() => {
    const initialUniqueUsernames = [
      ...new Set(originalUserlist.map((user) => user.username)),
    ].map((username) => ({
      label: username,
      value: username,
    }));

    const initialUniqueRoles = [
      ...new Set(originalUserlist.map((user) => user.role)),
    ].map((role) => ({
      label: USERS_ROLE[role] || role, // Map to readable name if available, else use the role directly
      value: role,
    }));

    setUniqueRoles(initialUniqueRoles);

    if (initialUniqueUsernames) {
      setUniqueUsernames(initialUniqueUsernames);
      setDefaultUniqueUsernames(initialUniqueUsernames);
    }
  }, [originalUserlist]);

  useEffect(() => {
    if (selectedRole) {
      const filteredUsernames = originalUserlist
        .filter((user) => user.role === selectedRole)
        .map((user) => user.username);

      const uniqueFilteredUsernames = [...new Set(filteredUsernames)].map(
        (username) => ({
          label: username,
          value: username,
        })
      );

      const isSelectedUsernamePresentAccordingToSelectedRole =
        uniqueFilteredUsernames.some((user) => user.label === selectedUsername);

      if (selectedUsername) {
        if (!isSelectedUsernamePresentAccordingToSelectedRole) {
          setSelectedUsername(null);
          setUniqueUsernames(defaultUniqueUsernames);
        } else {
          setUniqueUsernames(uniqueFilteredUsernames);
        }
      } else {
        setUniqueUsernames(uniqueFilteredUsernames);
      }
    } else {
      setUniqueUsernames(defaultUniqueUsernames);
    }
  }, [selectedRole, selectedUsername]);

  useEffect(() => {
    getUser().then((user) => {
      if (user) {
        if (user.role === USER_ROLES.SUPERADMIN) {
          checkIsSuperAdmin(true);
        }
        if (user.role === USER_ROLES.CALL_CENTRE_ADMIN) {
          setCallCenterAdmin(true);
        }

        if (user.role === USER_ROLES.RESELLER) {
          setIsReseller(true);
        }

        if (user.role === USER_ROLES.COMPANY_ADMIN) {
          setIsCompanyUser(true);
        }
        if (user?.acl_settings) {
          setUserRole(user?.role);
          setModuleOperations(user.acl_settings.module_operations);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (selectedRole && !selectedUsername && !searchValue.trim()) {
      setUserlist(
        originalUserlist.filter((user) => user.role === selectedRole)
      );
    } else if (!selectedRole && selectedUsername && !searchValue.trim()) {
      setUserlist(
        originalUserlist.filter((user) => user.username === selectedUsername)
      );
    } else if (selectedRole && selectedUsername && !searchValue.trim()) {
      setUserlist(
        originalUserlist.filter(
          (user) =>
            user.role === selectedRole && user.username === selectedUsername
        )
      );
    } else if (selectedRole && searchValue.trim()) {
      setUserlist(
        originalUserlist.filter(
          (user) =>
            user.role === selectedRole &&
            (user.username
              .toLowerCase()
              .includes(searchValue.trim().toLowerCase()) ||
              user.name
                .toLowerCase()
                .includes(searchValue.trim().toLowerCase()))
        )
      );
    } else if (searchValue && !selectedRole) {
      setUserlist(
        originalUserlist.filter(
          (user) =>
            user.username
              .toLowerCase()
              .includes(searchValue.trim().toLowerCase()) ||
            user.name.toLowerCase().includes(searchValue.trim().toLowerCase())
        )
      );
    } else {
      setUserlist(originalUserlist); // Reset to the original user list if no username is selected
    }
  }, [selectedRole, selectedUsername, originalUserlist, searchValue]);

  const handleExport = async () => {
    setButtonDisable(true);
    await exportData("Users", setToastProps, setShowToast);
    setButtonDisable(false);
  };

  const handleCheckboxToggle = (userId) => {
    const isSelected = selectedUsers.includes(userId);
    setSelectedUsers(
      isSelected
        ? selectedUsers.filter((id) => id !== userId)
        : [...selectedUsers, userId]
    );
  };

  const handleMultiDelete = () => {
    setIsMultiDelete(true);
    setEnableDeleteModal(true);
  };

  useEffect(() => {
    if (toastProps) {
      setShowToast(true);
    }
  }, [toastProps]);

  useEffect(() => {
    async function superadminCheck() {
      const user = JSON.parse(await AsyncStorage.getItem("user"));
      setLoggedInUserId(user._id);
      if (user.role === USER_ROLES.SUPERADMIN) {
        checkIsSuperAdmin(true);
      }
      if (user.role === USER_ROLES.CALL_CENTRE_ADMIN) {
        setCallCenterAdmin(true);
      }
    }

    superadminCheck();
  }, []);

  useEffect(() => {
    getUserReadAccessRoleLabel()
      .then((resp) => {
        if (resp) {
          setUserAccessRoles(resp);
          setActiveUserAccessRole(resp[0].value);
        }
      })
      .catch(onError);
  }, []);

  useEffect(() => {
    if (activeUserAccessRole) {
      setLoading(true);
      getApiCall(API_USERS_LIST, { role: activeUserAccessRole })
        .then(onUserListSuccess)
        .catch(onError)
        .finally(() => setLoading(false));
    }
  }, [activeUserAccessRole]);

  function onUserListSuccess(resp) {
    const updatedList = resp.map((val, idx) => {
      val["key"] = idx + 1;
      val["company_name"] = val.companies?.name;
      // val['actions'] = RenderActionComponent(val._id);
      val["created_at"] = new Date(val["created_at"]).toGMTString();
      return val;
    });
    setUserlist(updatedList);
  }

  async function handleDeleteUser(id) {
    checkAuth(setToastProps, setShowToast);
    setIsMultiDelete(false);
    setEnableDeleteModal(true);
    setDeleteUserId(id);
  }

  async function handleAddCredits(id) {
    setShowAddCreditModel(true);
    setAddCreditsId(id);
  }

  async function handleRemoveCredits(id) {
    setShowRemoveCreditModel(true);
    setRemoveCreditsId(id);
  }

  async function handleInactiveUser(id) {
    setEnableInactiveModal(true);
    setInactiveUserId(id);
  }
  async function handleActiveUser(id) {
    const payload = { status: 1 };
    patchApiCallWithHeader(`${API_USER_STATUS_UPDATE}/${id}`, payload)
      .then(onUserStatusUpdateSuccess)
      .catch(onUserStatusUpdateFail);
  }

  async function handleShowPassword(id) {
    setEnableShowPasswordModal(true);
    setShowPasswordId(id);
  }

  const handleSelectAll = () => {
    if (selectedUsers.length === userlist.length) {
      setSelectedUsers([]);
    } else {
      const allUserIds = userlist.map((user) => user._id);
      setSelectedUsers(allUserIds);
    }
  };

  useEffect(() => {
    if (confirmDelete) {
      if (isMultiDelete) {
        postApiCall(API_USER_DELETE, { userIds: selectedUsers })
          .then(onUserDeleteSuccess)
          .catch(onUserDeleteFail);
      } else {
        postApiCall(API_USER_DELETE, { userIds: [deleteUserId] })
          .then(onUserDeleteSuccess)
          .catch(onUserDeleteFail);
      }
    }
  }, [confirmDelete]);

  useEffect(() => {
    if (confirmUserInactive) {
      const payload = { status: 0 };
      patchApiCallWithHeader(
        `${API_USER_STATUS_UPDATE}/${inactiveUserId}`,
        payload
      )
        .then(onUserStatusUpdateSuccess)
        .catch(onUserStatusUpdateFail);
    }
  }, [confirmUserInactive]);

  useEffect(() => {
    if (enableShowPasswordModal) {
      const id = showPasswordId;
      getApiCall(`${API_USERS_LIST}/${id}`, { role: activeUserAccessRole })
        .then(onUserShowPassword)
        .catch(onShowPasswordFail)
        .finally(() => setLoading(false));
    }
  }, [enableShowPasswordModal]);

  function onUserShowPassword(resp) {
    if (resp.actual_password) {
      setShowPasswordUser(`Password is : ${resp.actual_password}`);
    } else {
      setShowPasswordUser("Actual Password is not saved for this user");
    }
    setShowPasswordId(undefined);
    setConfirmShowPassword(false);
  }

  function onShowPasswordFail(err) {
    if (typeof err !== "object" && err.includes("TokenExpiredError")) {
      errorCheck(err, navigate, setToastProps, setShowToast);
    } else {
      console.log(err);
      setToastProps({
        type: "danger",
        message: `Unable to update user status to inactive: ${err}`,
      });
    }
    setConfirmDelete(false);
  }

  function onUserStatusUpdateSuccess(resp) {
    setToastProps({
      type: "success",
      message: "User status updated successfully",
    });
    setEnableInactiveModal(false);
    setInactiveUserId(undefined);
    setConfirmUserInactive(false);
    getOriginalList(); // Refresh user list
  }

  function onUserDeleteSuccess(resp) {
    setToastProps({ type: "success", message: "User deleted successfully" });
    let updatedList;
    if (isMultiDelete) {
      updatedList = userlist.filter((val) => !selectedUsers.includes(val._id));
      setSelectedUsers([]);
    } else {
      updatedList = userlist.filter((val) => val._id != deleteUserId);
    }
    setUserlist(updatedList);
    setEnableDeleteModal(false);
    setDeleteUserId(undefined);
    setConfirmDelete(false);
  }

  function onUserStatusUpdateFail(err) {
    if (typeof err !== "object" && err.includes("TokenExpiredError")) {
      errorCheck(err, navigate, setToastProps, setShowToast);
    } else {
      console.log(err);
      setToastProps({
        type: "danger",
        message: `Unable to update user status to inactive: ${err}`,
      });
    }
    setConfirmDelete(false);
  }

  function onUserDeleteFail(err) {
    if (typeof err !== "object" && err.includes("TokenExpiredError")) {
      errorCheck(err, navigate, setToastProps, setShowToast);
    } else {
      console.log(err);
      setToastProps({
        type: "danger",
        message: `Unable to delete user: ${err}`,
      });
    }
    setConfirmDelete(false);
  }

  async function switchAccount(id) {
    postApiCall(API_SWITCH_USER, { id })
      .then(onAccountSwitchSuccess)
      .catch(onAccountSwitchError);
  }

  async function onAccountSwitchSuccess(response) {
    const userData = response;
    const switchAccountSuccess = await switchUser(userData);
    if (switchAccountSuccess) {
      setToastProps({
        type: "success",
        message: `Account switched successfully`,
      });
      setTimeout(function () {
        window.location.href = ROUTES.DASHBOARD;
      }, 1500);
    }
  }

  const handleAllocateClick = (id, role) => {
    navigate(`/allocate/${id}/${role}`);
  };

  const handleSearchUserList = (value) => {
    const searchTerm = value.toLowerCase();
    const filteredUsers = originalUserlist.filter((user) =>
      user.username.toLowerCase().includes(searchTerm)
    );
    setUserlist(filteredUsers);

    if (filteredUsers.length === 0) {
      setNoDataMessage("No users found with that username.");
    } else {
      setNoDataMessage("");
    }
  };

  if (loading) {
    return <Loader />;
  }

  //
  const RenderActionComponent = ({ id, role, status }) => {
    const userEditRoute = ROUTES.USERS.EDIT.replace(":id", id);
    const userResetPassRoute = ROUTES.USERS.RESET_PASSWORD.replace(":id", id);

    return (
      <div
        key={id}
        className="d-flex align-items-center"
        style={{ justifyContent: "space-between" }}
      >
        {/* Switch Account */}
        {(isSuperAdmin ||
          role === USER_ROLES.COMPANY_USER ||
          role === USER_ROLES.CALL_CENTRE_ADMIN ||
          isReseller || 
          (isCallCenterAdmin && role === USER_ROLES.RESELLER) || isCompanyUser) && (
          <span
            className="pointer"
            title="Switch account"
            onClick={() => switchAccount(id)}
          >
            <img alt="Switch account" src={switchUserIcon} width={32} />
          </span>
        )}

        {/* Dropdown Actions */}
        <div style={{ position: "relative" }}>
          <BootstrapDropdown>
            <BootstrapDropdown.Toggle
              variant="primary"
              size="sm"
              id={`dropdown-basic-${id}`}
              style={{ marginLeft: "5px", width: "150px" }}
            >
              View Actions
            </BootstrapDropdown.Toggle>

            <BootstrapDropdown.Menu
              popperConfig={{
                modifiers: [
                  {
                    name: "preventOverflow",
                    options: { boundary: "viewport" },
                  },
                  {
                    name: "flip",
                    options: { fallbackPlacements: ["top", "bottom"] },
                  },
                ],
              }}
              style={{ maxHeight: "300px", overflowY: "auto" }}
            >
              <BootstrapDropdown.Item
                onClick={() => navigate(`/${userEditRoute}`)}
              >
                Edit User
              </BootstrapDropdown.Item>
              {!selectedUsers.length > 0 && (
                <BootstrapDropdown.Item onClick={() => handleDeleteUser(id)}>
                  Delete User
                </BootstrapDropdown.Item>
              )}
              {(role === USER_ROLES.RESELLER ||
                (!isSuperAdmin && !isCallCenterAdmin)) && (
                <BootstrapDropdown.Item
                  onClick={() => handleAllocateClick(id, role)}
                >
                  Allocate
                </BootstrapDropdown.Item>
              )}
              {Number(status) == 1 ? (
                <BootstrapDropdown.Item onClick={() => handleInactiveUser(id)}>
                  Make Inactive
                </BootstrapDropdown.Item>
              ) : (
                <BootstrapDropdown.Item onClick={() => handleActiveUser(id)}>
                  Make Active
                </BootstrapDropdown.Item>
              )}
              <BootstrapDropdown.Item onClick={() => handleShowPassword(id)}>
                Show Password
              </BootstrapDropdown.Item>
              <BootstrapDropdown.Item onClick={() => handleAddCredits(id)}>
                Add Credits
              </BootstrapDropdown.Item>
              <BootstrapDropdown.Item onClick={() => handleRemoveCredits(id)}>
                Remove Credits
              </BootstrapDropdown.Item>
              <BootstrapDropdown.Item
                onClick={() => navigate(`/${userResetPassRoute}`)}
              >
                Reset Password
              </BootstrapDropdown.Item>
            </BootstrapDropdown.Menu>
          </BootstrapDropdown>
        </div>
      </div>
    );
  };

  const dropdownItemTemplate = (option) => {
    return <span>{option}</span>;
  };

  //
  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses.current}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={dropdownItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  function onError(err) {
    if (typeof err !== "object" && err.includes("TokenExpiredError")) {
      errorCheck(err, navigate, setToastProps, setShowToast);
    } else {
      console.log(err);
    }
  }

  function onAccountSwitchError(err) {
    setToastProps({
      type: "danger",
      message: `Access Denied ! Only Superadmin can switch`,
    });
  }

  // Map the role to the role name from USERS_ROLE
  const userTypeTemplate = (rowData) => {
    const userRole = USERS_ROLE[rowData.role];
    //  console.log("USER", userRole);
    return <span>{userRole}</span>;
  };

  const statusText = (status) => {
    if (status.status == 0) {
      return "Inactive"
    }
    else {
      return "Active"
    }
  }

  const handleCreateButtonClick = () => {
    navigate(`/${ROUTES.USERS.CREATE}`);
  };

  const rowClassName = (data) => {
    return {
      "highlighted-row": selectedUsers.includes(data._id),
    };
  };

  //
  const actionBodyTemplate = (rowData) => {
    return (
      <RenderActionComponent
        id={rowData._id}
        role={rowData.role}
        username={rowData.username}
        status={rowData.status}
      />
    );
  };

  //
  return (
    <>
      <div>
        <Row>
          <Col sm="12">
            <Card>
              <Card.Header className="d-block justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Users List</h4>
                  {/* <RenderUserAccessRolesList activeUserAccessRole={activeUserAccessRole} userAccessRoles={userAccessRoles} setActiveUserAccessRole={setActiveUserAccessRole} /> */}
                </div>
                <div>
                  <Button
                    label="Export Data"
                    onClick={handleExport}
                    className="p-button-active p-button-sm"
                    style={{
                      marginRight: "10px",
                      height: "41.33px",
                      backgroundColor: "var(--bs-primary)",
                      borderBlockColor: "var(--bs-primary)",
                    }}
                    disabled={buttonDisable}
                  >
                    &nbsp;
                    {buttonDisable && (
                      <span
                        className="spinner-border spinner-border-sm ml-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </Button>
                  {(userRole === USER_ROLES.SUB_SUPERADMIN
                    ? moduleOperations?.Users?.add
                    : true) && (
                    <button
                      className="btn btn-primary ml-auto"
                      onClick={handleCreateButtonClick}
                    >
                      Create User
                    </button>
                  )}
                </div>
              </Card.Header>
              <Card.Body className="px-0">
              <div className="scroll-container">
                <div className="table-responsive border-bottom my-3">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      overflowX: "scroll",
                    }}
                  >
                    <Button
                      label="Delete Selected"
                      onClick={handleMultiDelete}
                      className="p-button-danger p-button-sm"
                      style={{
                        marginBottom: "10px",
                        marginLeft: "10px",
                        flexShrink: 0,
                      }}
                      disabled={selectedUsers.length === 0}
                    />

                    <Dropdown
                      value={selectedRole}
                      options={uniqueRoles}
                      onChange={(e) => setSelectedRole(e.value)}
                      placeholder="Select Type"
                      className="p-column-filter"
                      style={{
                        marginBottom: "10px",
                        marginLeft: "10px",
                        flexShrink: 0,
                      }}
                    />

                    <Dropdown
                      value={selectedUsername}
                      options={uniqueUsernames}
                      onChange={(e) => setSelectedUsername(e.value)}
                      placeholder="Select Username"
                      className="p-column-filter"
                      style={{
                        marginBottom: "10px",
                        marginLeft: "10px",
                        flexShrink: 0,
                      }}
                    />
                    <Form.Group style={{ flexShrink: 0 }}>
                      <Form.Control
                        style={{
                          height: "50px",
                          marginLeft: "10px",
                          border: "1px solid #ced4da",
                          fontSize: "15px",
                        }}
                        placeholder="Search by Name or Username..."
                        type="text"
                        maxLength={100}
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Button
                      label="Clear Filters"
                      onClick={clearFilters}
                      className="p-button-danger p-button-sm"
                      style={{
                        marginBottom: "10px",
                        marginLeft: "20px",
                        flexShrink: 0,
                      }}
                      disabled={
                        !(
                          searchValue.trim() ||
                          selectedRole ||
                          selectedUsername
                        )
                      }
                    />
                  </div>

                  <DataTable
                    value={userlist}
                    tableStyle={{ minWidth: "50rem" }}
                    filters={filters}
                    paginator
                    rows={8}
                    rowClassName={rowClassName}
                    className={`${
                      userlist.length < 3 && userlist.length > 0
                        ? "increasePaddingBottom"
                        : null
                    }`}
                  >
                    <Column
                      header={
                        <Checkbox
                          checked={
                            selectedUsers.length === userlist.length &&
                            userlist.length > 0
                          }
                          onChange={handleSelectAll}
                        />
                      }
                      headerStyle={{ width: "3rem" }}
                      body={(rowData) => (
                        <Checkbox
                          checked={selectedUsers.includes(rowData._id)}
                          onChange={() => handleCheckboxToggle(rowData._id)}
                        />
                      )}
                    />
                    <Column
                      field="username"
                      filterField="username"
                      header="Username"
                      filter
                      filterPlaceholder="Search by username"
                    />
                    <Column
                      field="name"
                      filterField="name"
                      header="Name"
                      filter
                      filterPlaceholder="Search by name"
                    />
                    <Column
                      field="email"
                      filterField="email"
                      header="Email"
                      filter
                      filterPlaceholder="Search by email"
                    />
                    <Column
                      field="USERS_ROLE.role"
                      header="Type"
                      body={userTypeTemplate}
                    />
                    <Column
                      field="company_name"
                      filterField="company_name"
                      header="Company Name"
                      filter
                      filterPlaceholder="Search by company name"
                    />
                     <Column
                      field="created_by.username"
                      header="Parent"
                    />
                    <Column
                      field="status"
                      header="Status"
                      body={statusText}
                      filterElement={statusFilterTemplate}
                      filter
                    />
                    <Column field="created_at" header="Joining Date" />
                    {(userRole === USER_ROLES.SUB_SUPERADMIN
                      ? moduleOperations?.Users?.edit ||
                        moduleOperations?.Users?.delete
                      : true) && (
                      <Column header="Action" body={actionBodyTemplate} />
                    )}
                  </DataTable>
                </div>
              </div>  
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      <RenderDeleteModal
        show={enableDeleteModal}
        setShow={setEnableDeleteModal}
        setConfirmDelete={setConfirmDelete}
        isMultiDelete={isMultiDelete}
        clearSelectedData={() => setSelectedUsers([])}
      />

      <RenderInactiveUserModal
        show={enableInactiveModal}
        setShow={setEnableInactiveModal}
        setConfirmUserInactive={setConfirmUserInactive}
        clearSelectedData={() => setSelectedUsers([])}
      />

      <RenderShowPasswordModal
        show={enableShowPasswordModal}
        setShow={setEnableShowPasswordModal}
        setConfirmShowPassword={setConfirmShowPassword}
        passwordValue={showPasswordUser}
      />

      <ToastNotification
        show_toast={showToast}
        set_show_toast={setShowToast}
        {...toastProps}
      />
        <RenderAddCreditModal
        show={showAddCreditModel}
        setShow={setShowAddCreditModel}
        setConfirmUpdateCredit={setConfirmCredits}
        creditsValue={addCreditsValue}
        setCreditsValue={setAddCreditsValue}
      />
       <RenderRemoveCreditModal
        show={showRemoveCreditModel}
        setShow={setShowRemoveCreditModel}
        setConfirmReduceUpdateCredit={setConfirmReduceCredits}
        removeCreditsValue={removeCreditsValue}
        setRemoveCreditsValue={setRemoveCreditsValue}
      />

    </>
  );
};

export default UserList;
