import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import defaultAnimation from './animation.json';

const LottieAnimation = ({animationData}) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10%' }}>
            <Player
                speed={0.5}
                autoplay
                loop
                src={animationData || defaultAnimation}
                style={{ width: '100%', height: '100%' }}
            />
        </div>
    );
};

export default LottieAnimation;
