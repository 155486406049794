import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Card from '../../../components/Card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import RenderDeleteModal from '../../../components/partials/components/delete-modal';
import ToastNotification from '../../../components/Toast';
import Loader from '../../../components/partials/components/loader';
import { API_EXTENSION } from '../../../service/url';
import { getApiCall, postApiCall } from '../../../service/httpCall';
import exportData from '../../../utilities/export';
import '../../../assets/custom/css/custom.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';

const Extension = () => {
   const navigate = useNavigate();
   const [extensionList, setExtensionList] = useState([]);
   const [loading, setLoading] = useState(false);
   const [enableDeleteModal, setEnableDeleteModal] = useState(false);
   const [confirmDelete, setConfirmDelete] = useState(false);
   const [selectedExtensions, setSelectedExtensions] = useState([]);
   const [showToast, setShowToast] = useState(false);
   const [toastProps, setToastProps] = useState(null);
   const [buttonDisable, setButtonDisable] = useState(false);

   // Hide toast automatically after 3 seconds
   useEffect(() => {
      if (showToast) {
         const timer = setTimeout(() => setShowToast(false), 3000);
         return () => clearTimeout(timer);
      }
   }, [showToast]);

   useEffect(() => {
      if (confirmDelete) {
         handleDeleteSelectedExtensions();
      }
   }, [confirmDelete]);

   // Fetch Extension Data
   const fetchExtensions = () => {
      setLoading(true);
      getApiCall(`${API_EXTENSION}?data=all`)
         .then((resp) => {
            if (resp) {
               const updatedList = resp.map((val, idx) => ({
                  ...val,
                  key: idx + 1,
                  created_at: new Date(val.created_at).toLocaleString(), 
                  updated_at: new Date(val.updated_at).toLocaleString(),
               }));
               setExtensionList(updatedList);
            } else {
               setToastProps({ type: 'danger', message: 'Failed to fetch extensions' });
               setShowToast(true);
            }
         })
         .catch((error) => {
            console.error("Error fetching extensions:", error);
            setToastProps({ type: 'danger', message: 'Error fetching extensions' });
            setShowToast(true);
         })
         .finally(() => setLoading(false));
   };

   useEffect(fetchExtensions, []);

   const handleDeleteSelectedExtensions = () => {
      if (selectedExtensions.length === 0) return;
      postApiCall(API_EXTENSION + '/delete', { extensionIds: selectedExtensions })
         .then(() => {
            setToastProps({ type: 'success', message: 'Extensions deleted successfully' });
            setShowToast(true);
            fetchExtensions();
            setSelectedExtensions([]);
         })
         .catch((err) => {
            setToastProps({ type: 'danger', message: `Unable to delete extensions: ${err}` });
            setShowToast(true);
         })
         .finally(() => setEnableDeleteModal(false));
   };

   const handleExport = async () => {
      setButtonDisable(true);
      await exportData('Extension', setToastProps, setShowToast);
      setButtonDisable(false);
   };

   const onCheckboxChange = (e, extensionId) => {
      const { checked } = e.target;
      setSelectedExtensions((prevSelected) =>
         checked ? [...prevSelected, extensionId] : prevSelected.filter((id) => id !== extensionId)
      );
   };

   const onHeaderCheckboxChange = (e) => {
      const { checked } = e.target;
      if (checked) {
         const allExtensionIds = extensionList.map((extension) => extension._id);
         setSelectedExtensions(allExtensionIds);
      } else {
         setSelectedExtensions([]);
      }
   };

   // Custom render for the "Allocation" column
   const allocationBodyTemplate = (rowData) => {
      return rowData.is_allocated === 0 ? "Available" : "Allocated";
   };

   // Custom render for the "Extension" column (combining prefix and description)
   const extensionBodyTemplate = (rowData) => {
      return `${rowData.extension} (${rowData.description || "No Description"})`;
   };

   return (
      <div style={{ marginTop: '20px' }}>
         <Row>
            <Col sm="12">
               <Card>
                  <Card.Header className="d-flex justify-content-between">
                     <h4>Extensions List</h4>
                     <Button className="btn btn-primary" onClick={() => navigate('/create-extension')}>Create Extension</Button>
                  </Card.Header>
                  <Card.Body className="px-0">
                     {loading ? <Loader /> : (
                        <>
                           <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
                              <Button
                                 label="Delete Selected"
                                 onClick={() => setEnableDeleteModal(true)}
                                 className="p-button-danger p-button-sm"
                                 style={{ marginLeft: '10px' }}
                                 disabled={selectedExtensions.length === 0}
                              />
                              <Button
                                 label="Export Data"
                                 onClick={handleExport}
                                 className="p-button-active p-button-sm"
                                 style={{ marginLeft: '10px' }}
                                 disabled={buttonDisable}
                              >
                                 {buttonDisable && (
                                    <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                                 )}
                              </Button>
                           </div>
                           <div className="scroll-container">
                              <DataTable value={extensionList} paginator rows={10}>
                                 <Column
                                    header={<Checkbox onChange={onHeaderCheckboxChange} checked={selectedExtensions.length === extensionList.length && extensionList.length > 0} />}
                                    body={(rowData) => (
                                       <Checkbox onChange={(e) => onCheckboxChange(e, rowData._id)} checked={selectedExtensions.includes(rowData._id)} />
                                    )}
                                    style={{ width: '3rem' }}
                                 />
                                 <Column field="username" header="Username" />
                                 <Column body={allocationBodyTemplate} header="Allocation Status" />
                                 <Column body={extensionBodyTemplate} header="Extension (Description)" />
                                 <Column field="created_at" header="Creation Date" />
                              </DataTable>
                           </div>   
                        </>
                     )}
                  </Card.Body>
               </Card>
            </Col>
         </Row>

         <RenderDeleteModal
            show={enableDeleteModal}
            setShow={setEnableDeleteModal}
            setConfirmDelete={setConfirmDelete}
            isMultiDelete={true}
            clearSelectedData={() => setSelectedExtensions([])}
            onConfirm={handleDeleteSelectedExtensions}
         />

         {/* <ToastNotification show={showToast} set_show_toast={setShowToast} {...toastProps} /> */}
      </div>
   );
};

export default Extension;
