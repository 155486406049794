import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

function RenderAddCreditModal(props) {
  const handleClose = () => {
    props.setShow(false);
  };

  const handleCreditChange = (e) => {
    const value = e.target.value;
    if (value === "" || (Number(value) >= 0 && !isNaN(value))) {
      props.setCreditsValue(value);
    }
  };

  return (
    <Modal show={props.show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Credits for the User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="creditValue">
            <Form.Control
              type="number"
              value={props.creditsValue}
              onChange={handleCreditChange}
              placeholder="Enter credit value"
              min="0"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={() => props.setConfirmUpdateCredit(true)}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RenderAddCreditModal;
