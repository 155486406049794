import React, { useEffect, useState, memo, Fragment } from "react";
import {Container, Row, Col, Card } from "react-bootstrap";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { FaUser, FaUsers, FaClock,FaStopwatch, FaHourglassHalf, FaChartLine,FaPercentage, FaDatabase, FaPhone, FaPhoneAlt, FaPhoneVolume, FaPhoneSlash, FaCog, FaPhoneSquareAlt } from 'react-icons/fa';
import { getApiCall} from '../../service/httpCall';
import { API_AGENTS_LIST, API_INCOMING_SUMMARY_LIST } from '../../service/url';
import "bootstrap/dist/css/bootstrap.min.css";

// AOS
import AOS from "aos";
import "../../../node_modules/aos/dist/aos";
import "../../../node_modules/aos/dist/aos.css";

import SwiperCore, { Navigation } from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
// import 'swiper/components/navigation/navigation.scss';

// Redux Selector / Action
import { useSelector } from "react-redux";

// Import selectors & action from setting store
import * as SettingSelector from "../../store/setting/selectors";

import { USER_ROLES } from '../../utilities/constant';

// install Swiper modules
SwiperCore.use([Navigation]);

const Index = memo((props) => {
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [isReseller, setIsReseller] = useState(false);
    const [graph, setGraph] = useState([]);
    const [isCompanyAdmin, setCompanyAdmin] = useState(false);
    const [isCallCenterAdmin, setCallCenterAdmin] = useState(false);
    const [agentDataObject, setAgentDataObject] = useState({
       total_agent: 0,
       logged_in_agent:0,
       agent_on_call: 0
    });
    const [incomingSummaryData, setIncomingSummaryData] = useState({
      dialed_call: 0,
      answered_call:0,
      abandoned_call: 0
   });


    useEffect(() => {
      async function checkUserRole() {
          const user = JSON.parse(await AsyncStorage.getItem('user'));
          if (user.role === USER_ROLES.SUPERADMIN) setIsSuperAdmin(true);
      }
      checkUserRole();
  }, []);
  
    useEffect(() => {
      async function superadminCheck() {
        const user = JSON.parse(await AsyncStorage.getItem('user'));
        if (user.role === USER_ROLES.SUPERADMIN) {
          setIsSuperAdmin(true);
        }
        if (user.role === USER_ROLES.COMPANY_ADMIN) {
          setCompanyAdmin(true);
        }
        if (user.role === USER_ROLES.CALL_CENTRE_ADMIN) {
          setCallCenterAdmin(true);
        }
      }

      async function resellerCheck() {
        const user = JSON.parse(await AsyncStorage.getItem('user'));
        if (user.role === USER_ROLES.RESELLER) {
          setIsReseller(true);
        }
      }
      superadminCheck();
      resellerCheck();
    }, []);

    useEffect(() => {
      async function superadminCheck() {
        const user = JSON.parse(await AsyncStorage.getItem('user'));
        if (user.role === USER_ROLES.SUPERADMIN) {
          setIsSuperAdmin(true);
        }
        if (user.role === USER_ROLES.COMPANY_ADMIN) {
          setCompanyAdmin(true);
        }
        if (user.role === USER_ROLES.CALL_CENTRE_ADMIN) {
          setCallCenterAdmin(true);
        }
      }

      async function resellerCheck() {
        const user = JSON.parse(await AsyncStorage.getItem('user'));
        if (user.role === USER_ROLES.RESELLER) {
          setIsReseller(true);
        }
      }
      superadminCheck();
      resellerCheck();
    }, []);

    const fetchAgents = () => {
          getApiCall(API_AGENTS_LIST)
             .then((resp) => {
                // console.log('resprespresprespresp', resp)
                if (resp && Array.isArray(resp)) {
                  const total_agent = resp.length;
                  const logged_in_agent = resp.filter(agent => agent.login_status === "1").length;
                  const agent_on_call = resp.filter(agent => agent.call_status === 1).length;
                  setAgentDataObject({ total_agent, logged_in_agent, agent_on_call });
              }
             })
             .catch((error) => {
                 console.log(error)
             })
       };


       const fetchIncomingSummary = () => {
        getApiCall(API_INCOMING_SUMMARY_LIST)
           .then((resp) => {
              // console.log('resprespresprespresp', resp)
              if (resp && Array.isArray(resp)) {
                // Summing up the required fields
                const dialed_call = resp.reduce((sum, item) => sum + (item.nos_processed || 0), 0);
                const answered_call = resp.reduce((sum, item) => sum + (item.connected_calls || 0), 0);
                const abandoned_call = resp.reduce((sum, item) => sum + ((item.total_nos || 0) - (item.connected_calls || 0)), 0);

                // Updating the state
                setIncomingSummaryData({ dialed_call, answered_call, abandoned_call });
            }
           })
           .catch((error) => {
               console.log(error)
           })
     };
    
    useEffect(
      fetchAgents,
      []);

    useEffect(
      fetchIncomingSummary,
    []);

    let data = [];

    let agentData = [];
    let incommingData = [];
    let outgoingData = [];
    let queueData = [];
    let othersData = [];
    
    if(isCallCenterAdmin) {

      agentData = [
                    { title: 'Total Agents', value: agentDataObject.total_agent, icon: <FaUser />, bgColor: '#4caf50', backgroundImage: 'url(/path/to/total-agents-bg.png)' },
                    { title: 'Logged-in Agents', value: agentDataObject.logged_in_agent, icon: <FaStopwatch />, bgColor: '#f44336', backgroundImage: 'url(/path/to/total-agents-bg.png)' },
                    { title: 'Agents on Call', value: agentDataObject.agent_on_call, icon: <FaPhoneVolume />, bgColor: '#ff9800', backgroundImage: 'url(/path/to/total-agents-bg.png)' },
                  ];
      incommingData = [
                        { title: 'Dialed Calls', value: incomingSummaryData.dialed_call, icon: <FaPhone />, bgColor: '#4caf7e', backgroundImage: 'url(/path/to/dialed-call-bg.png)' },
                        { title: 'Answered Calls', value: incomingSummaryData.answered_call, icon: <FaPhoneVolume />, bgColor: '#4caf50', backgroundImage: 'url(/path/to/answered-call-bg.png)' },
                        { title: 'Abandoned Calls', value: incomingSummaryData.abandoned_call, icon: <FaPhoneSlash />, bgColor: '#ff9800', backgroundImage: 'url(/path/to/abandoned-call-bg.png)' }
                      ];
      outgoingData =  [
                       { title: 'Total Calls', value: '0', icon: <FaDatabase />, bgColor: '#2196f3', backgroundImage: 'url(/path/to/total-data-bg.png)' },
                       { title: 'Dialed Calls', value: '0', icon: <FaPhone />, bgColor: '#4caf7e', backgroundImage: 'url(/path/to/dialed-call-bg.png)' },
                       { title: 'Answered Calls', value: '0', icon: <FaPhoneVolume />, bgColor: '#ff9800', backgroundImage: 'url(/path/to/answered-call-bg.png)' },        
                      ];

      queueData = [
                      { title: 'AQT', value: '0', icon: <FaUsers />, bgColor: '#3f51b5', backgroundImage: 'url(/path/to/aqt-bg.png)' },
                      { title: 'AHT', value: '0', icon: <FaStopwatch />, bgColor: '#f44336', backgroundImage: 'url(/path/to/aht-bg.png)' },
                      { title: 'ASA', value: '00:00', icon: <FaHourglassHalf />, bgColor: '#ff9800', backgroundImage: 'url(/path/to/asa-bg.png)' },
                      { title: 'SLA', value: '0%', icon: <FaPercentage />, bgColor: '#9c27b0', backgroundImage: 'url(/path/to/sla-bg.png)' },
                      { title: 'Agent Disposed', value: '0', icon: <FaUser />, bgColor: '#4cabaf', backgroundImage: 'url(/path/to/system-disposed-bg.png)' },
                      { title: 'System Disposed', value: '0', icon: <FaCog />, bgColor: '#f44336', backgroundImage: 'url(/path/to/system-disposed-bg.png)' },
                  ];
      
      othersData = [
                      { title: 'Average Wrap-up', value: '00:00', icon: <FaClock />, bgColor: '#795548', backgroundImage: 'url(/path/to/avg-wrapup-bg.png)' },
                      { title: 'Offered-Call', value: '0', icon: <FaPhoneAlt />, bgColor: '#ff5722', backgroundImage: 'url(/path/to/offered-call-bg.png)' },
                      { title: 'Dialed Data', value: '0', icon: <FaDatabase />, bgColor: '#4caf50', backgroundImage: 'url(/path/to/dialed-data-bg.png)' },
                      { title: 'Pending Data', value: '0', icon: <FaPhoneSquareAlt />, bgColor: '#03a9f4', backgroundImage: 'url(/path/to/pending-data-bg.png)' },
                      { title: 'Pending Callbacks', value: '0', icon: <FaHourglassHalf />, bgColor: '#ff9800', backgroundImage: 'url(/path/to/pending-callback-bg.png)' },
                      { title: 'Con.Dialed Calls', value: '0', icon: <FaPhoneVolume />, bgColor: '#ff9800', backgroundImage: 'url(/path/to/connected-dialed-call-bg.png)' },
                   ];

    }else{
     data = [
      { title: 'Total Agents', value: '0', icon: <FaUser />, bgColor: '#4caf50', backgroundImage: 'url(/path/to/total-agents-bg.png)' },
      { title: 'AQT', value: '0', icon: <FaUsers />, bgColor: '#3f51b5', backgroundImage: 'url(/path/to/aqt-bg.png)' },
      { title: 'AHT', value: '0', icon: <FaStopwatch />, bgColor: '#f44336', backgroundImage: 'url(/path/to/aht-bg.png)' },
      { title: 'ASA', value: '00:00', icon: <FaHourglassHalf />, bgColor: '#ff9800', backgroundImage: 'url(/path/to/asa-bg.png)' },
      { title: 'Average Wrap-up', value: '00:00', icon: <FaClock />, bgColor: '#795548', backgroundImage: 'url(/path/to/avg-wrapup-bg.png)' },
      { title: 'SLA', value: '0%', icon: <FaPercentage />, bgColor: '#9c27b0', backgroundImage: 'url(/path/to/sla-bg.png)' },
      { title: 'Total Data', value: '0', icon: <FaDatabase />, bgColor: '#2196f3', backgroundImage: 'url(/path/to/total-data-bg.png)' },
      { title: 'Offered-Call', value: '0', icon: <FaPhoneAlt />, bgColor: '#ff5722', backgroundImage: 'url(/path/to/offered-call-bg.png)' },
      
      { title: 'Dialed Data', value: '0', icon: <FaDatabase />, bgColor: '#4caf50', backgroundImage: 'url(/path/to/dialed-data-bg.png)' },
      { title: 'Pending Data', value: '0', icon: <FaPhoneSquareAlt />, bgColor: '#03a9f4', backgroundImage: 'url(/path/to/pending-data-bg.png)' },
      { title: 'Pending Callbacks', value: '0', icon: <FaHourglassHalf />, bgColor: '#ff9800', backgroundImage: 'url(/path/to/pending-callback-bg.png)' },
      { title: 'Agent Disposed', value: '0', icon: <FaUser />, bgColor: '#4cabaf', backgroundImage: 'url(/path/to/system-disposed-bg.png)' },
      { title: 'System Disposed', value: '0', icon: <FaCog />, bgColor: '#f44336', backgroundImage: 'url(/path/to/system-disposed-bg.png)' },
      { title: 'Answered Calls', value: '00:00', icon: <FaPhone />, bgColor: '#4caf50', backgroundImage: 'url(/path/to/answered-call-bg.png)' },
      { title: 'Abandoned Calls', value: '0', icon: <FaPhoneSlash />, bgColor: '#f44336', backgroundImage: 'url(/path/to/abandoned-call-bg.png)' },
      { title: 'Dialed Calls', value: '0', icon: <FaPhone />, bgColor: '#4caf7e', backgroundImage: 'url(/path/to/dialed-call-bg.png)' },
      { title: 'Con.Dialed Calls', value: '0', icon: <FaPhoneVolume />, bgColor: '#ff9800', backgroundImage: 'url(/path/to/connected-dialed-call-bg.png)' },
    ];
  }
    

  const filteredData = isSuperAdmin ? data : data.slice(0, data.length / 2);

    const graphData = [
        { status: 'Available', agents: agentDataObject.logged_in_agent },
        { status: 'Busy', agents: agentDataObject.agent_on_call },
        { status: 'Offline', agents: Number(agentDataObject.total_agent) - Number(agentDataObject.logged_in_agent) },
        // { status: 'Break', agents: 5 },
    ];

 const filteredGraphData = isSuperAdmin ? graphData : graphData.slice(0, 2);
 useSelector(SettingSelector.theme_color);

  const getVariableColor = () => {
    let prefix =
      getComputedStyle(document.body).getPropertyValue("--prefix") || "bs-";
    if (prefix) {
      prefix = prefix.trim();
    }
    const color1 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary`
    );
    const color2 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}info`
    );
    const color3 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary-tint-20`
    );
    const color4 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}warning`
    );
    return {
      primary: color1.trim(),
      info: color2.trim(),
      warning: color4.trim(),
      primary_light: color3.trim(),
    };
  };
  const variableColors = getVariableColor();

  const colors = [variableColors.primary, variableColors.info];
  useEffect(() => {
    return () => colors;
  });

  useEffect(() => {
    AOS.init({
      startEvent: "DOMContentLoaded",
      disable: function () {
        var maxWidth = 996;
        return window.innerWidth < maxWidth;
      },
      throttleDelay: 10,
      once: true,
      duration: 700,
      offset: 10,
    });
  });
  const chart1 = {
    options: {
      chart: {
        fontFamily:
          '"Inter", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: false,
        },
      },
      colors: colors,
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      yaxis: {
        show: true,
        labels: {
          show: true,
          minWidth: 19,
          maxWidth: 19,
          style: {
            colors: "#8A92A6",
          },
          offsetX: -5,
        },
      },
      legend: {
        show: false,
      },
      xaxis: {
        labels: {
          minHeight: 22,
          maxHeight: 22,
          show: true,
          style: {
            colors: "#8A92A6",
          },
        },
        lines: {
          show: false, //or just here to disable only x axis grids
        },
        categories: ["Jan", "Feb", "Mar", "Apr", "Jun", "Jul", "Aug"],
      },
      grid: {
        show: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "vertical",
          shadeIntensity: 0,
          gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
          inverseColors: true,
          opacityFrom: 0.4,
          opacityTo: 0.1,
          stops: [0, 50, 80],
          colors: colors,
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    series: [
      {
        name: "total",
        data: [94, 80, 94, 80, 94, 80, 94],
      },
      {
        name: "pipline",
        data: [72, 60, 84, 60, 74, 60, 78],
      },
    ],
  };

  //chart2
  const chart2 = {
    options: {
      colors: colors,
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 10,
            size: "50%",
          },
          track: {
            margin: 10,
            strokeWidth: "50%",
          },
          dataLabels: {
            show: false,
          },
        },
      },
      labels: ["Apples", "Oranges"],
    },
    series: [55, 75],
  };
  const chart3 = {
    options: {
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      colors: colors,
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "28%",
          endingShape: "rounded",
          borderRadius: 5,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: ["S", "M", "T", "W", "T", "F", "S", "M", "T", "W"],
        labels: {
          minHeight: 20,
          maxHeight: 20,
          style: {
            colors: "#8A92A6",
          },
        },
      },
      yaxis: {
        title: {
          text: "",
        },
        labels: {
          minWidth: 19,
          maxWidth: 19,
          style: {
            colors: "#8A92A6",
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands";
          },
        },
      },
    },
    series: [
      {
        name: "Successful deals",
        data: [30, 50, 35, 60, 40, 60, 60, 30, 50, 35],
      },
      {
        name: "Failed deals",
        data: [40, 50, 55, 50, 30, 80, 30, 40, 50, 55],
      },
    ],
  };
  return (
    <Fragment>
    {(isCallCenterAdmin) ? <>
    <label className="form-label fw-bold fs-4 mb-3">Agents</label>
     <Row xs={1} md={2} lg={5} className="g-3">
      {agentData.map((item, idx) => (
       <Col key={idx}>
        <Card 
         className="shadow-sm" 
         style={{ 
          borderRadius: '12px', 
          height: '100px',
          position: 'relative',  
          overflow: 'hidden' ,
          marginBottom:"10px",
          borderBottom: `4px solid ${item.bgColor}` 
        }}
      >
        {/* Background Icon */}
        <div
          style={{
            position: 'absolute',
            top: '50%',
            right: '1%',
            transform: 'translate(-50%, -50%)',
            fontSize: '3rem',
            color: 'rgba(0, 0, 0, 0.1)',  
            zIndex: 0,  
          }}
        >
          {item.icon}
        </div>

        <Card.Body 
          className="d-flex align-items-center p-2"
          style={{ position: 'relative', zIndex: 1 }} 
        >
          {/* Foreground Icon */}
          <div style={{
              backgroundColor: item.bgColor,
              color: "white",
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
              flexShrink: 0,
            }}>
            {item.icon}
          </div>

          {/* Text Content */}
          <div style={{ flexGrow: 1, textAlign: 'center' }}>
            <Card.Title className="mb-0" style={{ fontSize: '1rem', fontWeight: '600' }}>
              {item.title}
            </Card.Title>
            <Card.Text style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
              {item.value}
            </Card.Text>
          </div>
        </Card.Body>
      </Card>
    </Col>
    ))}
     </Row> 


     <label className="form-label fw-bold fs-4 mb-3">Incoming</label>
     <Row xs={1} md={2} lg={5} className="g-3">
      {incommingData.map((item, idx) => (
       <Col key={idx}>
        <Card 
         className="shadow-sm" 
         style={{ 
          borderRadius: '12px', 
          height: '100px',
          position: 'relative',  
          overflow: 'hidden' ,
          marginBottom:"10px",
          borderBottom: `4px solid ${item.bgColor}` 
        }}
      >
        {/* Background Icon */}
        <div
          style={{
            position: 'absolute',
            top: '50%',
            right: '1%',
            transform: 'translate(-50%, -50%)',
            fontSize: '3rem',
            color: 'rgba(0, 0, 0, 0.1)',  
            zIndex: 0,  
          }}
        >
          {item.icon}
        </div>

        <Card.Body 
          className="d-flex align-items-center p-2"
          style={{ position: 'relative', zIndex: 1 }} 
        >
          {/* Foreground Icon */}
          <div style={{
              backgroundColor: item.bgColor,
              color: "white",
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
              flexShrink: 0,
            }}>
            {item.icon}
          </div>

          {/* Text Content */}
          <div style={{ flexGrow: 1, textAlign: 'center' }}>
            <Card.Title className="mb-0" style={{ fontSize: '1rem', fontWeight: '600' }}>
              {item.title}
            </Card.Title>
            <Card.Text style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
              {item.value}
            </Card.Text>
          </div>
        </Card.Body>
      </Card>
    </Col>
    ))}
     </Row> 

     <label className="form-label fw-bold fs-4 mb-3">Outgoing</label>
     <Row xs={1} md={2} lg={5} className="g-3">
      {outgoingData.map((item, idx) => (
       <Col key={idx}>
        <Card 
         className="shadow-sm" 
         style={{ 
          borderRadius: '12px', 
          height: '100px',
          position: 'relative',  
          overflow: 'hidden' ,
          marginBottom:"10px",
          borderBottom: `4px solid ${item.bgColor}` 
        }}
      >
        {/* Background Icon */}
        <div
          style={{
            position: 'absolute',
            top: '50%',
            right: '1%',
            transform: 'translate(-50%, -50%)',
            fontSize: '3rem',
            color: 'rgba(0, 0, 0, 0.1)',  
            zIndex: 0,  
          }}
        >
          {item.icon}
        </div>

        <Card.Body 
          className="d-flex align-items-center p-2"
          style={{ position: 'relative', zIndex: 1 }} 
        >
          {/* Foreground Icon */}
          <div style={{
              backgroundColor: item.bgColor,
              color: "white",
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
              flexShrink: 0,
            }}>
            {item.icon}
          </div>

          {/* Text Content */}
          <div style={{ flexGrow: 1, textAlign: 'center' }}>
            <Card.Title className="mb-0" style={{ fontSize: '1rem', fontWeight: '600' }}>
              {item.title}
            </Card.Title>
            <Card.Text style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
              {item.value}
            </Card.Text>
          </div>
        </Card.Body>
      </Card>
    </Col>
    ))}
     </Row> 

     <label className="form-label fw-bold fs-4 mb-3">Queue</label>
     <Row xs={1} md={2} lg={6} className="g-3">
      {queueData.map((item, idx) => (
       <Col key={idx}>
        <Card 
         className="shadow-sm" 
         style={{ 
          borderRadius: '12px', 
          height: '100px',
          position: 'relative',  
          overflow: 'hidden' ,
          marginBottom:"10px",
          borderBottom: `4px solid ${item.bgColor}` 
        }}
      >
        {/* Background Icon */}
        <div
          style={{
            position: 'absolute',
            top: '50%',
            right: '1%',
            transform: 'translate(-50%, -50%)',
            fontSize: '3rem',
            color: 'rgba(0, 0, 0, 0.1)',  
            zIndex: 0,  
          }}
        >
          {item.icon}
        </div>

        <Card.Body 
          className="d-flex align-items-center p-2"
          style={{ position: 'relative', zIndex: 1 }} 
        >
          {/* Foreground Icon */}
          <div style={{
              backgroundColor: item.bgColor,
              color: "white",
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
              flexShrink: 0,
            }}>
            {item.icon}
          </div>

          {/* Text Content */}
          <div style={{ flexGrow: 1, textAlign: 'center' }}>
            <Card.Title className="mb-0" style={{ fontSize: '1rem', fontWeight: '600' }}>
              {item.title}
            </Card.Title>
            <Card.Text style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
              {item.value}
            </Card.Text>
          </div>
        </Card.Body>
      </Card>
    </Col>
    ))}
     </Row>


     <label className="form-label fw-bold fs-4 mb-3">Others</label>
     <Row xs={1} md={2} lg={6} className="g-3">
      {othersData.map((item, idx) => (
       <Col key={idx}>
        <Card 
         className="shadow-sm" 
         style={{ 
          borderRadius: '12px', 
          height: '100px',
          position: 'relative',  
          overflow: 'hidden' ,
          marginBottom:"10px",
          borderBottom: `4px solid ${item.bgColor}` 
        }}
      >
        {/* Background Icon */}
        <div
          style={{
            position: 'absolute',
            top: '50%',
            right: '1%',
            transform: 'translate(-50%, -50%)',
            fontSize: '3rem',
            color: 'rgba(0, 0, 0, 0.1)',  
            zIndex: 0,  
          }}
        >
          {item.icon}
        </div>

        <Card.Body 
          className="d-flex align-items-center p-2"
          style={{ position: 'relative', zIndex: 1 }} 
        >
          {/* Foreground Icon */}
          <div style={{
              backgroundColor: item.bgColor,
              color: "white",
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
              flexShrink: 0,
            }}>
            {item.icon}
          </div>

          {/* Text Content */}
          <div style={{ flexGrow: 1, textAlign: 'center' }}>
            <Card.Title className="mb-0" style={{ fontSize: '1rem', fontWeight: '600' }}>
              {item.title}
            </Card.Title>
            <Card.Text style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
              {item.value}
            </Card.Text>
          </div>
        </Card.Body>
      </Card>
    </Col>
    ))}
     </Row> 
     </>
    :
     <Row xs={1} md={2} lg={5} className="g-3">
      {data.map((item, idx) => (
       <Col key={idx}>
        <Card 
         className="shadow-sm" 
         style={{ 
          borderRadius: '12px', 
          height: '100px',
          position: 'relative',  
          overflow: 'hidden' ,
          marginBottom:"10px",
          borderBottom: `4px solid ${item.bgColor}` 
        }}
      >
        {/* Background Icon */}
        <div
          style={{
            position: 'absolute',
            top: '50%',
            right: '1%',
            transform: 'translate(-50%, -50%)',
            fontSize: '3rem',
            color: 'rgba(0, 0, 0, 0.1)',  
            zIndex: 0,  
          }}
        >
          {item.icon}
        </div>

        <Card.Body 
          className="d-flex align-items-center p-2"
          style={{ position: 'relative', zIndex: 1 }} 
        >
          {/* Foreground Icon */}
          <div style={{
              backgroundColor: item.bgColor,
              color: "white",
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
              flexShrink: 0,
            }}>
            {item.icon}
          </div>

          {/* Text Content */}
          <div style={{ flexGrow: 1, textAlign: 'center' }}>
            <Card.Title className="mb-0" style={{ fontSize: '1rem', fontWeight: '600' }}>
              {item.title}
            </Card.Title>
            <Card.Text style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
              {item.value}
            </Card.Text>
          </div>
        </Card.Body>
      </Card>
    </Col>
    ))}
     </Row>
    }
 
      <Container className="my-4">
          <Card>
            <Card.Header as="h5">Agent Status Distribution</Card.Header>
            <Card.Body>
              <Row>
                <Col md={12}>
                  <div style={{ height: '300px' }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        data={graphData}
                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="status" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar 
                          dataKey="agents" 
                          fill="#8884d8" 
                          name="Number of Agents"
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
    </Fragment>
  );
})

export default Index
